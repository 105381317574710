import React, { useState, useEffect, useRef } from "react";

import classNames from "classnames";


// @mui/material components
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from "@mui/material/FormControlLabel";
import AppBar from "@mui/material/AppBar";
// @mui/icons-material
import ArrowBack  from "@mui/icons-material/ArrowBack";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/EditLocation';
import WorkIcon from '@mui/icons-material/Work';
import CheckBoxIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MapIcon from '@mui/icons-material/Map';
import PaymentIcon from '@mui/icons-material/Payment';
import CupomIcon from '@mui/icons-material/LocalActivity';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import Comment from '@mui/icons-material/Comment';
import MoneyIcon from 'assets/img/money.png';
import MPIcon from 'assets/img/mercadopago.png';

import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import axios from 'axios';

import Link from '@mui/material/Link';

import myCookies from 'universal-cookie';

import { renovaJWT, BS_ALE, geraHorarios, removeEComercial, geraJson, validaCpfCnpj } from 'funcs/MyFuncs.js';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { FormControl, OutlinedInput, IconButton, Input, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";

import styles from "assets/jss/material-kit-react/views/carrinhoCompra.js";
import  "assets/css/CarrinhoCompra.css";
import 'prevent-pull-refresh';

import Confirmacao from "components/Dialogs/Confirmacao.js";

import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import MaskedInput from 'react-text-mask';

import {Buffer} from 'buffer';

import { useNavigate } from "react-router-dom";

import { Preferences } from '@capacitor/preferences';
import { formatarNumero } from "funcs/MyFuncs";

const corCheckBoxRemove = '#980000';
const corCheckBoxAdiciona = '#777';

const delayProcessando     = 0;
const tempoMinProcess      = 0;

const tp_cupom_naoautorizado = 0;
const tp_cupom_confautoriza  = 1;
const tp_cupom_autorizado    = 2;

const tp_programa_naoautorizado = 0;
const tp_programa_confautoriza  = 1;
const tp_programa_autorizado    = 2;

const tp_campo_nome = 1;
const tp_campo_celular = 2;

const optionsIdentificacao = [
    "Nenhum",
    "Casa",
    "Apartamento",
    "Trabalho"
];

function CEPMaskInput(props) {
    const { ref, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref ? ref.inputElement : null}
        mask={[/[0-9]/, /\d/,  /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ]}
      />
    );
}

const AdicionaCheckbox = withStyles((props) => <Checkbox color="default" {...props} />, {
    root: {
        color: corCheckBoxAdiciona,
      '&$checked': {
        color: corCheckBoxAdiciona,
      },
      [`& label`]: {
        color: corCheckBoxAdiciona,
      },
    },
    checked: {},
  });

function CPFMaskInput(props) {
    const { ref, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref ? ref.inputElement : null}
        mask={[/[0-9]/, /\d/,  /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
      />
    );
}

const TelefoneMaskInput = React.forwardRef((props, ref) => {
    const { component: Component, ...other } = props;

    const cp_input_cel = React.createRef();

    //const inputRef = useRef(null);
  
    // implement `InputElement` interface
    React.useImperativeHandle(ref, () => ({
      focus: () => {
        cp_input_cel.current.inputElement.focus();
      },
      // hiding the value e.g. react-stripe-elements
    }));
  
    // `Component` will be your `SomeThirdPartyComponent` from below
    return <Component
        {...other} 
        ref={cp_input_cel}
        mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />;
  });

/*function TelefoneMaskInput(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={inputRef ? inputRef : null}
        mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      />
    );
}*/

function NenhumMaskInput(props) {
    const { ref, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref ? ref.inputElement : null}
        mask={[""]}
      />
    );
}

function CNPJMaskInput(props) {
    const { ref, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref ? ref.inputElement : null}
        mask={[/[0-9]/, /\d/,  '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
      />
    );
}

class CarrinhoCompra extends React.Component {

    btnAddTroco;
    keyboardIsProbablyOpen = false;

    MPFormData;

    arrPosItems  = [];    
    arrMenuItems = [];
    cardBody     = null;
    myMenu       = null;
    cabecalho    = null;

    ultElementMenu = -1;

    fullWindowHeight = 0;

    firstTime = false;

    cp_nome = React.createRef();
    cp_celular = React.createRef();

    constructor(props) {
        super(props);
        
        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.state = [];

        if (props.location.state){
            if (props.location.state.stateFinalizaCompra){
                this.state = props.location.state.stateFinalizaCompra;
            }else{
                this.state = { 
                    carrinhoCompra: this.carregaCarrinho(localCart),
        
                    alertaSelecionaEndereco: false,
                    alertaSelecionaPagamento: false,
                    alertaDocNota: false,
                    alertaTroco: false,
                    finalizandoPedido: false,
                    alertaDesejaNota: false,
                    alertaSelecionaCupom: false,
                    alertaParticipaCupom: false,
        
                    edit_tipoDocNota: "cpf",
                    cli_cpf_edit: "",

                    edit_tipoEntrega: "retirada",
        
                    tipoDocNota : "nenhum",
                    docDocNota  : "",
        
                    listaPags : [],
                    formaPagamentoAtual : null,
                    tmpFormaPagTroco : [],
        
                    tmpQtdProd: 0,
                    tmpProdSelect : [],
                    tmpProdIndex : -1,
                    alertaConfExc: false,

                    ped_obsped : '',
        
                    taxaEntrega: 0,

                    cupomSelected: [],
                    cli_cupons: [],
                    edit_cupomCod: '',

                    cupomDescState: tp_cupom_naoautorizado,
                    cupomDescAceito: 0,

                    fidelidadeDescState: tp_programa_naoautorizado,

                    fidelidadeDescAceito: 0,

                    cad_cep :           '',
                    cad_rua:            '',
                    cad_bairro:         '',
                    cad_numero:         '',
                    cad_estado:         '',
                    cad_cidade:         '',
                    cad_complemento:    '',
                    cad_identificacao:  '',
                    cad_referencia:     '',

                    
                    listaBairros: [],

                    editaEnderecoNoLogin: false,
                    cli_tele_edit : '',
                    cli_nome_edit : '',

                    cli_pontos : 0,

                    politPrivTitulo: 'Política de Privacidade',

                    valPedidoZerado: false,

                    carrinho_compra_copia: [],
                };
            }
        }else{
            this.state = { 
                carrinhoCompra: this.carregaCarrinho(localCart),
                carrinho_compra_copia: [],
    
                alertaSelecionaEndereco: false,
                alertaSelecionaPagamento: false,
                alertaDocNota: false,
                alertaTroco: false,
                finalizandoPedido: false,
                alertaDesejaNota: false,
                alertaSelecionaCupom: false,
                
                alertaProgramaFidelidade: false,
    
                edit_tipoDocNota: "cpf",
                cli_cpf_edit: "",

                edit_tipoEntrega: "retirada",
    
                tipoDocNota : "nenhum",
                docDocNota  : "",
    
                listaPags : [],
                formaPagamentoAtual : null,
                tmpFormaPagTroco : [],
    
                tmpQtdProd: 0,
                tmpProdSelect : [],
                tmpProdIndex : -1,
                alertaConfExc: false,
    
                taxaEntrega: 0,

                cupomSelected: [],
                cli_cupons: [],
                edit_cupomCod: '',

                cad_cep :           '',
                cad_rua:            '',
                cad_bairro:         '',
                cad_numero:         '',
                cad_estado:         '',
                cad_cidade:         '',
                cad_complemento:    '',
                cad_identificacao:  '',
                cad_referencia:     '',

                listaBairros: [],

                editaEnderecoNoLogin: false,
                cli_tele_edit : '',
                cli_nome_edit : '',

                cli_pontos : 0,

                politPrivTitulo: 'Política de Privacidade',

                valPedidoZerado: false,
            };
        }        

        this.updateDimensions = this.updateDimensions.bind(this);
        this.carregaDados = this.carregaDados.bind(this);

    }

    carregaCarrinho = (carrinho) => {
        
        if ((global.programaFidelidade == '0')){
            return carrinho;
        }

        var arrFinalCarrinho = [];

        carrinho.map((value, index) => {

            var qtdeTmp = value.qtde;
            if (qtdeTmp > 1){
                for (var i = 0; i < qtdeTmp; i++){
                    value.qtde = 1;
                    arrFinalCarrinho.push(
                        JSON.parse(JSON.stringify(value))
                    )
                }
            }else{
                arrFinalCarrinho.push(
                    value
                );
            }
        })

        return arrFinalCarrinho; // 

    }

    agrupaProds = (carrinho) => {

        var tmpArrCompara = [];
        var arrFinalCarrinho = [];

        carrinho.map((value, index) => {

            var novo = true;
            for (var i = 0; i < arrFinalCarrinho.length; i++){
                if (JSON.stringify(tmpArrCompara[i]) == JSON.stringify(value)){
                    novo = false;
                    arrFinalCarrinho[i].QUANTIDADE += 1;
                    break;
                }
            }

            if (novo){
                tmpArrCompara.push(JSON.parse(JSON.stringify(value)));
                arrFinalCarrinho.push(JSON.parse(JSON.stringify(value)));
            }

        })

        return arrFinalCarrinho;

    }

    separaProdsJSON = (carrinho) => {

        var arrFinalCarrinho = [];

        carrinho.map((value, index) => {

            var qtdeTmp = value.QUANTIDADE;
            if (value.UNIDADE != 'KG' && (qtdeTmp > 1)){
                for (var i = 0; i < qtdeTmp; i++){

                    var tmpResto = qtdeTmp % 1;

                    value.QUANTIDADE = 1;
                    arrFinalCarrinho.push(
                        JSON.parse(JSON.stringify(value))
                    )
                }
            }else{
                arrFinalCarrinho.push(
                    value
                );
            }
        })

        return arrFinalCarrinho;

    }

    carregaDadosSemLogin = () => {
        
        if ((global.myToken == '') || (!global.myToken)){
            
            try {
                // here place your login logic     
                      
                var CancelToken = axios.CancelToken;
    
                const {log_senha, log_usu} = this.state;

                var valor_compra = 0;
        
                this.state.carrinhoCompra.map((item, index) => {
                    valor_compra += (item.valorUnit * item.qtde);
                });                
            
                var parametros = 
                "&partoken="    + global.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parvalpro=" + valor_compra +
                "&parbuscar=" + (global.buscar ? 1 : 0) +
                "&parverapp=" + '99';

                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);
        
                
                axios.post(
                    global.siteurl + 'cliente_info_nologin.php', 
                    parametros,
                    {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;

                    if (responseData.cliPermCupom){
                        //global.cliPerCupom = responseData.cliPermCupom;
                    }
                    
                    if (responseData.resposta == "2"){
                    }else if (responseData.resposta == "0") {
                        global.myToken = "";
                        this.setState({
                            myToken : "",
                            loading: false,
                        }, () => {
                            this.carregaDadosSemLogin();
                        });
                    }else{

                        var taxaEntrega = 0;

                        var tmpEndereco = (this.state.cli_enderecos) ? this.state.cli_enderecos : responseData.enderecos;
                        tmpEndereco.map((value, index) => {
                            if (value.enderecoAtivo == '1'){
                                if (global.apidist == '1'){
                                    taxaEntrega = (value.taxaentrega_dist > 0) ? value.taxaentrega_dist : (value.taxaentrega_bairro > 0) ? value.taxaentrega_bairro : value.taxaentrega_cep;
                                }else{
                                    taxaEntrega = (value.taxaentrega_bairro > 0) ? value.taxaentrega_bairro : value.taxaentrega_cep;
                                }
                                
                            }
                        });

                        taxaEntrega = (taxaEntrega) ? taxaEntrega : 0;

                        if (typeof(taxaEntrega) === 'string'){
                            taxaEntrega    = parseFloat(taxaEntrega);
                        }

                        
                        var total_ped    = this.returnTotPed(taxaEntrega);
                        var item = {
                            online   : false,
                            nome     : "Pedido Zerado",
                            valTroco : 0
                        }

                        var myFormaPagAtual = (total_ped > 0) ? 
                            (this.state.formaPagamentoAtual?.nome == item.nome) ? null : this.state.formaPagamentoAtual :
                            item;


                        this.setState({ 
                            cli_nome  : responseData.nome,
                            cli_tele  : responseData.telefone,
                            cli_tipo  : responseData.tipo,
                            cli_vericod : '',
                            cli_email : responseData.email,
                            cli_cpf   : "",
                            cli_enderecos : (this.state.cli_enderecos) ? this.state.cli_enderecos : responseData.enderecos,

                            cli_cupons : responseData.cupons,
                            cli_cupons_inv : responseData.cupons_inv,
                            
                            cli_cpf_edit  : (this.state.cli_cpf_edit == "") ? "" : this.state.cli_cpf_edit,

                            taxaEntrega : taxaEntrega,

                            formaPagamentoAtual: myFormaPagAtual,
                            valPedidoZerado: (total_ped <= 0),

                            loading: false,
                        }, () => {
                            if (this.firstTime){
                                this.firstTime = false;
                                this.trocaEndereco();
                            }
                        });
                    }
                })
                .catch((error) =>{
                    
                    if(axios.isCancel(error)){
        
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                    else{
                
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                });
    
            } catch (err) {
                
            }       
        }else{
            this.setState({
                loading: false,
            });
        }
    }

    editaEnderecoNoLogin = () => {

        this.setState({
            editaEnderecoNoLogin: true,
        });

    }

    carregaDados = () => {
        if (global.myToken){
            
            try {
                // here place your login logic     
                      
                var CancelToken = axios.CancelToken;
    
                const {log_senha, log_usu} = this.state;

                var valor_compra = 0;
        
                this.state.carrinhoCompra.map((item, index) => {
                    valor_compra += (item.valorUnit * item.qtde);
                });                
            
                var parametros = 
                "&partoken="    + global.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parvalpro=" + valor_compra +
                "&parbuscar=" + (global.buscar ? 1 : 0) +
                "&parverapp=" + '99';

                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);
        
                
                axios.post(
                    global.siteurl + 'cliente_info.php', 
                    parametros,
                    {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;

                    if (responseData.cliPermCupom){
                        //global.cliPerCupom = responseData.cliPermCupom;
                    }
                    
                    if (responseData.resposta == "2"){
                    }else if (responseData.resposta == "0") {
                        global.myToken = "";
                        this.setState({
                            myToken : "",
                            loading: false,
                        }, () => {
                            this.carregaDadosSemLogin();
                        });
                    }else{

                        var taxaEntrega = 0;
                        responseData.enderecos.map((value, index) => {
                            if (value.enderecoAtivo == '1'){
                                if (global.apidist == '1'){
                                    taxaEntrega = (value.taxaentrega_dist > 0) ? value.taxaentrega_dist : (value.taxaentrega_bairro > 0) ? value.taxaentrega_bairro : value.taxaentrega_cep;
                                }else{
                                    taxaEntrega = (value.taxaentrega_bairro > 0) ? value.taxaentrega_bairro : value.taxaentrega_cep;
                                }
                                
                            }
                        });

                        taxaEntrega = (taxaEntrega) ? taxaEntrega : 0;

                        if (typeof(taxaEntrega) === 'string'){
                            taxaEntrega    = parseFloat(taxaEntrega);
                        }

                        var total_ped    = this.returnTotPed(taxaEntrega);
                        var item = {
                            online   : false,
                            nome     : "Pedido Zerado",
                            valTroco : 0
                        }

                        var myFormaPagAtual = (total_ped > 0) ? 
                            (this.state.formaPagamentoAtual?.nome == item.nome) ? null : this.state.formaPagamentoAtual :
                            item;

                        this.setState({ 
                            cli_nome  : responseData.nome,
                            cli_tele  : responseData.telefone,
                            cli_tipo  : responseData.tipo,
                            cli_vericod : '',
                            cli_email : responseData.email,
                            cli_cpf   : responseData.cpf,
                            cli_enderecos : responseData.enderecos,

                            cli_pontos : responseData.pontos,

                            cli_cupons : responseData.cupons,
                            cli_cupons_inv : responseData.cupons_inv,

                            cli_cpf_edit  : (this.state.cli_cpf_edit == "") ? responseData.cpf : this.state.cli_cpf_edit,

                            taxaEntrega : taxaEntrega,

                            formaPagamentoAtual: myFormaPagAtual,
                            valPedidoZerado: (total_ped <= 0),                            

                            loading: false,
                        }, () => {
                            if (this.firstTime){
                                this.firstTime = false;
                                this.trocaEndereco();
                            }
                        });
                    }
                })
                .catch((error) =>{
                    
                    if(axios.isCancel(error)){
        
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                    else{
                
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                });
    
            } catch (err) {
                
            }       
        }else{
            this.carregaDadosSemLogin();
            this.setState({
                loading: false,
            });
        }
    }

    updateDimensions() {
        var alturaScroll = document.body.scrollHeight - window.innerHeight;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = alturaItems / (alturaScroll);

        var tmpPos = 0;
        var itemAtual = 0;

        for (var i = 0; i < this.arrPosItems.length; i++){
            var tmpItem = this.arrPosItems[i];
            tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

            if ( (scrolledY * proporcao) < ( ( (this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) - this.state.scrollMarginTop) ){
                break;
            }else{
                itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
            }
        }        

        if (this.state.posItemClicado == -1){
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});

                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
            }
        }else if (scrolledY == this.state.posItemClicado){

            if (this.state.eventClicado == -1){
                if (itemAtual !== this.state.itemAtivo) {
                    this.setState({ itemAtivo: itemAtual});
                }
    
                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
                this.setState({ 
                    posItemClicado: -1,
                });
            }else{
                this.myMenu.scrollTo({left: this.arrMenuItems[this.state.eventClicado].offsetLeft - 30, behavior: 'smooth'})
                this.setState({ 
                    itemAtivo: this.state.eventClicado,
                    posItemClicado: -1,
                    eventClicado : -1,
                });
            }
        }else{
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});
            }
        }


    }

    closeProgress = () => {
        this.setState({
            loading: false,
        });
    }

    setEnderecoAtivo(id) {

        
        try {
            // here place your signup logic     
            
            var CancelToken = axios.CancelToken;
            
            if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){

                global.buscar = (id == 'RETIRAD');

                if (global.buscar){

                    var tipoMudaState = 1;

                    try {
                        if (this.state.cupomSelected.idCupom){
                            if (this.state.cupomSelected.tipoDesc == 2){
                                tipoMudaState = 2;
                            }
                        }
                    } catch (error) {
                        
                    }

                    
                }

                var arrTmp = this.state.cli_enderecos;

                arrTmp.map((value, index) => {
                    if (value.idEndereco == id){
                        value.enderecoAtivo = 1; 
                    }else{
                        value.enderecoAtivo = 0; 
                    }
                });

                if (tipoMudaState == 2){
                    this.setState({
                        cli_enderecos: arrTmp,
                        alertaSelecionaEndereco: false, editaEnderecoNoLogin: false,
                        cupomSelected : [],
                    },() => {

                        //BS_ALE(this, 'Atenção', 'Já faz tempo que você iniciou o aplicativo e sua sessão expirou. Você será direcionado para a tela inicial.', () => {BS_ALE('teste2');});

                        BS_ALE(this,  'Atenção', 'O cupom selecionado não se aplica mais ao seu pedido.', () => {
                            if (global.msg_retirada.length > 0) {
                                BS_ALE(this, 'Atenção', global.msg_retirada)
                            }
                        });
                        this.carregaDadosSemLogin();
                    });
                }else{
                    this.setState({
                        cli_enderecos: arrTmp,
                        alertaSelecionaEndereco: false, editaEnderecoNoLogin: false,
                    },() => {

                        if (global.buscar){
                            if (global.msg_retirada.length > 0) {
                                setTimeout(() => {
                                    BS_ALE(this, 'Atenção', global.msg_retirada)
                                }, 500);
                            }
                        }

                        this.carregaDadosSemLogin();
                    });
                }

            }else{
                var parametros = 
                "parendati="  + id     +
                "&partoken="  + global.myToken +
                "&parres="    + global.cod_restaurante +
                "&pargrures=" + global.cod_grurest +
                "&parverapp=" + '99';
        
                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);    
                
                axios.post(
                    global.siteurl + 'cliente_mudaendati.php', 
                    parametros,
                    {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;
                    this.closeProgress();
                    if (responseData.resposta == '1') {
    
                        global.buscar = (id == 'RETIRAD');
    
                        if (global.buscar){
    
                            var tipoMudaState = 1;
    
                            try {
                                if (this.state.cupomSelected.idCupom){
                                    if (this.state.cupomSelected.tipoDesc == 2){
                                        tipoMudaState = 2;
                                    }
                                }
                            } catch (error) {
                                
                            }
    
                            
                        }

                        if (tipoMudaState == 2){
                            this.setState({
                                alertaSelecionaEndereco: false, editaEnderecoNoLogin: false,
                                cupomSelected : [],
                            },() => {
    
                                //BS_ALE(this, 'Atenção', 'Já faz tempo que você iniciou o aplicativo e sua sessão expirou. Você será direcionado para a tela inicial.', () => {BS_ALE('teste2');});
    
                                BS_ALE(this,  'Atenção', 'O cupom selecionado não se aplica mais ao seu pedido.', () => {
                                    if (global.msg_retirada.length > 0) {
                                        BS_ALE(this, 'Atenção', global.msg_retirada)
                                    }
                                });
                                this.carregaDados();
                            });
                        }else{
                            this.setState({
                                alertaSelecionaEndereco: false, editaEnderecoNoLogin: false,
                            },() => {
    
                                if (global.buscar){
                                    if (global.msg_retirada.length > 0) {
                                        setTimeout(() => {
                                            BS_ALE(this, 'Atenção', global.msg_retirada)
                                        }, 500);
                                    }
                                }
    
                                this.carregaDados();
                            });
                        }
                        
                        
                    }else{
                        BS_ALE(this,  'Atenção', responseData.msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                    }
                })
                .catch((error) =>{
                    
                    if(axios.isCancel(error)){
        
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                    else{
                
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                });
            }
    
            
        } catch (err) {
            
        }
    }

    componentDidMount(){

        const { navigation } = this.props;

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        let meuToken = localStorage.getItem(global.chaveLocalLogin);
        meuToken = meuToken;

        global.myToken = meuToken;
        this.setState({ 
            myToken        : meuToken,
            carrinhoCompra : this.carregaCarrinho(localCart),
            estadoLogin    : [0]
        }, () => {

            if (this.state.carrinhoCompra.length > 0){
                this.firstTime = true;
                this.carregaDados();
            }else{

                var msg = "Você precisa de ao menos um produto em seu carrinho pra fazer um pedido";
                this.props.navigate('/');
                BS_ALE(this, 'Atenção', msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);

            }
            
        });   
                
        if (window.visualViewport){
            this.fullWindowHeight = window.visualViewport.height;
            window.visualViewport.addEventListener('resize', this.handleResize);
        }else{
            this.fullWindowHeight = window.innerHeight;
        }
    }

    handleResize = () => {
        var fullWindowHeight = this.fullWindowHeight;
        if(window.visualViewport.height == fullWindowHeight) {
            if (this.keyboardIsProbablyOpen){
                this.keyboardIsProbablyOpen = false;
            }                
        } else if(window.visualViewport.height < fullWindowHeight*0.9) {

            
            if (!this.keyboardIsProbablyOpen){
                setTimeout( () => {
                    //window.scrollTo(0,5000);
                }, 500);
                this.keyboardIsProbablyOpen = true;
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.updateDimensions);

        if (window.visualViewport){
            window.visualViewport.removeEventListener("resize", this.handleResize);
        }
        
    }

    renderHeader = () => {

        return (
            <div style={{flexGrow: 1, justifyContent:'center', alignItems: 'center', fontSize: '20px', }}>
                <IconButton
                color="inherit"
                style={{padding: '7px'}}
                onClick={() => {
                    
                    this.props.navigate('carrinho-compra');
                }}
                >
                  <ArrowBack />
                </IconButton>
                Finalizar Compra
            </div>
        );
    }

    renderStyleShadow(ativo = false) {
        

        var arrStyle = {};

        if (ativo){
            arrStyle = {
                boxShadow: '0px 0px 3px 2px rgba(181,0,0,0.5)'
            };
        }

        return arrStyle;
    }

    renderStyleShadowCupom() {
        

        var arrStyle = {
            boxShadow: '0px 0px 3px 2px rgba(125, 125, 125,0.5)'
        };

        return arrStyle;
    }

    renderIcon( identificacao, ativo = false ) {

        var cor = (ativo) ? '#c00' : '#777';

        if (ativo){
            return (<CheckBoxIcon style={{margin: 'auto', color: cor}} />);
        }

        switch (identificacao){
            case "Casa":
                return (<HomeIcon style={{margin: 'auto', color: cor}} />);
            case "Trabalho":
                return (<WorkIcon style={{margin: 'auto', color: cor}} />);
            case "Apartamento":
                return (<ApartmentIcon style={{margin: 'auto', color: cor}} />);
            case "RETIRADA":
                return (<StorefrontIcon style={{margin: 'auto', color: cor}} />);
            default:
                return (<MapIcon style={{margin: 'auto', color: cor}} />);                                

        }
    }

    trocaEndereco() {

        var tipoEntrega = "retirada";

        if (this.state.cli_enderecos) {
            this.state.cli_enderecos.map( (item, index) => {
                if (item.enderecoAtivo == '1'){
                    if (item.identificacao == "RETIRADA"){
                        tipoEntrega   = "retirada";
                    }else{
                        tipoEntrega   = "entrega";
                    }
                    
                }
            })
        }

        this.setState({
            alertaSelecionaEndereco : true,
            edit_tipoEntrega : tipoEntrega,
        });
    }

    renderTabEndereco() {
        return (
            <div style={{borderRadius: '8px', backgroundColor: '#efefef', padding: '6px', margin: '10px', marginTop: '0px'}}>
                {(this.state.cli_enderecos) ? this.state.cli_enderecos.map( (item, index) => {

                    var enderecoAtivo = (item.enderecoAtivo == '1');

                    if (enderecoAtivo){
                        if ( (item.identificacao) && (item.identificacao != "") ) {

                            var enderecoFinal = '';
    
                            if (item.identificacao == "RETIRADA"){
                                enderecoFinal = (global.restEndereco != "") ? global.restEndereco : "Vou retirar meus produtos na loja";
                            }else{
                                if (item.rua.length > 0) {
                                    enderecoFinal = enderecoFinal + item.rua;
                                }
            
                                if (item.numero.length > 0) {
                                    if (enderecoFinal.length > 0) {
                                        enderecoFinal = enderecoFinal + ', ';
                                    }
            
                                    enderecoFinal = enderecoFinal + item.numero;
                                }
            
                                if (item.complemento.length > 0) {
            
                                    if (enderecoFinal.length > 0) {
                                        enderecoFinal = enderecoFinal + ', ';
                                    }
            
                                    enderecoFinal = enderecoFinal + item.complemento;
                                }
            
                                if (item.referencia.length > 0) {
            
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ', ';
                                }
            
                                enderecoFinal = enderecoFinal + item.referencia;
                                }
            
                                if (item.bairro.length > 0) {
            
                                    if (enderecoFinal.length > 0) {
                                        enderecoFinal = enderecoFinal + ', ';
                                    }
            
                                enderecoFinal = enderecoFinal + item.bairro;
                                }
            
                                if (item.cidade.length > 0) {
            
                                    if (enderecoFinal.length > 0) {
                                        enderecoFinal = enderecoFinal + ' - ';
                                    }
            
                                enderecoFinal = enderecoFinal + item.cidade;
                                }
            
                                if (item.estado.length > 0) {
            
                                    if (enderecoFinal.length > 0) {
                                        enderecoFinal = enderecoFinal + ' - ';
                                    }
            
                                enderecoFinal = enderecoFinal + item.estado;
                                }
            
                                if (item.cep.length > 0) {
            
                                    if (enderecoFinal.length > 0) {
                                        enderecoFinal = enderecoFinal + ' - ';
                                    }
            
                                enderecoFinal = enderecoFinal + item.cep;
            
                                }
                            }
                        }else{
    
                            var enderecoFinal = '';
        
                            if (item.complemento.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ', ';
                                }
        
                                enderecoFinal = enderecoFinal + item.complemento;
                            }
        
                            if (item.referencia.length > 0) {
        
                            if (enderecoFinal.length > 0) {
                                enderecoFinal = enderecoFinal + ', ';
                            }
        
                            enderecoFinal = enderecoFinal + item.referencia;
                            }
        
                            if (item.bairro.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ', ';
                                }
        
                            enderecoFinal = enderecoFinal + item.bairro;
                            }
        
                            if (item.cidade.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ' - ';
                                }
        
                            enderecoFinal = enderecoFinal + item.cidade;
                            }
        
                            if (item.estado.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ' - ';
                                }
        
                            enderecoFinal = enderecoFinal + item.estado;
                            }
        
                            if (item.cep.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ' - ';
                                }
        
                            enderecoFinal = enderecoFinal + item.cep;
        
                            }
    
                            
                        }
    
                        var styleTeste = this.renderStyleShadow(enderecoAtivo);
    
                        return (
                            <div style={Object.assign({}, {marginBottom: '0px', backgroundColor: '#fff', padding: '10px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                                <div onClick={() => this.trocaEndereco()} style={{width: '90%', paddingLeft: '10px'}}>
                                    <div style={{fontSize: '15px', fontWeight: '500', color: (enderecoAtivo) ? '#c00' : '#000'}}>{ ( (item.identificacao) && (item.identificacao != "") ) ? ((item.identificacao == 'RETIRADA') ? global.nome_rest : item.identificacao) : ( item.rua + ', ' + item.numero)}</div>
                                    <div style={{fontSize: '14px', fontWeight: (item.identificacao == 'RETIRADA') ? '500' : '400', color: (item.identificacao == 'RETIRADA') ? '#000' : '#777', whiteSpace: 'break-spaces'}}>{enderecoFinal}</div>
                                    {this.renderPrevisao(item)}
                                </div>
                                <div onClick={() => {
                                    if (item.identificacao != 'RETIRADA') {
                                        this.trocaEndereco()
                                    }
                                } } style={{width: '10%', minWidth: '48px', display: 'flex'}}>
                                    <EditIcon style={{margin: 'auto', fontSize: '42px', color: '#c00'}} />
                                </div>
                            </div>
                        );
                    }                    
                }) : null}
            </div>
        );
    }

    renderValTroco(item) {
        if (item.valTroco){
            if (item.valTroco.replace(',', '.') > 0){

                var total_ped = this.returnTotPed();
                
                var valorTroco   =  formatarNumero(item.valTroco.replace(',', '.') - total_ped, 2);

                return (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <text style={{marginRight: '30px', fontSize: '16px', color: '#aaa', fontWeight: '400'}} >{'Troco para: R$ ' + item.valTroco}</text>        
                        <text style={{marginRight: '30px', fontSize: '16px', color: '#aaa', fontWeight: '400'}} >{'Troco: R$ ' + valorTroco}</text>        
                    </div>                
                );
            }
        }
    }

    renderObsPedido() {
        return (
            <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', backgroundColor: '#fff', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <Comment />
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#555', fontWeight: '500'}} >{"Observações do pedido"}</text>
                            <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '14px', color: '#c00', fontWeight: '400'}} >{"Atenção !!! Não coloque observações de produtos aqui. Observações sobre produtos precisam ser colocadas nos campos de observação disponível em cada produto."}</text>
                        </div>
                    </div>
                    <div style={{width: '100%', display: 'flex'}}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="ped_obsped"
                            type="text"
                            id="ped_obsped"
                            autoComplete="ped_obsped"
                            value={this.state.ped_obsped}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderDocNotaPreenchido() {
        if (this.state.tipoDocNota != "nenhum"){
            var tipoDoc = (this.state.tipoDocNota == "cpf") ? "CPF" : "CNPJ";
            return (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <text style={{marginRight: '30px', fontSize: '16px', color: '#aaa', fontWeight: '400'}} >{ tipoDoc + ': ' + this.state.docDocNota}</text>
                </div>                
            );
        }
    }

    renderDocNota() {

        const { classes } = this.props;

        if (this.state.valPedidoZerado){
            return (<div></div>)
        }

        return (
            <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', backgroundColor: '#fff', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{width: '70%', display: 'flex'}}>
                        <AssignmentInd />
                        <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#555', fontWeight: '500'}} >{"CPF/CNPJ na nota"}</text>
                    </div>
                    <div style={{width: '30%', display: 'flex'}}>
                        <a
                            onClick={() => {
                                this.setState({
                                    alertaDocNota: true,
                                    finalizandoPedido: false,
                                    edit_tipoDocNota  : (this.state.docDocNota != "") ? this.state.tipoDocNota  : "cpf",
                                    cli_cpf_edit      : (this.state.docDocNota != "") ? this.state.docDocNota   : this.state.cli_cpf,
                                });
                            }}
                            style={{color: '#3496eb', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                        >
                            { (this.state.tipoDocNota != "nenhum") ? "Alterar" : "Adicionar"}
                        </a>
                    </div>
                </div>
                {this.renderDocNotaPreenchido()}
            </div>
        );
    }

    renderTituloEntrega() {
        
        var IconeEntrega = <TwoWheelerIcon />;
        var msgEntrega   = "Entrega";

        if (this.state.cli_enderecos) {
            this.state.cli_enderecos.map( (item, index) => {
                if (item.enderecoAtivo == '1'){
                    if (item.identificacao == "RETIRADA"){
                        IconeEntrega = <StorefrontIcon />;
                        msgEntrega   = "Retirada";
                    }else{
                        IconeEntrega = <TwoWheelerIcon />;
                        msgEntrega   = "Entrega";
                    }
                    
                }
            })
        }

        return (
            <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', backgroundColor: '#fff', display: 'flex'}}>
                <div style={{width: '80%', display: 'flex'}}>
                    {IconeEntrega}
                    <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '20px', color: '#555', fontWeight: '500'}} >Pedido para <text style={{color: '#c00', fontWeight: 'bold'}}>{msgEntrega}</text></text>
                </div>
                <div style={{width: '20%', display: 'flex'}}>
                    <a
                        onClick={() => {
                            this.trocaEndereco();
                        }}
                        style={{color: '#3496eb', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                    >
                        { "Mudar"}
                    </a>
                </div>
            </div> 
        );
    }

    renderFormaAtualPag() {

        const { classes } = this.props;

        if (this.state.valPedidoZerado){
            return (<div></div>)
        }else if (this.state.formaPagamentoAtual){
            var item = this.state.formaPagamentoAtual;

            var iconePagamento = (item.online) ? MPIcon : MoneyIcon;

            return (
                <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', backgroundColor: '#fff', display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '70%'}}>
                            <img src={iconePagamento} style={{marginRight: '5px', height: (item.online) ? '28px' : '20px'}}/>
                            <text style={{marginRight: '30px', fontSize: '15px', color: '#555', fontWeight: '600'}} >{item.nome}</text>
                        </div>
                        <div style={{width: '30%', display: 'flex'}}>
                            <a
                                onClick={() => {
                                    this.carregaFormasPag();
                                }}
                                style={{color: '#3496eb', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                            >
                                { "Mudar"}
                            </a>
                        </div>
                    </div>
                    {this.renderValTroco(item)}
                </div> 
            );
        }else{
            return (
                <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', backgroundColor: '#fff', display: 'flex'}}>
                    <div style={{width: '70%', display: 'flex'}}>
                        <PaymentIcon />
                        <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#555', fontWeight: '500'}} >{"Forma de pagamento"}</text>
                    </div>
                    <div style={{width: '30%', display: 'flex'}}>
                        <a
                            onClick={() => {
                                this.carregaFormasPag();
                            }}
                            style={{color: '#3496eb', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                        >
                            { "Escolher"}
                        </a>
                    </div>
                    
                    
                </div> 
            );
        }
    }

    handleChangeItensFidelidade = (item, valorCalcPontos) => {
        if (item.qtde > 1){
            item.qtde = item.qtde - 1;

            this.state.carrinho_compra_copia.push({
                chaveLocalStorage : item.chaveLocalStorage,
                listaGruProdAtual : item.listaGruProdAtual,
                alterandoIndex : item.alterandoIndex,
                qtde: 1,
                grupoProds:  item.grupoProds,
                grupoProdsCod:  item.grupoProdsCod,
                grupoProdsMisto:  item.grupoProdsMisto,
                checkedFidelidade: (item.checkedFidelidade >= 1) ? '0' : '1',
                valorDescFidelidade: item.valorDescFidelidade,
                qtdeSabores:  item.qtdeSabores,
                saborAtual:   item.saborAtual,
                sabores:     item.sabores,
                valorUnit:    item.valorUnit,
                grupoAdics:  item.grupoAdics,
                qtdeSabores: item.qtdeSabores,
                valorCalcPontos: valorCalcPontos,
            });
        }else{
            item.valorCalcPontos   = (item.checkedFidelidade >= 1) ? 0 : valorCalcPontos;
            item.checkedFidelidade = (item.checkedFidelidade >= 1) ? '0' : '1';
        }

        this.setState({
            update : true
        });
    }

    renderListaProdFidelidade = (items, resultados) => {
        const { classes } = this.props;
        var resultado = null;

        resultados.pontosUsados = 0;

        return items.map((item, index) => {

            var sabor = item.sabores;
            var qtde  = item.qtde;

            //var valorTot = (item.valorUnit - 0).toFixed(2).replace('.', ',');
            var valorTot = formatarNumero(item.valorUnit - 0, 2);

            var saboresNome = "";

            item.sabores.map( (value, index) => {

                var prod = value.produto;

                saboresNome += (saboresNome.length > 0) ? ' ' : '';

                saboresNome += ((item.qtdeSabores > 1) ? '1/' + item.qtdeSabores + ' ' : '') + prod.nomeProduto;
            });

            var pontosBonus = 0;

            try {
                pontosBonus = parseInt(item.grupoProdsFideResg);
            } catch (error) {
                pontosBonus = 0;
            }

            var arrResult = {
                "pontosAdicBonus" : 0
            }

            var preco_prod_sem_adic = this.getPrecoProdSemAdic(item, arrResult);

            var valorCalcPontos = preco_prod_sem_adic;
            var pontosGerados = Math.floor(((valorCalcPontos) / global.programaFidelidadeFatorValMin));

            var pontosResgate = 0;

            try {
                var fator = parseInt(global.programaFidelidadeFator);
                fator = (isNaN(fator)) ? 0 : fator;

                pontosGerados = (isNaN(pontosGerados)) ? 0 : pontosGerados;

                pontosBonus = (isNaN(pontosBonus)) ? 0 : pontosBonus;

                pontosResgate = parseInt(Math.ceil(((100 / fator) / global.programaFidelidadeFatorValMin) * valorCalcPontos) + pontosBonus);
                
                if (pontosResgate <= 0){
                    pontosResgate = 1;
                }

                pontosResgate = pontosResgate + arrResult["pontosAdicBonus"];
            } catch (error) {
                pontosResgate = 1;
            }

            var itemSelecionado = (item.checkedFidelidade >= 1);

            resultados.pontosUsados += (itemSelecionado) ? pontosResgate : 0;

            var arrLengthQtd = [];
            for (var i = 0; i < qtde; i++){
                arrLengthQtd.push("");
            }

            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', margin: '5px 0px'}} onClick={() => {
                            
                }}>
                    <div onClick={() => {this.handleChangeItensFidelidade(item, valorCalcPontos)}} style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '8%', margin: 'auto', display: 'flex', flexDirection: 'column'}}>
                        <FormControlLabel
                            control={<AdicionaCheckbox checked={itemSelecionado} value={"1"} name="checkedG" />}
                            style={{color: corCheckBoxAdiciona, width: '100%',}}
                        />
                        </div>   
                        <div style={{width: '46%', display: 'flex', flexDirection: 'column'}}>
                            <text style={{fontSize: '16px', color: '#000', fontWeight: '420'}} >{saboresNome}</text>
                            <text style={{fontSize: '16px', color: '#888', fontWeight: 'normal'}} >{item.valorUnit.replace('.', ',')}</text>
                        </div>   
                        <div style={{width: '46%', display: 'flex', flexDirection: 'column'}}>
                            <div style={{fontSize: '17px', fontWeight: '420', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right', textDecoration: (itemSelecionado) ? 'line-through' : ''}}>R$ {valorTot}</div>
                            <div style={{color: '#349449', fontSize: '14px', fontWeight: '400', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right'}}>{(itemSelecionado) ? 'Trocando' : 'Troque'} por {pontosResgate} ponto(s)</div>
                        </div>
                    </div>
                </div>
            );
            
            
        });
    }

    renderLista = (items) => {
        const { classes } = this.props;
        var resultado = null;

        var myCtX = this;

        return items.map((item, index) => {

            var pontosBonus = 0;

            try {
                pontosBonus = parseInt(item.grupoProdsFideResg);
            } catch (error) {
                pontosBonus = 0;
            }

            var arrResult = {
                "pontosAdicBonus" : 0
            }

            var preco_prod_sem_adic = myCtX.getPrecoProdSemAdic(item, arrResult);

            var valorCalcPontos = preco_prod_sem_adic;

            var pontosGerados = Math.floor(((valorCalcPontos) / global.programaFidelidadeFatorValMin));

            var pontosResgate = parseInt(Math.ceil(((100 / global.programaFidelidadeFator) / global.programaFidelidadeFatorValMin) * valorCalcPontos) + (pontosBonus));
            
            if (pontosResgate < 1){
                pontosResgate = 1;
            }

            pontosResgate = pontosResgate + arrResult["pontosAdicBonus"];
            
            var sabor = item.sabores;
            var qtde  = item.qtde;

            //var valorTot = (qtde * item.valorUnit).toFixed(2).replace('.', ',');
            var valorTot = formatarNumero((qtde * item.valorUnit), 2);

            var saboresNome = "";

            item.sabores.map( (value, index) => {

                var prod = value.produto;

                saboresNome += (saboresNome.length > 0) ? ' ' : '';

                saboresNome += ((item.qtdeSabores > 1) ? '1/' + item.qtdeSabores + ' ' : '') + prod.nomeProduto;
            });

            var strQtde = ((qtde % 1 != 0) ? formatarNumero(qtde, 3) : qtde.toString()).replace('.', ',');


            var itemSelecionado = (item.checkedFidelidade >= 1 && !this.state.alertaProgramaFidelidade);
            
            //var valorTotFidelidade =    ((qtde * item.valorUnit) - 
            //                            ((isNaN(item.valorCalcPontos)) ? 0 : item.valorCalcPontos )).toFixed(2).replace('.', ',');
            var valorTotFidelidade   = formatarNumero(((qtde * item.valorUnit) - ((isNaN(item.valorCalcPontos)) ? 0 : item.valorCalcPontos )), 2);

            if ((global.programaFidelidadeAdicional == '1') && (itemSelecionado)){
                valorTotFidelidade = (0).toFixed(2).replace('.', ',');
            }

            if (this.state.alertaProgramaFidelidade){
                valorTotFidelidade = valorTot;
            }

            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', margin: '5px 0px'}} onClick={() => {
                    
                }}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '54%', display: 'flex', flexDirection: 'column'}}>
                            <text style={{fontSize: '16px', color: '#000', fontWeight: '420'}} >{saboresNome}</text>
                            <text style={{fontSize: '16px', color: '#888', fontWeight: 'normal'}} >{strQtde + " x " + item.valorUnit.replace('.', ',')}</text>
                        </div>
                        <div style={{width: '46%', display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{flex: '1'}}></div>
                                <div style={{fontSize: '12px', fontWeight: '420', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right', textDecoration: (itemSelecionado) ? 'line-through' : ''}}>{(itemSelecionado) ? 'R$ ' + valorTot : ''}</div>
                                <div style={{fontSize: '17px', fontWeight: '420', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right', color: (itemSelecionado) ? '#349449' : ''}}>R$ {valorTotFidelidade}</div>
                            </div>
                            <div style={{color: '#980000', fontSize: '14px', fontWeight: '400', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right'}}>{(itemSelecionado) ? pontosResgate + ' ponto(s)' : ''}</div>
                        </div>
                    </div>
                </div>
            );
            
            
        });
    }

    renderCarrinho = () => {

        const { classes } = this.props;
        
        return (

            <div style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', }}>
                <div>
                    <div>
                        <div style={{display: 'flex', flexDirection: 'column', }}>
                            {this.renderLista(this.state.carrinhoCompra)}
                        </div>        
                    </div> 
                </div>
            </div>  

        );
    }

    renderProdutos() {
        return(
            this.renderCarrinho()
        );
    }

    renderCliInfos = () => {
        
        if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){
            return (
                <div>
                    <div style={{width: '70%', display: 'flex'}}>
                        <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '18px', color: '#555', fontWeight: '500'}} >{"Suas Informações"}</text>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', padding: '0px 6px', margin: '0px 10px 10px 10px',}}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="cli_nome_edit"
                            label="Nome"
                            name="cli_nome_edit"
                            autoComplete="name"
                            autoFocus
                            value={this.state.cli_nome_edit}
                            onChange={this.handleChange}
                            inputRef={this.cp_nome}
                            style={{marginRight: '6px'}}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="cli_tele_edit"
                            label="Celular"
                            name="cli_tele_edit"
                            InputProps={{
                                ref: this.cp_celular,
                                inputComponent: TelefoneMaskInput,
                                inputProps: {
                                    ref: this.cp_celular,
                                    component: MaskedInput,
                                },
                            }}
                            value={this.state.cli_tele_edit}
                            onChange={this.handleChange}
                            style={{marginRight: '6px'}}
                        />
                    </div>
                </div>
            )
        }
    }

    renderInfoCliNoLog = (classes) => {
        
        return (
            <div>
                <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                    <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '0px 10px 10px 10px', }}>
                    <div>Teste</div>
                </div>
            </div>
        );
    }

    renderSeparador = (condicao) => {

        const { classes } = this.props;
        
        if (condicao){
            return(
                <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                    <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                </div>
            );
        }
    }

    renderInfoCompra() {

        const { classes } = this.props;

        return (
            <div>
                {this.renderCliInfos()}
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px',}}>
                    {this.renderTituloEntrega()}
                </div>
                {this.renderTabEndereco()}
                <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                    <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '0px 10px 10px 10px', }}>
                    {this.renderProdutos()}
                </div>
                {this.renderMensageKG()}
                <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                    <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px', }}>
                    {this.renderProgramaFide()}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px', }}>
                    {this.renderCupomDesc()}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px', }}>
                    {this.renderResumoPed()}
                </div>
                {this.renderSeparador(!this.state.valPedidoZerado)}
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px',}}>
                    {this.renderFormaAtualPag()}
                </div>
                {this.renderSeparador(!this.state.valPedidoZerado)}
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px',}}>
                    {this.renderDocNota()}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                    <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px',}}>
                    {this.renderObsPedido()}
                </div>
            </div>
        )
    }
    
    resolveAlertaDesejaNota = () => {
        this.setState({
            alertaDesejaNota: false,
            alertaDocNota   : true,
            finalizandoPedido : true,
        });
    }

    handleCloseDesejaNota = () => {
        this.setState({
            alertaDesejaNota: false,
        });
    }

    resolveAlertaTroco = () => {
        this.setState({
            alertaTroco: false,
            alertaDefineTroco: true,
            valorTroco: '',
            alertaSelecionaPagamento: false,
        });
    }

    handleCloseTroco = () => {
        this.setState({
            alertaTroco: false,
            alertaSelecionaPagamento: false,
            formaPagamentoAtual: this.state.tmpFormaPagTroco,
        });
    }

    selecionaPagamentoMP = () => {

        var item = {
            online   : true,
            nome     : "Mercado Pago",
            valTroco : 0
        }

        this.setState({
            formaPagamentoAtual: item,
            alertaSelecionaPagamento: false,
        });

    }

    selecionaFormaPag = (item) => {

        if (item.troco == '1'){
            this.setState({
                tmpFormaPagTroco: item,
                alertaSelecionaPagamento: false,
                alertaTroco: true,
            });

        }else{

            this.setState({
                formaPagamentoAtual: item,
                alertaSelecionaPagamento: false,
            });

        }
        
    }

    renderFormaPag = (item) => {

        var desconto = 0;

        try {
            desconto = parseFloat(item.desconto);                        
        } catch (error) {  
            desconto = 0;
        }

        
        if (desconto > 0){
            return (
                <div>
                    <div onClick={() => { this.selecionaFormaPag(item) }} style={{border: "2px groove rgba(0, 48, 13, 0.3)", borderRadius: "8px", display: "flex", marginBottom: "10px", flexDirection: 'row'}}>
                        <div style={{borderRadius: '8px', width: '50%',  padding: "10px", backgroundColor: '#fff', display: 'flex'}}>
                            <img src={MoneyIcon} style={{margin: "auto 5px auto 0px", height: '26px'}}/>
                            <text style={{marginRight: '30px', fontWeight: '500', fontSize: '18px', color: '#00300d', display: 'flex', alignItems: 'center', }} >{item.nome}</text>
                        </div> 
                        <div style={{marginLeft: 'auto'}} class="discount-label green"> <span style={{fontSize: '17px', fontWeight: 'bold'}}>{desconto.toString().replace('.', ',')}% OFF</span> </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div>
                    <div style={{border: "2px groove rgba(0, 0, 0, 0.2)", borderRadius: "8px", padding: "10px", marginBottom: "10px"}}>
                        <div onClick={() => {
                            this.selecionaFormaPag(item)
                        }} style={{borderRadius: '8px', width: '100%', backgroundColor: '#fff', display: 'flex'}}>
                            <img src={MoneyIcon} style={{marginRight: '5px', height: '22px'}}/>
                            <text style={{marginRight: '30px', fontSize: '15px', color: '#000', display: 'flex', alignItems: 'center', }} >{item.nome}</text>
                        </div> 
                    </div>
                </div>
            );
        }
    }

    renderCupons() {

        const { classes } = this.props;

        return (
            <div style={{borderRadius: '8px', backgroundColor: '#fff', margin: '6px 0px 4px 0px'}}>
                {(this.state.cli_cupons) ? this.state.cli_cupons.map( (item, index) => {
                    return (
                        this.renderCupom(item)
                    );
                }) : null}
            </div>
        );
    }

    converteData = (data) => {

        var resultado = "";

        try {
            resultado = data.substring(8, 10) + '/' +
                        data.substring(5, 7) + '/' +
                        data.substring(0, 4);
        } catch (error) {
            resultado = "XX/XX/XXXX";
        }

        return resultado;

    }

    selecionaCupom = (cupom) => {
        this.setState({
            cupomSelected : cupom,
            alertaSelecionaCupom : false,
            formaPagamentoAtual : null,
        });
    }

    renderTxtDescCupom = (cupom) => {

        if (cupom.tipoDesc == 0){
            return (
                "Desconto de " + parseFloat(cupom.desconto).toFixed(0) + "% em seus produtos."    
            );
        }else if (cupom.tipoDesc == 1){
            return (
                "Desconto de " + 'R$ ' + cupom.desconto.replace('.', ',') + " em seus produtos."    
            );
        }else {

            var taxa_ent    = this.state.taxaEntrega;

            if (typeof(taxa_ent) === 'number'){
                //taxa_ent    = taxa_ent.toFixed(2);
                taxa_ent    = formatarNumero(taxa_ent, 2).replace(',', '.');
            }

            taxa_ent = ((taxa_ent.trim() == "0.00") || (taxa_ent.trim() == "0,00")) ? "0,00" : taxa_ent.replace('.', ',');
            taxa_ent = 'R$ ' + taxa_ent;

            return (
                "Desconto de " + taxa_ent + " na sua taxa de entrega."    
            );
        }
    }

    renderCupom = (cupom) => {

        var styleTeste = this.renderStyleShadowCupom();
        
        return (
            <div onClick={() => {this.selecionaCupom(cupom)}} style={Object.assign({}, {margin: '20px 0px', padding: '12px', borderRadius: '8px', border: 'solid 3px #e8d500', borderBottomWidth: '2px', backgroundColor: '#ffeb06'})}>
                <div style={{fontSize: '16px', color: '#de1212', fontWeight: 'bold'}}>{cupom.descricao}</div>
                <div style={{fontSize: '14px', color: '#de1212', fontWeight: '420' }}>
                    {
                        this.renderTxtDescCupom(cupom)
                    }
                </div>

                <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px'}}>
                    <div style={{fontSize: '12px', flex: 1, color: 'black', fontWeight: 'bold'}}>
                        <div style={{marginLeft: 'auto'}}>
                            {"Disponível até: " + this.converteData(cupom.dataFim)}
                        </div>
                    </div>
                    <div style={{fontSize: '12px', flex: 1, color: 'black', fontWeight: 'bold'}}>
                        <div style={{marginLeft: 'auto', width: 'fit-content'}}>
                            { (cupom.valMin > 0) ? 
                                "Valor mínimo: " + 'R$ ' + cupom.valMin.replace('.', ',') :
                                "Sem valor mínimo"
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderPagamentos() {

        const { classes } = this.props;

        return (
            <div style={{borderRadius: '8px', backgroundColor: '#fff', margin: '6px 0px 12px 0px'}}>
                {(this.state.listaPags) ? this.state.listaPags.map( (item, index) => {
                    return this.renderFormaPag(item);
                }) : null}
            </div>
        );
    }

    renderMsgTipoEntrega() {
        if (this.state.edit_tipoEntrega == "retirada"){
            return (
                <text style={{margin: 'auto auto 10px auto', color: '#099800', fontSize: '15px', fontWeight: '450'}}>Confirme a retirada clicando na loja</text>
            );
        }else{
            return (
                <text style={{margin: 'auto auto 10px auto', color: '#099800', fontSize: '15px', fontWeight: '450'}}>Confirme a entrega selecionando o endereço</text>
            );
        }
    }

    renderBtnAddEnd() {

        const { classes } = this.props;

        if ((global.pedidoNoCad == '1') && ((global.myToken == '') || (!global.myToken))){
            return null;
        }else if (this.state.edit_tipoEntrega == "retirada"){
            return null;
        }else{
            return (
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                        
                        this.props.navigate('usuarioinfo-addend', {
                            state: { 
                                finalizandoCad: true,
                                stateFinalizaCompra: this.state
                            },
                            
                        });
                    }}
                    style={{backgroundColor: '#3496eb'}}
                >
                    Adicionar Endereço
                </Button>
            );
        }
    }

    renderEnderecos() {
        return (
            <div style={{borderRadius: '8px', backgroundColor: '#efefef', padding: '10px', paddingBottom: '0px'}}>
                {(this.state.cli_enderecos) ? this.state.cli_enderecos.map( (item, index) => {

                    if (this.state.edit_tipoEntrega == "retirada"){
                        if (item.identificacao != "RETIRADA"){
                            return null;
                        }
                    }else{
                        if (item.identificacao == "RETIRADA"){
                            return null;
                        }else if (item.identificacao == "RETBLQ"){
                            return null;
                        }
                    }

                    if (item.identificacao == "DELBLQ"){
                        return null;
                    }

                    var enderecoAtivo = (item.enderecoAtivo == '1');

                    if ( (item.identificacao) && (item.identificacao != "") ) {

                        var enderecoFinal = '';

                        if (item.identificacao == "RETIRADA"){
                            enderecoFinal = (global.restEndereco != "") ? global.restEndereco : "Vou retirar meus produtos na loja";
                        }else{
                            if (item.rua.length > 0) {
                                enderecoFinal = enderecoFinal + item.rua;
                            }
        
                            if (item.numero.length > 0) {
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ', ';
                                }
        
                                enderecoFinal = enderecoFinal + item.numero;
                            }
        
                            if (item.complemento.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ', ';
                                }
        
                                enderecoFinal = enderecoFinal + item.complemento;
                            }
        
                            if (item.referencia.length > 0) {
        
                            if (enderecoFinal.length > 0) {
                                enderecoFinal = enderecoFinal + ', ';
                            }
        
                            enderecoFinal = enderecoFinal + item.referencia;
                            }
        
                            if (item.bairro.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ', ';
                                }
        
                            enderecoFinal = enderecoFinal + item.bairro;
                            }
        
                            if (item.cidade.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ' - ';
                                }
        
                            enderecoFinal = enderecoFinal + item.cidade;
                            }
        
                            if (item.estado.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ' - ';
                                }
        
                            enderecoFinal = enderecoFinal + item.estado;
                            }
        
                            if (item.cep.length > 0) {
        
                                if (enderecoFinal.length > 0) {
                                    enderecoFinal = enderecoFinal + ' - ';
                                }
        
                            enderecoFinal = enderecoFinal + item.cep;
        
                            }
                        }
                    }else{

                        var enderecoFinal = '';
    
                        if (item.complemento.length > 0) {
    
                            if (enderecoFinal.length > 0) {
                                enderecoFinal = enderecoFinal + ', ';
                            }
    
                            enderecoFinal = enderecoFinal + item.complemento;
                        }
    
                        if (item.referencia.length > 0) {
    
                        if (enderecoFinal.length > 0) {
                            enderecoFinal = enderecoFinal + ', ';
                        }
    
                        enderecoFinal = enderecoFinal + item.referencia;
                        }
    
                        if (item.bairro.length > 0) {
    
                            if (enderecoFinal.length > 0) {
                                enderecoFinal = enderecoFinal + ', ';
                            }
    
                        enderecoFinal = enderecoFinal + item.bairro;
                        }
    
                        if (item.cidade.length > 0) {
    
                            if (enderecoFinal.length > 0) {
                                enderecoFinal = enderecoFinal + ' - ';
                            }
    
                        enderecoFinal = enderecoFinal + item.cidade;
                        }
    
                        if (item.estado.length > 0) {
    
                            if (enderecoFinal.length > 0) {
                                enderecoFinal = enderecoFinal + ' - ';
                            }
    
                        enderecoFinal = enderecoFinal + item.estado;
                        }
    
                        if (item.cep.length > 0) {
    
                            if (enderecoFinal.length > 0) {
                                enderecoFinal = enderecoFinal + ' - ';
                            }
    
                        enderecoFinal = enderecoFinal + item.cep;
    
                        }

                        
                    }

                    var styleTeste = this.renderStyleShadow(enderecoAtivo);

                    return (
                        <div style={Object.assign({}, {marginBottom: '10px', backgroundColor: '#fff', padding: '18px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                            <div onClick={() => this.setEnderecoAtivo(item.idEndereco)} style={{width: '6%', minWidth: '40px', display: 'flex'}}>
                                {this.renderIcon(item.identificacao, enderecoAtivo)}
                            </div>
                            <div onClick={() => {
                                    
                                        this.setEnderecoAtivo(item.idEndereco);
                                        
                                    
                                    }} style={{width: '84%'}}>
                                <div style={{fontSize: '15px', fontWeight: '500', color: (enderecoAtivo) ? '#c00' : '#000'}}>{ ( (item.identificacao) && (item.identificacao != "") ) ? ((item.identificacao == 'RETIRADA') ? 'Retirar na Loja' : item.identificacao) : ( item.rua + ', ' + item.numero)}</div>
                                <div style={{fontSize: '14px', fontWeight: '400', color: '#777'}}>{enderecoFinal}</div>
                                {this.renderPrevisao(item)}
                            </div>
                        </div>
                    );
                }) : null}
            </div>
        );
    }

    renderPrevisao = (item) => {

        if (item.prevTempo > 0){
            return (
                <div style={{fontSize: '14px', fontWeight: '400', color: '#c00'}}>{((item.identificacao == 'RETIRADA') ? 'Previsão de retirada: ' : 'Previsão de entrega: ') + item.prevTempo + ' minutos'}</div>
            );
        }
        
    }

    renderBairrosItens = () => {
        return this.state.listaBairros.map((value, index) => {
            return (
                <MenuItem value={value.gondor}>{value.nome}</MenuItem>
            );
        });
    }

    handleDropChange = (event) => {
        this.setState({
            cad_bairro : event.target.value
        });
    };

    _renderBairro = () => {

        const { classes } = this.props;
    
        if (this.state.estadoCEP == 2){
          return (
            <FormControl variant="outlined" required className={classes.enderecoInputs} style={{width: '100%', marginTop: '8px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Bairro</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.cad_bairro}
                onChange={this.handleDropChange}
                label="Bairro"
                >
                    {this.renderBairrosItens()}
                </Select>
            </FormControl>);
        } else {
          return (
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cad_bairro"
                label="Bairro"
                name="cad_bairro"
                className={classes.enderecoInputs}
                value={this.state.cad_bairro}
                onChange={this.handleChange}
                inputProps={{
                    maxLength: 40
                }}
            />
          );
        }    
    } 

    handleIdentificacaoChange = (event) => {
        this.setState({
            cad_identificacao : event.target.value,
        });
    };

    renderFormEnderecoFields = () => {

        const { classes } = this.props;

        if ( (this.state.estadoCEP == 1) || (this.state.estadoCEP == 2) ){
            return (
            <div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cad_estado"
                        label="Estado"
                        name="cad_estado"
                        value={this.state.cad_estado}
                        onChange={this.handleChange}
                        className={classes.enderecoInputs}
                        autoCapitalize='characters'
                        style={{
                            width: '40%',
                            marginRight: '10px',
                        }}
                        inputProps={{
                            maxLength: 2,
                            style: {textTransform: 'uppercase'}
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cad_cidade"
                        label="Cidade"
                        name="cad_cidade"
                        value={this.state.cad_cidade}
                        className={classes.enderecoInputs}
                        onChange={this.handleChange}
                        inputProps={{
                            maxLength: 20
                        }}
                    />
                </div>
                {this._renderBairro()}
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="cad_rua"
                    label="Rua"
                    name="cad_rua"
                    autoComplete="address"
                    value={this.state.cad_rua}
                    className={classes.enderecoInputs}
                    onChange={this.handleChange}
                    inputProps={{
                        maxLength: 50
                    }}
                />

                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cad_numero"
                        label="Nº"
                        name="cad_numero"
                        value={this.state.cad_numero}
                        className={classes.enderecoInputs}
                        onChange={this.handleChange}
                        style={{
                            width: '40%',
                            marginRight: '10px',
                        }}
                        inputProps={{
                            maxLength: 5
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="cad_complemento"
                        label="Complemento"
                        name="cad_complemento"
                        value={this.state.cad_complemento}
                        className={classes.enderecoInputs}
                        onChange={this.handleChange}
                        inputProps={{
                            maxLength: 20
                        }}
                    />
                </div>
                
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="cad_referencia"
                    label="Referência"
                    name="cad_referencia"
                    value={this.state.cad_referencia}
                    className={classes.enderecoInputs}
                    onChange={this.handleChange}
                    inputProps={{
                        maxLength: 40
                    }}
                />
                
                <Tooltip title={"Uma etiqueta para seu endereço, para mantê-los organizados"} 
                    open={this.state.identFocused}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top" classes={{ tooltip: classes.myTooltip }}>
                    <span>
                        <FormControl variant="outlined" required className={classes.enderecoInputs} style={{width: '100%', marginTop: '8px'}}>
                            <InputLabel id="select_identificacao_label">Identificação</InputLabel>
                            <Select
                            labelId="select_identificacao_label"
                            id="select_identificacao"
                            value={this.state.cad_identificacao}
                            onChange={this.handleIdentificacaoChange}
                            label="Identificação"
                            >
                                {optionsIdentificacao.map( (value, index) => {
                                    return (<MenuItem value={index} name="">{value}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>                              
                    </span>
                </Tooltip>
                
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.cadastraEndereco()}
                    style={{backgroundColor: '#3496eb', marginTop: '18px'}}
                >
                    Salvar Endereço
                </Button>
            </div>);
        }else{
            return (<div></div>)
        }
    }

    verificaCamposEndereco(cep, estado, cidade, bairro, rua, numero, referencia) {
        var r = true;
        var msg = '';
        
        if (cep.trim().length != 9){
          r = false;
          msg = 'O CEP precisa ser preenchido corretamente';
        }else if (estado.trim().length != 2){
          r = false;
          msg = 'O Estado precisa ser informado corretamente';
        }else if (cidade.trim().length <= 4){
          r = false;
          msg = 'A Cidade precisa ser informada corretamente';
        }else if (bairro.trim().length <= 4){
          r = false;
          msg = 'O Bairro precisa ser informado corretamente';
        }else if (rua.trim().length <= 4){
          r = false;
          msg = 'A Rua precisa ser informada corretamente';
        }else if (numero.trim().length <= 0){
          r = false;
          msg = 'Insira o número da sua residência';
        }else if (referencia.trim().length <= 4){
          r = false;
          msg = 'A Referência precisa ser informada corretamente';
        }
    
        if (!r){
          BS_ALE(this,  'Atenção', msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);
        }
    
        return r;
    }

    getBairroByid = (array, id) => {
        return array.find((element) => {
            return element.gondor === id;
        })
    }

    usaEndereco = (cad_identificacao, cad_cep, cad_estado, cad_cidade, codbairro, bairro, cad_rua, cad_numero, cad_complemento, cad_referencia) => {

        var CancelToken = axios.CancelToken;
          
        let source = CancelToken.source();
        setTimeout(() => {
        source.cancel();
        }, global.timeOutTimer);

        try {

            var parametros = 
            "partoken="      + this.state.myToken             +
            "&parres="       + global.cod_restaurante         +
            "&parverapp="    + global.versaoApp               +
            "&pargrures="    + global.cod_grurest             +
            "&partipo="      + 1                              +
            "&parindentif="  + ( (cad_identificacao == 0) ? "" : optionsIdentificacao[this.state.cad_identificacao] ) +
            "&parcep="       + cad_cep.replace('-', '')       +
            "&parest="       + cad_estado                     +
            "&parcidade="    + cad_cidade                     +
            "&parcodbairro=" + codbairro                      +
            "&parbairro="    + bairro                         +
            "&parrua="       + cad_rua                        +
            "&parnumero="    + cad_numero                     +
            "&parcomp="      + cad_complemento                +
            "&parref="       + cad_referencia;    
            
            axios.post(
                global.siteurl + 'cliente_info_nologin_endereco.php', 
                parametros,
                {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;
                    
                    if (responseData.resposta == '1') {
                        
                        var arrTmp = this.state.cli_enderecos;

                        var indexDel = -1;
                        arrTmp.map((value, index) => {
                            value.enderecoAtivo = 0; 
                            if (value.idEndereco == 'ENDOFF'){
                                indexDel = index;
                            }
                        });

                        if (indexDel != -1){
                            arrTmp.splice(indexDel, 1);
                        }

                        arrTmp.push({
                            bairro: bairro,
                            cep: cad_cep,
                            cidade: cad_cidade,
                            codigo_bairro: codbairro,
                            complemento: cad_complemento,
                            enderecoAtivo: 1,
                            estado: cad_estado,
                            idEndereco: 'ENDOFF',
                            identificacao: ( (cad_identificacao == 0) ? "" : optionsIdentificacao[this.state.cad_identificacao] ),
                            numero: cad_numero,
                            prevTempo: '0',
                            prevTempoDel: '0',
                            prevTempoRet: '0',
                            referencia: cad_referencia,
                            rua: cad_rua,
                            taxaentrega_bairro: responseData.taxaentrega_bairro,
                            taxaentrega_cep: responseData.taxaentrega_cep,
                            taxaentrega_dist: responseData.taxaentrega_dist,
                            tipo: 3
                        });

                        var taxaEntrega = 0;

                        if (global.apidist == '1'){
                            taxaEntrega = (responseData.taxaentrega_dist > 0) ? responseData.taxaentrega_dist : (responseData.taxaentrega_bairro > 0) ? responseData.taxaentrega_bairro : responseData.taxaentrega_cep;
                        }else{
                            taxaEntrega = (responseData.taxaentrega_bairro > 0) ? responseData.taxaentrega_bairro : responseData.taxaentrega_cep;
                        }

                        taxaEntrega = (taxaEntrega) ? taxaEntrega : 0;

                        if (typeof(taxaEntrega) === 'string'){
                            taxaEntrega    = parseFloat(taxaEntrega);
                        }

                        this.setState({
                            taxaEntrega : taxaEntrega,
                            cli_enderecos : arrTmp,
                            alertaSelecionaEndereco: false, editaEnderecoNoLogin: false,
                        });
                        
                    }else{
                        BS_ALE(this,  'Atenção', responseData.msg , [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                    }
                })
                .catch((error) =>{
                    if(axios.isCancel(error)){
        
                        BS_ALE(this,  'Atenção', error.message, [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                    else{
                
                        BS_ALE(this,  'Atenção', error.message, [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                });            
        } catch (error) {
            BS_ALE(this,  'Atenção', error.message, [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);            
        }
      
    }

    cadastraEndereco = () => {

        const {cad_cep, cad_estado, cad_cidade, cad_bairro, cad_rua, cad_numero, cad_referencia, cad_complemento, cad_identificacao,} = this.state;

        try {
            // here place your signup logic     

            const arrBairro = this.getBairroByid(this.state.listaBairros, this.state.cad_bairro);

            const bairro    = (arrBairro) ? arrBairro.nome   : this.state.cad_bairro;
            const codbairro = (arrBairro) ? arrBairro.codigo : '';
            
            if (this.verificaCamposEndereco(cad_cep, cad_estado, cad_cidade, bairro, cad_rua, cad_numero, cad_referencia)){
                
                this.usaEndereco(cad_identificacao, cad_cep, cad_estado, cad_cidade, codbairro, bairro, cad_rua, cad_numero, cad_complemento, cad_referencia);
                
            }else{
                
            }

        } catch (err) {
            
        }
    }

    pesquisaCEP(CEP) {

        try {

            if (this.state.cad_cep.trim().length == 9){
              var CancelToken = axios.CancelToken;
          
              this.setState({
                estadoCEP: 0,
              });  
      
              let source = CancelToken.source();
              setTimeout(() => {
                source.cancel();
              }, global.timeOutTimer);
      
              var parametros = 
              //'parres='    + global.cod_restaurante   +
              'parres='     + global.cod_restaurante   +
              "&parverapp=" + global.versaoApp +
              '&parcep='    + this.state.cad_cep.replace('-', '');
              
              axios.post(
                global.siteurl + 'verifica_cep.php', 
                parametros,
                {
                   cancelToken: source.token,
                   headers: {
                       'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                   },
                   withCredentials: true,
                }).then((response) => {
                  var responseData = response.data;
                  if (responseData.resposta == '1') {
                      axios.post(
                        global.siteurl + 'pesquisa_cep.php', 
                        parametros,
                          {
                             cancelToken: source.token,
                             headers: {
                                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                             },
                             withCredentials: true,
                          }).then((response) => {
      
                            this.setState({
                              'cad_estado'      : '',
                              'cad_cidade'      : '',
                              'cad_bairro'      : '',
                              'cad_rua'         : '',
                              'cad_complemento' : '',
                              'cad_numero'      : '',
                              'cad_referencia'  : '',
                              'cad_identificacao' : '',
                            });
      
                            if (response.data.resposta == "1"){
                              //responseData = response.data.registros;
      
                              responseData = response.data.registros;
                              
                              if (responseData.uf != null) {
                                this.setState({
                                    'cad_estado' : responseData.uf,    
                                    'cad_cidade' : responseData.localidade,
                                });
        
                                if ((responseData.bairro != null) && (responseData.bairro.length > 0)) {
            
                                    this.setState({
                                        estadoCEP     : 1,
                                        'cad_bairro'      : responseData.bairro,    
                                        'cad_rua'         : responseData.logradouro,
                                    });

                                }else{
                                  axios.post(
                                    global.siteurl + 'lista_bairros.php', 
                                    'parresid=' + global.cod_restaurante +
                                    "&parverapp=" + global.versaoApp +
                                    '&parclicep=' + this.state.cad_cep.replace('-', ''),
                                    {
                                       cancelToken: source.token,
                                       headers: {
                                           'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                                       }
                                    }).then((response) => {
                                      responseData = response.data;
                                      
                                      if (responseData.resposta == '1') {

                                        if (responseData.apidist == '1'){
                                            this.setState({
                                                estadoCEP     : 1
                                            });
                                        }else{
                                            this.setState({
                                                estadoCEP: 2,
                                                listaBairros: response.data.registros,
                                            });
                                        }

                                      }else{                          
                                        BS_ALE(this,  'Atenção', 'Por enquanto ainda não atendemos o endereço para o CEP informado.', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                                      }
                                  })
                                  .catch((error) =>{
                                                                       
                                    if(axios.isCancel(error)){
                          
                                      BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 025', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                                  
                                    }
                                    else{
                                  
                                      BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 026', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                                  
                                    }
        
                                    
                                  });
                                }
        
                                
                              }else{
                                BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 027', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                              }
                            }else{
                              BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 028', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                            }
                            
                        })
                        .catch((error) =>{
                          if(axios.isCancel(error)){
                
                            BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 029', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                        
                          }
                          else{
                        
                            BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 030', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                        
                          }
                        });
                  }else{
                    BS_ALE(this,  'Atenção', 'Por enquanto ainda não atendemos o endereço para o CEP informado.', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                  }
              })
              .catch((error) =>{
                if(axios.isCancel(error)){
      
                  BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 033', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
              
                }
                else{
              
                  BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 034', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
              
                }
              });
            }else{
              BS_ALE(this, 'Atenção', 'Informe um CEP válido e clique no botão VERIFICAR')
            }
        } catch (err) {
            
        }                      

    }

    renderFormEndereco = () => {

        const { classes } = this.props;
        var estadoCEP = this.state.estadoCEP;

        if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){
            return (
                <div>
                    <div className={classes.addEndForm}>
                        <div className={classes.paper}>
                            <form className={classes.form} style={{marginTop: '25px'}} noValidate>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="cad_cep"
                                        label="CEP"
                                        type="text"
                                        id="cad_cep"
                                        autoComplete="text"
                                        className={classes.enderecoInputs}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' ) {
                                                event.preventDefault();
                                                this.pesquisaCEP(this.state.cad_cep);
                                            }
                                        }}
                                        InputProps={{
                                            inputComponent: CEPMaskInput,
                                            value: this.state.cad_cep,
                                            onChange: this.handleChange,
                                        }}
                                    />
    
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={() => {
                                            this.pesquisaCEP(this.state.cad_cep);
                                        }}
                                        style={{
                                            backgroundColor: '#3496eb',
                                            height: 'fit-content',
                                            marginTop: 'auto',
                                            marginBottom: 'auto',
                                            marginLeft: '20px',
                                            width: 'fit-content',
                                        }}
                                    >
                                        Verificar
                                    </Button>
                                </div>
    
                                {this.renderFormEnderecoFields()}
    
                                
                            </form>
                        </div>
                    </div>
                </div>
            );
        }

    }

    renderCabecDialogEndereco = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '17px'}}>
                <div style={{position: 'relative', display: 'flex', top: 0, left: 0, width: '100%'}}>
                    <div onClick={() => {this.setState({ alertaSelecionaEndereco: false, editaEnderecoNoLogin: false, })}} style={{zIndex: '2', display: 'flex', position: 'relative', top: 0, left: 0, width: '30px'}}>
                        <ArrowBack onClick={() => {this.setState({ alertaSelecionaEndereco: false, editaEnderecoNoLogin: false, })}} style={{color: '#999'}} />
                    </div>
                    <text style={{zIndex: '1', position: 'absolute', textAlign: 'center', width: '100%', fontSize: '17px', fontWeight: '500'}}>Pedido para:</text>
                </div>
            </div>
        );
    }

    renderEditarEndereco = () => {
        if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1') && (this.state.edit_tipoEntrega != "retirada")){
            return (
                <div onClick={() => {this.editaEnderecoNoLogin()}} style={{color: '#5095C7', fontSize: '16px', fontWeight: '500', textDecoration: 'underline', textAlign: 'center', marginTop: '10px' }}>
                    Editar Endereço
                </div>
            );
        }
    }

    renderDialogEndereco = () => {

        const { classes } = this.props;

        var entregaDelivery = 0;
        var entregaRetirada = 0;

        var msgRetiradaBlq  = "";
        var msgDeliveryBlq  = "";

        var temRetirada     = false;
        var temEntrega      = false;

        if (this.state.cli_enderecos) {
            for (var i = 0; i < this.state.cli_enderecos.length; i++){
                var item = this.state.cli_enderecos[i];
                if (item.identificacao == "RETIRADA"){
                    entregaRetirada   = item.prevTempo;

                    if (item.prevTempoDel){
                        entregaDelivery   = item.prevTempoDel;
                    }

                    temRetirada       = true;
                }else if (item.identificacao == "RETBLQ") {
                    msgRetiradaBlq    = item.rua;
                }else if (item.identificacao == "DELBLQ") {
                    msgDeliveryBlq    = item.rua;
                }else{
                    entregaDelivery   = item.prevTempo;

                    if (item.prevTempoRet){
                        entregaRetirada   = item.prevTempoRet;
                    }

                    temEntrega        = (!this.state.editaEnderecoNoLogin);
                }
            }
        }

        if (msgDeliveryBlq != ''){
            entregaDelivery = 0;
        }
        
        if ((this.state.alertaSelecionaEndereco)){
            
            if ( (msgDeliveryBlq != '') && (this.state.edit_tipoEntrega != "retirada")){ 
                return(
                    <Dialog
                        open={this.state.alertaSelecionaEndereco}
                        onClose={this.handleCloseAlerta}
                        disableBackdropClick 
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        classes={{ container: classes.root, paper: classes.paper }}
                        style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                    >
                        <DialogContent>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '30px'}}>
                                {this.renderCabecDialogEndereco()}
                                <RadioGroup style={{display: 'flex', flexDirection: 'row', marginBottom: '8px'}} value={this.state.edit_tipoEntrega} onChange={this.handleRadioEntregaChange}>
                                    <FormControlLabel style={{width: '40%'}} value="retirada" control={<Radio />} label={"Retirada" + ((entregaRetirada > 0) ? (" (+-" + entregaRetirada + "min)") : "")} />
                                    <FormControlLabel style={{width: '40%', margin: 'auto 0px auto auto'}} value="entrega"  control={<Radio />} label={"Entrega" + ((entregaDelivery > 0) ? (" (+-" + entregaDelivery + "min)") : "")} />
                                </RadioGroup>
                                <text style={{margin: 'auto auto 10px auto', textAlign: 'justify', color: '#980900', fontSize: '15px', fontWeight: '450'}}>{msgDeliveryBlq}</text>
                            </div>
                        </DialogContent>
                    </Dialog>
                );
            } else if ( (msgRetiradaBlq != '') && (this.state.edit_tipoEntrega == "retirada")){
                return(
                    <Dialog
                        open={this.state.alertaSelecionaEndereco}
                        onClose={this.handleCloseAlerta}
                        disableBackdropClick 
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        classes={{ container: classes.root, paper: classes.paper }}
                        style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                    >
                        <DialogContent>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '30px'}}>
                                {this.renderCabecDialogEndereco()}
                                <RadioGroup style={{display: 'flex', flexDirection: 'row', marginBottom: '8px'}} value={this.state.edit_tipoEntrega} onChange={this.handleRadioEntregaChange}>
                                    <FormControlLabel style={{width: '40%'}} value="retirada" control={<Radio />} label={"Retirada" + ((entregaRetirada > 0) ? (" (+-" + entregaRetirada + "min)") : "")} />
                                    <FormControlLabel style={{width: '40%', margin: 'auto 0px auto auto'}} value="entrega"  control={<Radio />} label={"Entrega" + ((entregaDelivery > 0) ? (" (+-" + entregaDelivery + "min)") : "")} />
                                </RadioGroup>
                                <text style={{margin: 'auto auto 10px auto', textAlign: 'justify', color: '#980900', fontSize: '15px', fontWeight: '450'}}>{msgRetiradaBlq}</text>
                            </div>
                        </DialogContent>
                    </Dialog>
                );
            }

            if ( (msgRetiradaBlq == '') && (this.state.edit_tipoEntrega == "retirada") && (!temRetirada) ){
                return(
                    <Dialog
                        open={this.state.alertaSelecionaEndereco}
                        onClose={this.handleCloseAlerta}
                        disableBackdropClick 
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        classes={{ container: classes.root, paper: classes.paper }}
                        style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                    >
                        <DialogContent>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '30px'}}>
                                {this.renderCabecDialogEndereco()}
                                <RadioGroup style={{display: 'flex', flexDirection: 'row', marginBottom: '8px'}} value={this.state.edit_tipoEntrega} onChange={this.handleRadioEntregaChange}>
                                    <FormControlLabel style={{width: '40%'}} value="retirada" control={<Radio />} label={"Retirada" + ((entregaRetirada > 0) ? (" (+-" + entregaRetirada + "min)") : "")} />
                                    <FormControlLabel style={{width: '40%', margin: 'auto 0px auto auto'}} value="entrega"  control={<Radio />} label={"Entrega" + ((entregaDelivery > 0) ? (" (+-" + entregaDelivery + "min)") : "")} />
                                </RadioGroup>
                                <text style={{margin: 'auto auto 10px auto', textAlign: 'justify', color: '#980900', fontSize: '15px', fontWeight: '450'}}>{"O serviço de RETIRADA está indisponível"}</text>
                            </div>
                        </DialogContent>
                    </Dialog>
                );
            }

            if ( (msgRetiradaBlq == '') && (this.state.edit_tipoEntrega != "retirada") && (!temEntrega) ){

                if (global.pedidoNoCad == '1'){
                    return (
                        <Dialog
                            open={this.state.alertaSelecionaEndereco}
                            onClose={this.handleCloseAlerta}
                            disableBackdropClick 
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            classes={{ container: classes.root, paper: classes.paper }}
                            style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                        >
                            <DialogContent>
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '30px'}}>
                                    {this.renderCabecDialogEndereco()}
                                    <RadioGroup style={{display: 'flex', flexDirection: 'row', marginBottom: '8px'}} value={this.state.edit_tipoEntrega} onChange={this.handleRadioEntregaChange}>
                                        <FormControlLabel style={{width: '40%'}} value="retirada" control={<Radio />} label={"Retirada" + ((entregaRetirada > 0) ? (" (+-" + entregaRetirada + "min)") : "")} />
                                        <FormControlLabel style={{width: '40%', margin: 'auto 0px auto auto'}} value="entrega"  control={<Radio />} label={"Entrega" + ((entregaDelivery > 0) ? (" (+-" + entregaDelivery + "min)") : "")} />
                                    </RadioGroup>
                                    {this.renderFormEndereco()}
                                    {this.renderBtnAddEnd()}
                                </div>
                            </DialogContent>
                        </Dialog>
                    )
                }

                return(
                    <Dialog
                        open={this.state.alertaSelecionaEndereco}
                        onClose={this.handleCloseAlerta}
                        disableBackdropClick 
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        classes={{ container: classes.root, paper: classes.paper }}
                        style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                    >
                        <DialogContent>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '30px'}}>
                                {this.renderCabecDialogEndereco()}
                                <RadioGroup style={{display: 'flex', flexDirection: 'row', marginBottom: '8px'}} value={this.state.edit_tipoEntrega} onChange={this.handleRadioEntregaChange}>
                                    <FormControlLabel style={{width: '40%'}} value="retirada" control={<Radio />} label={"Retirada" + ((entregaRetirada > 0) ? (" (+-" + entregaRetirada + "min)") : "")} />
                                    <FormControlLabel style={{width: '40%', margin: 'auto 0px auto auto'}} value="entrega"  control={<Radio />} label={"Entrega"} />
                                </RadioGroup>
                                <text style={{margin: 'auto auto 10px auto', textAlign: 'justify', color: '#980900', fontSize: '15px', fontWeight: '450'}}>{"Você precisa registrar ao menos um endereço para usar o serviço Delivery"}</text>
                                {this.renderBtnAddEnd()}
                                {this.renderEditarEndereco()}
                            </div>
                        </DialogContent>
                    </Dialog>
                );
            }

            return(
                <Dialog
                    open={this.state.alertaSelecionaEndereco}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                >
                    <DialogContent>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '30px'}}>
                            {this.renderCabecDialogEndereco()}
                            <RadioGroup style={{display: 'flex', flexDirection: 'row', marginBottom: '8px'}} value={this.state.edit_tipoEntrega} onChange={this.handleRadioEntregaChange}>
                                <FormControlLabel style={{width: '40%'}} value="retirada" control={<Radio />} label={"Retirada" + ((entregaRetirada > 0) ? (" (+-" + entregaRetirada + "min)") : "")} />
                                <FormControlLabel style={{width: '40%', margin: 'auto 0px auto auto'}} value="entrega"  control={<Radio />} label={"Entrega" + ((entregaDelivery > 0) ? (" (+-" + entregaDelivery + "min)") : "")} />
                            </RadioGroup>
                            {this.renderMsgTipoEntrega()}
                            {this.renderEnderecos()}
                            {this.renderBtnAddEnd()}
                            {this.renderEditarEndereco()}
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    carregaFormasPag = () => {

        try {
            // here place your login logic     
                    
            var CancelToken = axios.CancelToken;

            const {log_senha, log_usu} = this.state;
        
            var parametros = 
            "&pargrures=" + global.cod_grurest +
            "&parres=" + global.cod_restaurante +
            "&parverapp=" + '99';

            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);
    
            
            axios.post(
                global.siteurl + 'pagamento_search.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == "2"){
                }else if (responseData.resposta == "0") {
                    global.myToken = "";
                    this.setState({
                        myToken : "",
                        loading: false,
                    });
                }else{
                    this.setState({
                        listaPags: responseData.registros,
                        alertaSelecionaPagamento: true,
                    });
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });

        } catch (err) {
            
        }  
    }

    setTrocoVal = () => {

        var pagamentoFinal = this.state.tmpFormaPagTroco;

        var total_ped    = this.returnTotPed();

        var str_troco    = this.state.valorTroco.replace('.', '').replace(',', '.');

        if (parseFloat(str_troco) >= parseFloat(total_ped)){

            pagamentoFinal.valTroco = (this.state.valorTroco.replace('.', '').replace(',', '.') == total_ped) ? undefined : this.state.valorTroco.replace('.', '');

            this.setState({
                alertaDefineTroco: false,
                formaPagamentoAtual: pagamentoFinal,
            });
        }else{
            BS_ALE(this, 'Atenção', 'O valor para troco precisa ser maior ou igual ao valor total do pedido');
        }
        
    }

    renderDialogTroco = () => {

        const { classes } = this.props;
        
        if ((this.state.alertaDefineTroco)){

            return(
                <Dialog
                    open={this.state.alertaDefineTroco}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                >
                    <DialogContent>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                            <div style={{display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px', }}>
                                {this.renderResumoPed()}
                            </div>
                            <div style={{width: '100%', }}><text style={{fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex'}}><PaymentIcon style={{marginRight: '6px'}} />Troco para:</text></div>
                            <div className={classes.separator} style={{marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa'}} ></div>
                            <div className={"MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"}>
                                <div tabIndex={1} autoFocus={true}></div>
                                <CurrencyInput value={this.state.valorTroco} onChange={(value) => {
                                    this.setState({
                                        valorTroco: value
                                    });
                                }} />
                            </div>
                            <div ref={(ref) => this.btnAddTroco = ref} style={{display: 'flex', flexDirection: 'row', margin: '12px 0 0px 0', width: '100%', paddingBottom: '12px'}}>
                                <Button onClick={() => {
                                        this.setState({
                                            alertaDefineTroco: false,
                                            tmpFormaPagTroco: []
                                        })}
                                    } color="primary" style={{backgroundColor: '#ddd', width: '50%', margin: '0 8px 0 0px', color: '#000'}}>
                                    Voltar
                                </Button>
                                <Button onClick={() => this.setTrocoVal()} color="primary" style={{backgroundColor: '#03a1fc', width: '50%', margin: '0 0 0px 8px', color: '#fff'}}>
                                    Confirmar
                                </Button>
                            </div>                       
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    returnTotPed = (newTaxaEntrega) => {

        var taxaEntrega = 0;
        if (typeof newTaxaEntrega !== 'undefined'){
            taxaEntrega = newTaxaEntrega;
        }else{
            taxaEntrega = this.state.taxaEntrega;
        }

        var valor_compra = 0;

        this.state.carrinhoCompra.map((item, index) => {
            var itemSelecionado = (item.checkedFidelidade >= 1);

            var arrResult = {
                "pontosAdicBonus" : 0
            }

            var preco_prod_sem_adic = this.getPrecoProdSemAdic(item, arrResult);

            var valorProgFid = (global.programaFidelidadeAdicional == '1') ? 0 : (item.valorUnit * item.qtde) - preco_prod_sem_adic;

            valor_compra += (itemSelecionado) ? valorProgFid : parseFloat(formatarNumero((item.valorUnit * item.qtde), 2).replace(',', '.') );
        });

        var pagDesconto = 0;
        var descontoNoPagamento = 0;
        var descontoNoCupom     = 0;

        try {
            if (this.state.cupomSelected.idCupom){

                if (this.state.cupomSelected.tipoDesc == 0){
                    descontoNoCupom     = Math.round(((valor_compra - descontoNoPagamento) * ((this.state.cupomSelected.desconto) / 100)) * 100) / 100;
                }else if (this.state.cupomSelected.tipoDesc == 1){
                    descontoNoCupom     = this.state.cupomSelected.desconto;
                }else{
                    descontoNoCupom     = taxaEntrega;
                }

            }   

            try {
                descontoNoCupom = parseFloat(descontoNoCupom);
            } catch (error) {
                
            }

        } catch (error) {
            descontoNoCupom = 0;
        }

        try {
            pagDesconto = parseFloat(this.state.formaPagamentoAtual.desconto) + parseFloat(global.descontoApp);
        } catch (error) {
            try {
                pagDesconto = parseFloat(this.state.tmpFormaPagTroco.desconto) + parseFloat(global.descontoApp);
            } catch (error) {
                pagDesconto = 0;
            }
        }

        if ( isNaN(pagDesconto) && (pagDesconto != undefined) && (pagDesconto != null) ){
            pagDesconto = 0;
        }

        //var prod_val    = valor_compra.toFixed(2);
        var prod_val    = formatarNumero(valor_compra, 2).replace(',', '.');
        prod_val = ((prod_val.trim() == "0.00") || (prod_val.trim() == "0,00")) ? "0,00" : prod_val.replace('.', ',');
        prod_val = 'R$ ' + prod_val;
        
        var taxa_ent    = taxaEntrega;

        if (typeof(taxa_ent) === 'number'){
            //taxa_ent    = taxa_ent.toFixed(2);
            taxa_ent    = formatarNumero(taxa_ent, 2).replace(',', '.')
        }

        taxa_ent = ((taxa_ent.trim() == "0.00") || (taxa_ent.trim() == "0,00")) ? "0,00" : taxa_ent.replace('.', ',');
        taxa_ent = 'R$ ' + taxa_ent;

        var tmpDescCupom = (this.state.cupomSelected.tipoDesc == '2') ? 0 : descontoNoCupom;
        descontoNoPagamento = Math.round(((valor_compra - tmpDescCupom) * ((pagDesconto) / 100)) * 100) / 100;

        var desconto    = (descontoNoPagamento + descontoNoCupom);
        var descontoProds       = 0;
        var descontoTaxaEntrega = 0;

        if (this.state.cupomSelected.tipoDesc == 2){
            descontoProds       = (descontoNoPagamento);
            descontoTaxaEntrega = descontoNoCupom;
        }else{
            descontoProds       = (descontoNoPagamento + descontoNoCupom);
        }

        //var total_ped    = (valor_compra + taxaEntrega - desconto).toFixed(2);
        var total_ped    = formatarNumero((valor_compra + taxaEntrega - desconto), 2).replace(',', '.');

        return total_ped;

    }

    renderDialogProgramaFidelidade = () => {
        //alertaSelecionaCupom

        const { classes } = this.props;

        var resultado = {
            pontosUsados : 0
        }
        
        var produtosLista = this.renderListaProdFidelidade(this.state.carrinho_compra_copia, resultado);

        var pontosFinais = this.state.cli_pontos - resultado.pontosUsados;
        
        if ((this.state.alertaProgramaFidelidade)){

            return(
                <Dialog
                    open={this.state.alertaProgramaFidelidade}
                    onClose={() => {this.setState({alertaProgramaFidelidade: false})}}
                    disableBackdropClick 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                >
                    <DialogContent>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                            <div style={{width: '100%', }}><text style={{fontWeight: '415', color: '#aaa', fontSize: '18px', display: 'flex'}}><CupomIcon style={{marginRight: '6px'}} />Selecione como deseja resgatar seus pontos</text></div>
                            <div className={classes.separator} style={{marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa'}} ></div>
                            <div style={{marginBottom: (pontosFinais < 0) ? '10px' : '', color: '#980000', fontSize: '12px'}}>{(pontosFinais < 0) ? '* Você não possui pontos suficientes para resgatar estes produtos.' : ''}</div>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '14px' }}>
                                <div><b>Saldo Inicial:</b> {this.state.cli_pontos} Ponto(s)</div>
                                <div style={{ flex: 1 }}></div>
                                <div style={{color: (pontosFinais < 0) ? '#980000' : ''}}><b>Saldo Final:</b> {pontosFinais} Ponto(s)</div>
                            </div>
                            <div>
                                {produtosLista}
                            </div>
                            <div ref={(ref) => this.btnAddTroco = ref} style={{display: 'flex', flexDirection: 'row', margin: '16px 0 0px 0', width: '100%', paddingBottom: '12px'}}>
                                <Button onClick={() => {
                                    if (pontosFinais >= 0){

                                        var item = {
                                            online   : false,
                                            nome     : "Pedido Zerado",
                                            valTroco : 0
                                        }

                                        this.setState({
                                            carrinhoCompra: JSON.parse(JSON.stringify(this.state.carrinho_compra_copia))
                                        }, () => {
                                            var total_ped    = this.returnTotPed();

                                            if (total_ped <= 0){
                                                this.setState({
                                                    formaPagamentoAtual: item,
                                                    valPedidoZerado: true,
                                                    alertaProgramaFidelidade: false,
                                                });
                                            }else{
                                                this.setState({
                                                    formaPagamentoAtual: (this.state.formaPagamentoAtual?.nome == item.nome) ? null : this.state.formaPagamentoAtual,
                                                    valPedidoZerado: false,
                                                    alertaProgramaFidelidade: false,
                                                });
                                            }
                                        })

                                    }else{
                                        BS_ALE(this,  'Atenção', "Você não possui pontos suficiente para trocar este(s) produto(s).", [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                                    }                                    
                                }} color="primary" style={{backgroundColor: '#047fd1', color: '#fff', width: '100%', margin: '0 8px 0 0px',}}>
                                    Confirmar
                                </Button>
                            </div>                       
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    renderDialogSelectCupom = () => {
        //alertaSelecionaCupom

        const { classes } = this.props;
        
        if ((this.state.alertaSelecionaCupom)){

            return(
                <Dialog
                    open={this.state.alertaSelecionaCupom}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                >
                    <DialogContent>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                            <div style={{width: '100%', }}><text style={{fontWeight: '415', color: '#aaa', fontSize: '18px', display: 'flex'}}><CupomIcon style={{marginRight: '6px'}} />Selecione seu cupom:</text></div>
                            <div className={classes.separator} style={{marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa'}} ></div>
                            <div style={{fontSize: '14px', textAlign: 'justify', color: '#980000'}}>* Somente um cupom pode ser adicionado por pedido.</div>
                            {(this.state.cli_cupons_inv == 1) ? 
                                <div style={{fontSize: '14px', textAlign: 'justify', color: '#980000'}}>Você possui cupons disponíveis que não podem ser utilizados nesse pedido. Consulte as regras para saber mais.</div> : 
                                <div></div>}
                            <div style={{display: 'flex', flexDirection: 'column',}}>
                                {this.renderCupons()}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{flex: 30}} className={"MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"}>
                                    <div tabIndex={1} autoFocus={true}></div>
                                    <TextField
                                        variant="outlined"
                                        autoCapitalize="characters"
                                        required
                                        fullWidth
                                        name="edit_cupomCod"
                                        placeholder="Código do Cupom"
                                        type="text"
                                        id="edit_cupomCod"
                                        inputProps={{
                                            maxLength: 20
                                        }}
                                        autoComplete="edit_cupomCod"
                                        value={this.state.edit_cupomCod}
                                        style={{textTransform: 'uppercase'}}
                                        onChange={this.handleChangeCapitalize}
                                    />
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{margin: 'auto', marginRight: '6px', height: 'fit-content', width: 'fit-content'}}>
                                        <a
                                            onClick={() => {
                                                this.pesquisaCupom();
                                            }}
                                            style={{color: '#3496eb', fontSize: '15px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%', marginRight: '10px', marginLeft: '10px'}}
                                        >
                                            Adicionar
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                            <div ref={(ref) => this.btnAddTroco = ref} style={{display: 'flex', flexDirection: 'row', margin: '16px 0 0px 0', width: '100%', paddingBottom: '12px'}}>
                                <Button onClick={() => this.setState({alertaSelecionaCupom: false})} color="primary" style={{backgroundColor: '#ddd', width: '100%', margin: '0 8px 0 0px', color: '#000'}}>
                                    Voltar
                                </Button>
                            </div>                       
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    handleRadioChange = (event) => {
        this.setState({
            edit_tipoDocNota : event.target.value,
            cli_cpf_edit : "",
        });
    };

    handleRadioEntregaChange = (event) => {
        this.setState({
            edit_tipoEntrega : event.target.value,
        });
    };

    handleChange = (event) => {

        if (event.target.name == "cad_cep"){
            this.setState({
                [event.target.name]: event.target.value,
                estadoCEP: "0",
            });
        }else{
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };

    handleChangeCapitalize = (event) => {
        this.setState({
            [event.target.name]:  event.target.value.toUpperCase(),
        });
    };

    setDocNota() {
        if ( validaCpfCnpj(this.state.cli_cpf_edit) ) {
            this.setState({
                tipoDocNota : this.state.edit_tipoDocNota,
                docDocNota  : this.state.cli_cpf_edit,
                alertaDocNota : false,
            }, () => {
                if (this.state.finalizandoPedido){
                    this.geraPedido();
                }
            });
        }else{

            if (this.state.edit_tipoDocNota == "nenhum"){
                this.setState({
                    tipoDocNota : this.state.edit_tipoDocNota,
                    docDocNota  : "",
                    alertaDocNota : false,
                }, () => {
                    if (this.state.finalizandoPedido){
                        this.geraPedido();
                    }
                });
            }else{
                var msg = "Você precisa inserir um CNPJ válido";

                if (this.state.edit_tipoDocNota == "cpf"){
                    msg = "Você precisa inserir um CPF válido";
                }
    
                BS_ALE(this,  'Atenção', msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);
            }
        }
        
    }

    renderDialogDocNota = () => {

        const { classes } = this.props;
        
        if ((this.state.alertaDocNota)){

            var objMaskInput = NenhumMaskInput;
            var docNotaLabel = "Nenhum";

            if (this.state.edit_tipoDocNota == "cpf"){
                objMaskInput = CPFMaskInput;
                docNotaLabel = "CPF";
            }else if (this.state.edit_tipoDocNota == "cnpj"){
                objMaskInput = CNPJMaskInput;
                docNotaLabel = "CNPJ";
            }

            return(
                <Dialog
                    open={this.state.alertaDocNota}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                >
                    <DialogContent>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '30px'}}>
                            <div style={{width: '100%', }}><text style={{fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex'}}><AssignmentInd style={{marginRight: '6px'}} />CPF/CNPJ na nota</text></div>
                            <div className={classes.separator} style={{marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa'}} ></div>
                            <div>
                                <RadioGroup style={{display: 'flex', flexDirection: 'row'}} value={this.state.edit_tipoDocNota} onChange={this.handleRadioChange}>
                                    <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                    <FormControlLabel value="cpf"    control={<Radio />} label="CPF" />
                                    <FormControlLabel value="cnpj"   control={<Radio />} label="CNPJ" />
                                </RadioGroup>
                            </div>
                            <div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="cli_cpf_edit"
                                    label={docNotaLabel}
                                    type="text"
                                    id="cli_cpf_edit"
                                    autoComplete="text"
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' ) {
                                            event.preventDefault();
                                            this.setDocNota();
                                        }
                                    }}
                                    InputProps={{
                                        inputComponent: objMaskInput,
                                        value: (this.state.edit_tipoDocNota == "nenhum") ? "" : this.state.cli_cpf_edit,
                                        onChange: this.handleChange,
                                    }}
                                />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '12px 0 12px 0', width: '100%'}}>
                                <Button onClick={() => this.setState({alertaDocNota: false})} color="primary" style={{backgroundColor: '#ddd', width: '50%', margin: '0 8px 0 0px', color: '#000'}}>
                                    Voltar
                                </Button>
                                <Button onClick={() => this.setDocNota()} color="primary" style={{backgroundColor: '#03a1fc', width: '50%', margin: '0 0 0 8px', color: '#fff'}}>
                                    Confirmar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }
    
    renderPagamentosOnline = () => {

        const { classes } = this.props;

        var permitePagOnl = false;

        if (this.state.cli_enderecos) {

            for (var i = 0; i < this.state.cli_enderecos.length; i++){

                var item = this.state.cli_enderecos[i];

                var enderecoAtivo = (item.enderecoAtivo == '1');
    
                if (enderecoAtivo){
                    if ( (item.identificacao) && (item.identificacao != "") ) {
                        if (item.identificacao == "RETIRADA"){
                            permitePagOnl = (global.restPonBal == '1');
                            break;
                        }else{
                            permitePagOnl = (global.restPonDel == '1');
                            break;
                        }
                    }else{
                        permitePagOnl = (global.restPonDel == '1');
                        break;
                    }
                }

            }
        }

        if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){
            return (<div></div>);            
        }

        if (permitePagOnl){
            return (
                <div>
                    <div style={{margin: "10px 0px", fontSize: '16px', fontWeight: '500', color: '#333'}}>Pagamentos Online</div>
                    <div style={{border: "2px groove rgba(0, 0, 0, 0.05)", borderRadius: "8px", padding: "10px", marginBottom: "10px"}}>
                        <div onClick={() => {
                                this.selecionaPagamentoMP()
                            }} style={{borderRadius: '8px', width: '100%', backgroundColor: '#fff', display: 'flex'}}>
                            <img src={MPIcon} style={{marginRight: '5px', height: '28px'}}/>
                            <text style={{marginRight: '30px', fontSize: '15px', color: '#000', display: 'flex', alignItems: 'center',}} >{'Mercado Pago'}</text>
                        </div> 
                    </div>
                </div>
            );
        }

    }

    renderDialogPagamento = () => {

        const { classes } = this.props;
        
        if ((this.state.alertaSelecionaPagamento)){

            return(
                <Dialog
                    open={this.state.alertaSelecionaPagamento}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                >
                    <DialogContent>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '30px'}}>
                            <div style={{width: '100%', }}><text style={{fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex'}}><PaymentIcon style={{marginRight: '6px'}} />Formas de pagamento</text></div>
                            <div className={classes.separator} style={{marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa'}} ></div>
                            {this.renderPagamentosOnline()}
                            <div style={{margin: "10px 0px", fontSize: '16px', fontWeight: '500', color: '#333'}}>Pagamentos na Entrega</div>
                            {this.renderPagamentos()}
                            <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => {
                                        this.setState({
                                            alertaSelecionaPagamento: false
                                        });
                                    }}
                                    style={{backgroundColor: '#3496eb'}}
                                >
                                Voltar
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    renderDialogParticiparProgFid = () => {

        const { classes } = this.props;
        
        if ((this.state.alertaParticipaProgFide)){

            return(
                <Dialog
                    open={this.state.alertaParticipaProgFide}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                >
                    {this.renderProgFideParticipar()}
                </Dialog>
            );
        }

    }

    renderDialogParticiparCupom = () => {

        const { classes } = this.props;
        
        if ((this.state.alertaParticipaCupom)){

            return(
                <Dialog
                    open={this.state.alertaParticipaCupom}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                >
                    {this.renderCupomDescontoParticipar()}
                </Dialog>
            );
        }
    }

    abreTermoCupom = () => {

        var CancelToken = axios.CancelToken;

        var parametros =    'parhash='  + global.hashRest +
                            "&parresid="  + global.cod_restaurante;
    
        let source = CancelToken.source();
        setTimeout(() => {
          source.cancel();
          
        }, global.timeOutTimer);

        axios.post(
        global.siteurl + 'exibe_termo_cupom.php', 
        parametros,
        {
            cancelToken: source.token,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            },
        }).then((response) => {
    
            this.setState({
                politPrivTitulo: 'Termo de Aceite - Cupom de Desconto',
                politPriv: response.data,
                politPrivOpen: true
            });
        
        });
    }

    abreTermoFidelidade = () => {

        var CancelToken = axios.CancelToken;

        var parametros =    'parhash='  + global.hashRest +
                            "&partoken="  + global.myToken +
                            "&parresid="  + global.cod_restaurante;
    
        let source = CancelToken.source();
        setTimeout(() => {
          source.cancel();
          
        }, global.timeOutTimer);

        axios.post(
        global.siteurl + 'exibe_termo_fidelidade.php', 
        parametros,
        {
            cancelToken: source.token,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            },
            withCredentials: true,
        }).then((response) => {
    
            this.setState({
                politPrivTitulo: 'Termo de Aceite - Programa de Fidelidade',
                politPriv: response.data,
                politPrivOpen: true
            });
        
        });
    }

    handleChangeRegulamentoAceito = (e) => {
        this.setState({
            cupomDescAceito : !(this.state.cupomDescAceito)
        });
    }

    mudaPermCupom = (permCupom) => {

        try {
            // here place your signup logic     
            
            var CancelToken = axios.CancelToken;
      
            var parametros = 
            "partoken="  + global.myToken +
            "&pargrures=" + global.cod_grurest +
            "&parverapp=" + '99' +
            "&parpermcupom=" + permCupom;
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);
    
            
            axios.post(
                global.siteurl + 'cliente_muda_cupperm.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                this.closeProgress();
                if (responseData.resposta == '1') {
                    
                    this.setState({
                        alertaParticipaCupom: false
                    }, () => this.carregaDados());
                    
                }else{
                    BS_ALE(this,  'Atenção', responseData.msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });
            
        } catch (err) {
            BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);            
        }
    }

    mudaPermFidelidade = (permFidelidade) => {

        try {
            // here place your signup logic     
            
            var CancelToken = axios.CancelToken;
      
            var parametros = 
            "partoken="  + global.myToken +
            "&pargrures=" + global.cod_grurest +
            "&parverapp=" + '99' +
            "&parpermfide=" + permFidelidade;
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);
    
            
            axios.post(
                global.siteurl + 'cliente_muda_fideperm.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                this.closeProgress();
                if (responseData.resposta == '1') {
                    
                    this.setState({
                        atualizaCad : false,
                        alertaParticipaProgFide: false,
                    }, () => this.carregaDados());
                    
                }else{
                    BS_ALE(this,  'Atenção', responseData.msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });
            
        } catch (err) {
            BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);            
        }

    }

    renderProgFideParticipar = () => {

        var styleTeste = this.renderStyleShadow(false);
        const { classes } = this.props;

        if (global.programaFidelidade == 1){
            if (this.state.fidelidadeDescState == tp_programa_naoautorizado){
                return (
                    <div style={{backgroundColor: '#950000', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column'}}>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
                            <IconButton
                                color="inherit"
                                style={{padding: '7px', paddingLeft: '0px'}}
                                onClick={() => {
                                    this.setState({
                                        alertaParticipaProgFide : false
                                    });
                                }}
                            >
                                <ArrowBack style={{color: '#fff'}} />
                            </IconButton>
                            <text style={{margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff'}}>Programa de Fidelidade</text>
                        </div>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                            <div style={{width: '100%'}}>
                                <div style={{fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center'}}>{"Você ainda não optou por participar do nosso programa de fidelidade."}</div>
                                <div style={{width: '100%', display: 'flex', marginTop: '12px'}}>
                                    <a ref={(ref) => {this.btnAddEnd = ref;}} onClick={() => this.setState({fidelidadeDescState : 1})} style={{margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#009800', textDecoration: 'underline'}}>Desejo participar do Programa de Fidelidade</a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }else if (this.state.fidelidadeDescState == tp_programa_confautoriza){
                return (
                    <div style={{backgroundColor: '#4287f5', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column'}}>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
                            <IconButton
                            color="inherit"
                            style={{padding: '7px', paddingLeft: '0px'}}
                            onClick={() => {
                                this.setState({
                                    alertaParticipaProgFide : false
                                });
                            }}
                            >
                                <ArrowBack style={{color: '#fff'}} />
                            </IconButton>
                            <text style={{margin: 'auto auto auto 0px', fontSize: '17px', fontWeight: '500', color: '#fff'}}>Programa de Fidelidade</text>
                        </div>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                            <div style={{marginLeft: '6px', marginRight: '10px'}}>
                                <div style={{fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'justify'}}>{"Ao aceitar participar do nosso Programa de Fidelidade você concorda com o termo abaixo."}</div>
                                <div style={{width: '100%', display: 'flex', marginTop: '12px', marginBottom: '14px'}}>
                                    <a ref={(ref) => {this.btnAddEnd = ref;}} onClick={() => {this.abreTermoFidelidade()}} style={{margin: 'auto auto auto 0px', fontSize: '15px', fontWeight: '500', color: '#777', textDecoration: 'underline'}}>Termo de Aceite e Política de Funcionamento</a>
                                </div>
                                <FormControlLabel
                                    control={<AdicionaCheckbox checked={(this.state.cupomDescAceito >= 1) ? true : false} value={"1"} onChange={this.handleChangeRegulamentoAceito} name="checkedG" />}
                                    label={"Aceito os termos acima"} style={{color: corCheckBoxAdiciona, width: '100%',}}
                                />
                                <div style={{width: '90%', margin: '14px auto 0px auto'}}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color={(this.state.cupomDescAceito == 1) ? "primary" : "secondary"}
                                        className={classes.submit}
                                        onClick={() => {
                                            if (this.state.cupomDescAceito == 1){
                                                this.mudaPermFidelidade(1);
                                            }else{
                                                BS_ALE(this,  'Atenção', 'Para continuar e ingressar no Programa de Descontos leia e aceite os termos.', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                                            }                                      
                                        }}
    
                                        style={{backgroundColor: (this.state.cupomDescAceito == 1) ? '#3496eb' : "#ccc", width: '100%'}}
                                    >
                                        Confirmar
                                    </Button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                );
            }else if (this.state.fidelidadeDescState == tp_programa_autorizado){
                return (
                    <div style={{marginBottom: '40px',borderRadius: '8px', backgroundColor: '#43944a', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column'}}>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
                            <text style={{margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff'}}>Programa de Fidelidade</text>
                        </div>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                            <div style={{width: '100%'}}>
                                <div style={{fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center'}}>{"Você está participando do nosso Programa de Fidelidade."}</div>
                                {this.renderSaldoPontos()}
                                <div style={{width: '100%', display: 'flex', marginTop: '6px'}}>
                                    <a ref={(ref) => {this.btnAddEnd = ref;}} onClick={() => this.confSairProgramaFide()} style={{margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#980000', textDecoration: 'underline'}}>Desejo sair do Programa de Fidelidade</a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    renderCupomDescontoParticipar = () => {

        var styleTeste = this.renderStyleShadow(false);
        const { classes } = this.props;

        if (global.cliPerCupom == 0){
            if (this.state.cupomDescState == tp_cupom_naoautorizado){
                return (
                    <div style={{backgroundColor: '#950000', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column'}}>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
                            <IconButton
                                color="inherit"
                                style={{padding: '7px', paddingLeft: '0px'}}
                                onClick={() => {
                                    this.setState({
                                        alertaParticipaCupom : false
                                    });
                                }}
                            >
                                <ArrowBack style={{color: '#fff'}} />
                            </IconButton>
                            <text style={{margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff'}}>Cupons de Desconto</text>
                        </div>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                            <div style={{width: '100%'}}>
                                <div style={{fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center'}}>{"Você ainda não optou por participar do nosso programa de descontos."}</div>
                                <div style={{width: '100%', display: 'flex', marginTop: '12px'}}>
                                    <a ref={(ref) => {this.btnAddEnd = ref;}} onClick={() => this.setState({cupomDescState : 1, cupomDescAceito: 0})} style={{margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#009800', textDecoration: 'underline'}}>Desejo participar do Programa de Descontos</a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }else if (this.state.cupomDescState == tp_cupom_confautoriza){
                return (
                    <div style={{backgroundColor: '#4287f5', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column'}}>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
                            <IconButton
                            color="inherit"
                            style={{padding: '7px', paddingLeft: '0px'}}
                            onClick={() => {
                                this.setState({
                                    alertaParticipaCupom : false
                                });
                            }}
                            >
                                <ArrowBack style={{color: '#fff'}} />
                            </IconButton>
                            <text style={{margin: 'auto auto auto 0px', fontSize: '17px', fontWeight: '500', color: '#fff'}}>Cupons de Desconto</text>
                        </div>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                            <div style={{marginLeft: '6px', marginRight: '10px'}}>
                                <div style={{fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'justify'}}>{"Ao aceitar participar do nosso Programa de Descontos você concorda com o termo abaixo e aceita receber mensagens de texto(SMS) com cupons e promoções disponíveis."}</div>
                                <div style={{width: '100%', display: 'flex', marginTop: '12px', marginBottom: '14px'}}>
                                    <a ref={(ref) => {this.btnAddEnd = ref;}} onClick={() => {this.abreTermoCupom()}} style={{margin: 'auto auto auto 0px', fontSize: '15px', fontWeight: '500', color: '#777', textDecoration: 'underline'}}>Termo de Aceite e Política de Funcionamento</a>
                                </div>
                                <FormControlLabel
                                    control={<AdicionaCheckbox checked={(this.state.cupomDescAceito >= 1) ? true : false} value={"1"} onChange={this.handleChangeRegulamentoAceito} name="checkedG" />}
                                    label={"Aceito os termos acima"} style={{color: corCheckBoxAdiciona, width: '100%',}}
                                />
                                <div style={{width: '90%', margin: '14px auto 0px auto'}}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color={(this.state.cupomDescAceito == 1) ? "primary" : "secondary"}
                                        className={classes.submit}
                                        onClick={() => {
                                            if (this.state.cupomDescAceito == 1){
                                                this.mudaPermCupom(1);
                                            }else{
                                                BS_ALE(this,  'Atenção', 'Para continuar e ingressar no Programa de Descontos leia e aceite os termos.', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                                            }                                      
                                        }}
    
                                        style={{backgroundColor: (this.state.cupomDescAceito == 1) ? '#3496eb' : "#ccc", width: '100%'}}
                                    >
                                        Confirmar
                                    </Button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                );
            }else if (this.state.cupomDescState == tp_cupom_autorizado){
                return (
                    <div style={{marginBottom: '40px',borderRadius: '8px', backgroundColor: '#43944a', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column'}}>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
                            <text style={{margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff'}}>Cupons de Desconto</text>
                        </div>
                        <div style={Object.assign({}, {width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                            <div style={{width: '100%'}}>
                                <div style={{fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center'}}>{"Você está participando do nosso Programa de Descontos."}</div>
                                <div style={{width: '100%', display: 'flex', marginTop: '12px'}}>
                                    <a ref={(ref) => {this.btnAddEnd = ref;}} onClick={() => this.confSairPrograma()} style={{margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#980000', textDecoration: 'underline'}}>Desejo sair do Programa de Descontos</a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        

    }

    renderPoliticaPrivacidade = () => {
        
        const {classes} = this.props;

        const appBarClasses = classNames(classes);

        return(
            <Dialog
                open={this.state.politPrivOpen}
                onClose={() => { this.setState({politPrivOpen : false}) }}
                disableBackdropClick 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ container: classes.root, paper: classes.paperFullSize }}
                style={{maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch'}}
            >
                <AppBar className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px', position: 'absolute'}}>
                  <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                    <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{"Informações"}</text>
                    <div onClick={() => { this.setState({politPrivOpen : false}) }} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                      <div style={{display: 'flex', width: '100%', height: '100%'}}>
                        <ArrowBack style={{ margin: 'auto', }}/>
                      </div>
                    </div>
                  </div>                
                </AppBar>
                <DialogContent style={{width: '100%', height: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px'}}>
                        <div>
                          <div style={{width: '100%', display: 'flex'}}>
                            <text style={{paddingBottom: '9px', paddingTop: '18px', margin: 'auto', marginTop: '12px', marginBottom: '15px', fontSize: '20px', fontWeight: '410', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: '#0380fc' }}>{this.state.politPrivTitulo}</text>
                          </div>
                          <div dangerouslySetInnerHTML={{ __html:  this.state.politPriv}} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    renderParticiparProgFidelidade = (pontosGerados) => {
        const { classes } = this.props;
        
        return (
            <div>
                <div style={{backgroundColor: '#a8ffb2', padding: '10px', borderRadius: '10px'}}>
                    <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', display: 'flex'}}>
                        <div style={{width: '70%', display: 'flex'}}>
                            <CupomIcon style={{ color: '#0b4d13' }} />
                            <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#0b4d13', fontWeight: '500'}} >{"Programa de Fidelidade"}</text>
                        </div>
                        <div style={{width: '30%', display: 'flex'}}>
                            <a
                                onClick={() => {
                                    this.setState({
                                        alertaParticipaProgFide: true,
                                        fidelidadeDescState: tp_programa_confautoriza
                                    });
                                }}
                                style={{color: '#0b4d13', fontSize: '16px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                            >
                                { "Participar"}
                            </a>
                        </div>
                    </div> 
                    <div style={{textAlign: 'center', fontSize: '15px', color: '#000', fontWeight: '500', }}>
                        Junte pontos e troque por produtos.<br />Participe e comece a ganhar.
                    </div>
                    <div style={{textAlign: 'justify', fontSize: '12px', color: '#000', lineHeight: '1.6', marginTop: '7px' }}>* Participando do Programa de Fidelidade, você poderá juntar pontos em suas compras e depois resgatá-los em seus pedidos. Clique em PARTICIPAR agora e já comece a juntar seus pontos.</div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                    <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                </div>
            </div>
        );
    }

    renderParticiparCupomDesc = () => {
        const { classes } = this.props;

        return (
            <div>
                <div style={{backgroundColor: '#a8ffb2', padding: '10px', borderRadius: '10px'}}>
                    <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', display: 'flex'}}>
                        <div style={{width: '70%', display: 'flex'}}>
                            <CupomIcon style={{ color: '#0b4d13' }} />
                            <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#0b4d13', fontWeight: '500'}} >{"Cupom de Desconto"}</text>
                        </div>
                        <div style={{width: '30%', display: 'flex'}}>
                            <a
                                onClick={() => {
                                    this.setState({
                                        alertaParticipaCupom: true,
                                        cupomDescState: tp_cupom_confautoriza
                                    });
                                }}
                                style={{color: '#0b4d13', fontSize: '16px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                            >
                                { "Participar"}
                            </a>
                        </div>
                    </div> 
                    {this.renderCuponsDisp()}
                    <div style={{textAlign: 'justify', fontSize: '12px', color: '#000', lineHeight: '1.6', marginTop: '7px' }}>* Tem um cupom de desconto? Clique em PARTICIPAR e comece a economizar.</div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                    <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                </div>
            </div>
        );
    }

    renderProgramaFide = () => {
     
        const { classes } = this.props;

        var pontosGerados = 0;
        this.state.carrinhoCompra.map((item, index) => {

            if (item.checkedFidelidade != '1'){
                var arrResult = {
                    "pontosAdicBonus" : 0
                }
    
                var preco_prod_sem_adic = this.getPrecoProdSemAdicGeracao(item, arrResult);
    
                var pontosBonus = 0;
    
                try {
                    pontosBonus = parseInt(item.grupoProdsFideGera);
                } catch (error) {
                    pontosBonus = 0;
                }
    
                var valorCalcPontos = preco_prod_sem_adic;
    
                var pontoProd = ( (Math.floor(((valorCalcPontos) / global.programaFidelidadeFatorValMin)) + (pontosBonus)));
                
                if (pontoProd < 0){
                    pontoProd = 0;
                }

                pontoProd = pontoProd + arrResult["pontosAdicBonus"];
                pontoProd = pontoProd * item.qtde
                pontoProd = (pontoProd < 0) ? 0 : pontoProd;
                pontosGerados += pontoProd;
            }

        });

        if (global.programaFidelidade == '0'){
            return (<div></div>);            
        }

        if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){
            return (<div></div>);            
        }

        if (global.programaFidelidade == '1'){
            if (global.cliPerFidelidade == 0){            
                return (
                    this.renderParticiparProgFidelidade(pontosGerados)
                );
            }
            
        }

        return(
            <div style={{backgroundColor: '#a8ffb2', padding: '10px', borderRadius: '10px'}}>
                <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', display: 'flex'}}>
                    <div style={{width: '70%', display: 'flex'}}>
                        <CupomIcon style={{ color: '#0b4d13' }} />
                        <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#0b4d13', fontWeight: '500'}} >{"Programa de Fidelidade"}</text>
                    </div>
                    <div style={{width: '30%', display: 'flex'}}>
                        <a
                            onClick={() => {
                                this.setState({
                                    alertaProgramaFidelidade: true,
                                    carrinho_compra_copia: JSON.parse(JSON.stringify(this.state.carrinhoCompra))
                                });
                            }}
                            style={{color: '#0b4d13', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                        >
                            { "Trocar"}
                        </a>
                    </div>
                </div> 
                <div style={{textAlign: 'justify', fontSize: '12px', color: '#000'}}>* Você pode trocar seus pontos acumulados clicando em TROCAR.</div>
                <div style={{textAlign: 'justify', fontSize: '14px', color: '#000', marginTop: '8px'}}><b>Saldo Atual:</b> {this.state.cli_pontos} ponto(s)</div>
                {this.renderMsgPontos(pontosGerados)}
            </div>
        )
        
    }

    renderMsgPontos = (pontosGerados) => {

        if (pontosGerados > 0){
            return (
                <div style={{fontSize: '15px', color: '#000', fontWeight: '500', }}>
                        {`Nessa compra você ganhará ${pontosGerados} ponto(s).`}
                </div>
            );
        }else{
            return (
                <div>
                    <div style={{fontSize: '15px', color: '#000', fontWeight: '400', }}>
                            {`Sua compra não vai gerar nenhum ponto.`}
                    </div>
                    <div style={{fontSize: '15px', color: '#000', fontWeight: '500', }}>
                            {`Adicione outros produtos pra começar a ganhar.`}
                    </div>
                </div>
            );
        }

    }

    renderCupomDesc = () => {

        const { classes } = this.props;

        if (global.restCupAtivo == '0'){
            return (<div></div>);            
        }

        if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){
            return (<div></div>);            
        }

        if (global.restCupAtivo == '1'){
            if (global.cliPerCupom == 0){            
                return (
                    //this.renderCupomDescontoParticipar()
                    this.renderParticiparCupomDesc()
                );
            }
            
        }

        if (this.state.cupomSelected.idCupom){
            var cupom = this.state.cupomSelected;

            if (global.cliPerCupom == "0"){
                return (
                    <div>
                        
                    </div>
                );
            }

            return (
                <div>
                    <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', backgroundColor: '#fff', display: 'flex'}}>
                        <div style={{width: '60%', display: 'flex'}}>
                            <CupomIcon />
                            <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#555', fontWeight: '500'}} >{"Cupom de Desconto"}</text>
                        </div>
                        <div style={{width: '40%', display: 'flex'}}>
                            <a
                                onClick={() => {
                                    
                                    this.setState({
                                        cupomSelected : [],
                                        formaPagamentoAtual: null,
                                    },() => {
                                        let total_ped = this.returnTotPed();
                                        this.setState({
                                            valPedidoZerado: (total_ped <= 0),
                                        })
                                    })

                                }}
                                style={{marginRight: '15px', color: 'rgb(153, 0, 0)', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                            >
                                { "Remover"}
                            </a>
                            <a
                                onClick={() => {
                                    this.setState({
                                        alertaSelecionaCupom: true,
                                        edit_cupomCod: '',
                                    });
                                }}
                                style={{color: '#3496eb', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right'}}
                            >
                                { "Mudar"}
                            </a>
                        </div>
                    </div> 

                    <div style={Object.assign({}, {margin: '20px 0px', padding: '12px', borderRadius: '8px', border: 'solid 3px #e8d500', borderBottomWidth: '2px', backgroundColor: '#ffeb06'})}>
                        <div style={{fontSize: '16px', color: '#de1212', fontWeight: 'bold'}}>{cupom.descricao}</div>
                        <div style={{fontSize: '14px', color: '#de1212', fontWeight: '420' }}>
                            {
                                this.renderTxtDescCupom(cupom)
                            }
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px'}}>
                            <div style={{fontSize: '12px', flex: 1, color: 'black', fontWeight: 'bold'}}>
                                <div style={{marginLeft: 'auto'}}>
                                    {"Disponível até: " + this.converteData(cupom.dataFim)}
                                </div>
                            </div>
                            <div style={{fontSize: '12px', flex: 1, color: 'black', fontWeight: 'bold'}}>
                                <div style={{marginLeft: 'auto', width: 'fit-content'}}>
                                    { (cupom.valMin > 0) ? 
                                        "Valor mínimo: " + 'R$ ' + cupom.valMin.replace('.', ',') :
                                        "Sem valor mínimo"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', margin: '6px 10px', }}>
                        <div className={classes.separator} style={{marginTop: '2px', marginBottom: '2px', backgroundColor: '#aaa'}} ></div>
                    </div>
                </div>
            );

            /*var iconePagamento = (item.online) ? MPIcon : MoneyIcon;

            return (
                <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', backgroundColor: '#fff', display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '70%'}}>
                            <img src={iconePagamento} style={{marginRight: '5px', height: (item.online) ? '28px' : '20px'}}/>
                            <text style={{marginRight: '30px', fontSize: '15px', color: '#555', fontWeight: '600'}} >{item.nome}</text>
                        </div>
                        <div style={{width: '30%', display: 'flex'}}>
                            <a
                                onClick={() => {
                                    this.carregaFormasPag();
                                }}
                                style={{color: '#3496eb', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                            >
                                { "Mudar"}
                            </a>
                        </div>
                    </div>
                    {this.renderValTroco(item)}
                </div> 
            );*/
        }else{

            if (this.state.cli_cupons.length > 0){
                return (
                    <div style={{backgroundColor: '#a8ffb2', padding: '10px', borderRadius: '10px'}}>
                        <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', display: 'flex'}}>
                            <div style={{width: '70%', display: 'flex'}}>
                                <CupomIcon style={{ color: '#0b4d13' }} />
                                <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#0b4d13', fontWeight: '500'}} >{"Cupom de Desconto"}</text>
                            </div>
                            <div style={{width: '30%', display: 'flex'}}>
                                <a
                                    onClick={() => {
                                        this.setState({
                                            alertaSelecionaCupom: true,
                                            edit_cupomCod: '',
                                        });
                                    }}
                                    style={{color: '#0b4d13', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                                >
                                    { "Escolher"}
                                </a>
                            </div>
                        </div> 
                        {this.renderCuponsDisp()}
                        <div style={{textAlign: 'justify', fontSize: '12px', color: '#000'}}>* Você pode escolher um cupom ou inserir um código de cupom clicando em ESCOLHER.</div>
                    </div>
                );
            }else{
                return (
                    <div style={{backgroundColor: '#a8ffb2', padding: '10px', borderRadius: '10px'}}>
                        <div style={{borderRadius: '8px', width: '100%', padding: '7px 0px', display: 'flex'}}>
                            <div style={{width: '70%', display: 'flex'}}>
                                <CupomIcon style={{ color: '#0b4d13' }} />
                                <text style={{marginRight: '30px', marginLeft: '10px', fontSize: '16px', color: '#0b4d13', fontWeight: '500'}} >{"Cupom de Desconto"}</text>
                            </div>
                            <div style={{width: '30%', display: 'flex'}}>
                                <a
                                    onClick={() => {
                                        this.setState({
                                            alertaSelecionaCupom: true,
                                            edit_cupomCod: '',
                                        });
                                    }}
                                    style={{color: '#0b4d13', fontSize: '17px', fontWeight: '550', textDecoration: 'underline', textAlign: 'right', width: '100%'}}
                                >
                                    { "Escolher"}
                                </a>
                            </div>
                        </div> 
                        {this.renderCuponsDisp()}
                        <div style={{textAlign: 'justify', fontSize: '12px', color: '#000'}}>* Você pode inserir um código de cupom clicando em ESCOLHER.</div>
                    </div>
                );
            }
        }
    }

    renderCuponsDisp = () => {

        var cuponsSize = this.state.cli_cupons.length;

        if (cuponsSize > 0){
            return (
                <div style={{fontSize: '15px', color: '#000', fontWeight: '500', }}>
                    {`Você tem ${cuponsSize} ${(cuponsSize > 1) ? "cupons disponíveis" : "cupom disponível"}.`}
                </div>
            )
        }
    }

    getPrecoProdSemAdicGeracao = (item, arrResult) => {
        var preco_prod_sem_adic = 0;

        var tipoCalcMisto     = global.tipoCalcPrecoMisto;
        var tipoCalcAdicional = global.tipoCalcPrecoAdicional;

        var pontosBonusAdic = 0;

        try {
            item.sabores.map( (value, index) => {
                if (value.produto){
                    if (tipoCalcMisto == 1){
                        preco_prod_sem_adic = (preco_prod_sem_adic < value.produto.preco) ? parseFloat(value.produto.preco) : parseFloat(preco_prod_sem_adic)
                    }else if (tipoCalcMisto == 2){
                        preco_prod_sem_adic = preco_prod_sem_adic + (parseFloat(value.produto.preco) / parseFloat(this.produto.sabores.length))
                    }
                }

                var grupoAdics = value.produto.grupoAdics;

                grupoAdics.map( (valueGrup, index) => {
                    valueGrup.adicionais.map( (value, index) => {
                        if (value.checked >= 1){

                            var pontosAnt = pontosBonusAdic;
                            try {

                                if (tipoCalcAdicional === '0'){
                                    //pontosBonusAdic += ((parseFloat(value.precod) * value.checked) * (2 / this.produto.sabores.length));
                                    var valorAdic = ((parseFloat(value.precod)) * (2 / item.qtdeSabores));
                                    var pontosAdic = parseInt(Math.floor(valorAdic / global.programaFidelidadeFatorValMin));
                                    pontosAdic       = pontosAdic + parseInt(valueGrup.fide_beneficios);
                                    pontosBonusAdic += (pontosAdic < 0) ? 0 : ( pontosAdic * value.checked );
                                }else{
                                    //pontosBonusAdic += (parseFloat(value.precod) * value.checked)
                                    var valorAdic = (parseFloat(value.precod));
                                    var pontosAdic = parseInt(Math.floor(valorAdic / global.programaFidelidadeFatorValMin));
                                    pontosAdic       = pontosAdic + parseInt(valueGrup.fide_beneficios);
                                    pontosBonusAdic += (pontosAdic < 0) ? 0 : ( pontosAdic * value.checked );
                                }

                            } catch (error) {

                                pontosBonusAdic = pontosAnt;

                            }
                        }
                    });
                });
            });

            if (item.grupoAdics) {

                var grupoAdics = item.grupoAdics;
    
                grupoAdics.map( (valueGrup, index) => {
                    valueGrup.adicionais.map( (value, index) => {
                        if (value.checked >= 1){
    
                            var pontosAnt = pontosBonusAdic;
                            try {
    
                                var valorAdic = (parseFloat(value.precod));
                                var pontosAdic = parseInt(Math.floor(valorAdic / global.programaFidelidadeFatorValMin));
                                pontosAdic       = pontosAdic + parseInt(valueGrup.fide_beneficios);
                                pontosBonusAdic += (pontosAdic < 0) ? 0 : ( pontosAdic * value.checked );
    
                            } catch (error) {
    
                                pontosBonusAdic = pontosAnt;
    
                            }
                        }
                    });
                });
            }
    
            if (global.programaFidelidadeAdicional == '1'){
                arrResult["pontosAdicBonus"] = pontosBonusAdic;
            }else{
                arrResult["pontosAdicBonus"] = 0;
            }
        } catch (error) {
            // 
        }

        return preco_prod_sem_adic;
    }

    getPrecoProdSemAdic = (item, arrResult) => {
        var preco_prod_sem_adic = 0;

        var tipoCalcMisto     = global.tipoCalcPrecoMisto;
        var tipoCalcAdicional = global.tipoCalcPrecoAdicional;

        var pontosBonusAdic = 0;

        try {
            item.sabores.map( (value, index) => {
                if (value.produto){
                    if (tipoCalcMisto == 1){
                        preco_prod_sem_adic = (preco_prod_sem_adic < value.produto.preco) ? parseFloat(value.produto.preco) : parseFloat(preco_prod_sem_adic)
                    }else if (tipoCalcMisto == 2){
                        preco_prod_sem_adic = preco_prod_sem_adic + (parseFloat(value.produto.preco) / parseFloat(this.produto.sabores.length))
                    }
                }

                var grupoAdics = value.produto.grupoAdics;

                grupoAdics.map( (valueGrup, index) => {
                    valueGrup.adicionais.map( (value, index) => {
                        if (value.checked >= 1){

                            var pontosAnt = pontosBonusAdic;
                            try {
                                if (tipoCalcAdicional === '0'){
                                    var valorAdic = ((parseFloat(value.precod)) * (2 / item.qtdeSabores));
                                    var pontosAdic = parseInt(Math.ceil(((100 / global.programaFidelidadeFator) / global.programaFidelidadeFatorValMin) * valorAdic));
                                    pontosAdic       = pontosAdic + parseInt(valueGrup.fide_resgate);
                                    pontosBonusAdic += (pontosAdic < 1) ? 1 : (( pontosAdic) * value.checked);
                                }else{
                                    var valorAdic = ((parseFloat(value.precod)));
                                    var pontosAdic = parseInt(Math.ceil(((100 / global.programaFidelidadeFator) / global.programaFidelidadeFatorValMin) * valorAdic));
                                    pontosAdic       = pontosAdic + parseInt(valueGrup.fide_resgate);
                                    pontosBonusAdic += (pontosAdic < 1) ? 1 : (( pontosAdic) * value.checked);
                                }

                            } catch (error) {

                                pontosBonusAdic = pontosAnt;

                            }
                        }
                    });
                });
            });

            if (item.grupoAdics) {

                var grupoAdics = item.grupoAdics;
    
                grupoAdics.map( (valueGrup, index) => {
                    valueGrup.adicionais.map( (value, index) => {
                        if (value.checked >= 1){
    
                            var pontosAnt = pontosBonusAdic;
                            try {

                                var valorAdic = ((parseFloat(value.precod)));
                                var pontosAdic = parseInt(Math.ceil(((100 / global.programaFidelidadeFator) / global.programaFidelidadeFatorValMin) * valorAdic));
                                pontosAdic       = pontosAdic + parseInt(valueGrup.fide_resgate);
                                pontosBonusAdic += (pontosAdic < 1) ? 1 : (pontosAdic * value.checked);

                            } catch (error) {
    
                                pontosBonusAdic = pontosAnt;
    
                            }
                        }
                    });
                });
            }
    
            if (global.programaFidelidadeAdicional == '1'){
                arrResult["pontosAdicBonus"] = pontosBonusAdic;
            }else{
                arrResult["pontosAdicBonus"] = 0;
            }
        } catch (error) {
            // 
        }

        return preco_prod_sem_adic;
    }

    renderResumoPed() {

        const { classes } = this.props;

        var valor_compra = 0;

        this.state.carrinhoCompra.map((item, index) => {
            var itemSelecionado = (item.checkedFidelidade >= 1);

            var arrResult = {
                "pontosAdicBonus" : 0
            }

            var preco_prod_sem_adic = this.getPrecoProdSemAdic(item, arrResult);

            var valorProgFid = (global.programaFidelidadeAdicional == '1') ? 0 : (item.valorUnit * item.qtde) - preco_prod_sem_adic;

            valor_compra += (itemSelecionado) ? valorProgFid : parseFloat(formatarNumero(item.valorUnit * item.qtde, 2).replace(',', '.'));
        });

        var pagDesconto = 0;
        var descontoNoPagamento = 0;
        var descontoNoCupom     = 0;

        try {
            if (this.state.cupomSelected.idCupom){

                if (this.state.cupomSelected.tipoDesc == 0){
                    descontoNoCupom     = Math.round(((valor_compra - descontoNoPagamento) * ((this.state.cupomSelected.desconto) / 100)) * 100) / 100;
                }else if (this.state.cupomSelected.tipoDesc == 1){
                    descontoNoCupom     = this.state.cupomSelected.desconto;
                }else{
                    descontoNoCupom     = this.state.taxaEntrega;
                }

            }   

            try {
                descontoNoCupom = parseFloat(descontoNoCupom);
            } catch (error) {
                
            }

        } catch (error) {
            descontoNoCupom = 0;
        }

        try {
            pagDesconto = parseFloat(this.state.formaPagamentoAtual.desconto) + parseFloat(global.descontoApp);
        } catch (error) {
            try {
                pagDesconto = parseFloat(this.state.tmpFormaPagTroco.desconto) + parseFloat(global.descontoApp);
            } catch (error) {
                pagDesconto = 0;
            }
        }

        if ( isNaN(pagDesconto) && (pagDesconto != undefined) && (pagDesconto != null) ){
            pagDesconto = 0;
        }

        //var prod_val    = valor_compra.toFixed(2);
        var prod_val    = formatarNumero(valor_compra, 2).replace(',', '.');
        prod_val = ((prod_val.trim() == "0.00") || (prod_val.trim() == "0,00")) ? "0,00" : prod_val.replace('.', ',');
        prod_val = 'R$ ' + prod_val;
        
        var taxa_ent    = this.state.taxaEntrega;

        if (typeof(taxa_ent) === 'number'){
            //taxa_ent    = taxa_ent.toFixed(2);
            taxa_ent = formatarNumero(taxa_ent, 2).replace(',', '.');
        }

        taxa_ent = ((taxa_ent.trim() == "0.00") || (taxa_ent.trim() == "0,00")) ? "0,00" : taxa_ent.replace('.', ',');
        taxa_ent = 'R$ ' + taxa_ent;

        var tmpDescCupom = (this.state.cupomSelected.tipoDesc == '2') ? 0 : descontoNoCupom;
        descontoNoPagamento = Math.round(((valor_compra - tmpDescCupom) * ((pagDesconto) / 100)) * 100) / 100;

        var desconto    = (descontoNoPagamento + descontoNoCupom);
        var descontoProds       = 0;
        var descontoTaxaEntrega = 0;

        if (this.state.cupomSelected.tipoDesc == 2){
            descontoProds       = (descontoNoPagamento);
            descontoTaxaEntrega = descontoNoCupom;
        }else{
            descontoProds       = (descontoNoPagamento + descontoNoCupom);
        }

        //var total_ped    = (valor_compra + this.state.taxaEntrega - desconto).toFixed(2);
        var total_ped    = formatarNumero((valor_compra + this.state.taxaEntrega - desconto), 2).replace(',', '.');
        total_ped = ((total_ped.trim() == "0.00") || (total_ped.trim() == "0,00")) ? "0,00" : total_ped.replace('.', ',');
        total_ped = 'R$ ' + total_ped;
        
        //var   strDesc     = descontoProds.toFixed(2);
        var   strDesc     = formatarNumero(descontoProds, 2).replace(',', '.');
        strDesc           = ((strDesc.trim() == "0.00") || (strDesc.trim() == "0,00")) ? "0,00" : strDesc.replace('.', ',');
        strDesc = 'R$ ' + strDesc;

        //var   strDescTax  = descontoTaxaEntrega.toFixed(2);
        var   strDescTax     = formatarNumero(descontoTaxaEntrega, 2).replace(',', '.');
        strDescTax           = ((strDescTax.trim() == "0.00") || (strDescTax.trim() == "0,00")) ? "0,00" : strDescTax.replace('.', ',');
        strDescTax = 'R$ ' + strDescTax;

        return ( 
            <div style={{backgroundColor:"#", width:'100%',}}>
                <div style={{
                    backgroundColor:"#", width: '100%', flexDirection:'row', justifyContent: 'center', alignItems: 'center',
                    margin: '0px 0px', display: 'flex',
                    }}>
                    <text style={{width: '60%', textAlign:'left', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>Total dos Produtos</text>
                    <text style={{width: '40%', textAlign:'right', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>{prod_val}</text> 
                </div>
                {(descontoProds > 0) ? 
                <div style={{
                    backgroundColor:"#", width: '100%', flexDirection:'row', justifyContent: 'center', alignItems: 'center',
                    margin: '0px 0px', display: 'flex',
                        }}>
                    <text style={{width: '60%', textAlign:'left', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>Desconto nos produtos</text>
                    <text style={{width: '40%', textAlign:'right', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>- {strDesc}</text> 
                </div>
                : null}
                <div style={{
                        backgroundColor:"#", width: '100%', flexDirection:'row', justifyContent: 'center', alignItems: 'center',
                        margin: '0px 0px', display: 'flex',
                    }}>
                    <text style={{width: '60%', textAlign:'left', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>Taxa de Entrega</text>
                    <text style={{width: '40%', textAlign:'right', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>{taxa_ent}</text> 
                </div>
                {(descontoTaxaEntrega > 0) ? 
                <div style={{
                    backgroundColor:"#", width: '100%', flexDirection:'row', justifyContent: 'center', alignItems: 'center',
                    margin: '0px 0px', display: 'flex',
                        }}>
                    <text style={{width: '60%', textAlign:'left', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>Desconto na Taxa de Entrega</text>
                    <text style={{width: '40%', textAlign:'right', fontSize: '14px', color: '#bbb', fontWeight: '400',}}>- {strDescTax}</text> 
                </div>
                : null}
                <div style={{
                    backgroundColor:"#", width: '100%', flexDirection:'row', justifyContent: 'center', alignItems: 'center',
                    marginTop: 3, marginBottom: 3, paddingTop: 6, paddingBottom: 6, display: 'flex',
                    }}>
                    <text style={{width: '60%', textAlign:'left', fontSize: '17px', color: '#555', fontWeight: '600',}}>Total do Pedido</text>
                    <text style={{width: '40%', textAlign:'right', fontSize: '17px', color: '#555', fontWeight: '600',}}>{total_ped}</text> 
                </div>
            </div>
        );
    
    }

    pesquisaCupom = () => {
                        
        try {
            // here place your signup logic     
            
            var CancelToken = axios.CancelToken;

            var valorTotal = 0;

            this.state.carrinhoCompra.map((item, index) => {
                valorTotal += (item.valorUnit * item.qtde);
            });
    
            var parametros = 
            "parvalpro="  + valorTotal +
            "&parbuscar=" + (global.buscar ? 1 : 0) +
            "&partoken="  + global.myToken +
            "&pargrures=" + global.cod_grurest +
            "&parres="    + global.cod_restaurante +
            "&parcodcup=" + this.state.edit_cupomCod +
            "&parverapp=" + '99';
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
                
            }, global.timeOutTimer);    
            
            axios.post(
                global.siteurl + 'verifica_cupom_cod.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                this.closeProgress();

                if (responseData.resposta == "1"){

                    //this.salvaJsonPedido(JSON.stringify(jsonPedido.PEDIDO));
                    this.setState({
                        cupomSelected : responseData.cupom,
                        alertaSelecionaCupom : false,
                        formaPagamentoAtual : null,
                    })

                    let total_ped = this.returnTotPed() - this.state.taxaEntrega;
                    if (total_ped <= 0) {
                        this.setState({
                            valPedidoZerado: true,
                        })
                    }
                    
                }else {
                    BS_ALE(this,  'Atenção', responseData.msg);
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });
        } catch (err) {
            
        }
    }

    verificaLojaProds(strJson) {

        try {
            // here place your signup logic     
            
            var CancelToken = axios.CancelToken;

            var valorTotal = 0;

            this.state.carrinhoCompra.map((item, index) => {
                valorTotal += (item.valorUnit * item.qtde);
            });
    
            var parametros = 
            "parvalpro="  + valorTotal +
            "&partoken="  + global.myToken +
            "&pargrures=" + global.cod_grurest +
            "&parres="    + global.cod_restaurante +
            "&parverapp=" + '99';
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
                
            }, global.timeOutTimer);    
            
            axios.post(
                global.siteurl + 'pedido_verif_lojaprod.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                this.closeProgress();

                if (responseData.resposta == "1"){

                    //this.salvaJsonPedido(JSON.stringify(jsonPedido.PEDIDO));
                    BS_ALE(this,  'Sucesso', 'Wooow it works, Hugo !!');
                    
                }else {
                    BS_ALE(this,  'Atenção', responseData.msg);
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });
        } catch (err) {
            
        }
    }

    verificaMinimoPedido = () => {

        // Retorna TRUE se o valor do pedido for menor do que o mínimo configurado para o restaurante

        var valor_compra = 0;
        this.state.carrinhoCompra.map((item, index) => {
            valor_compra += (item.valorUnit * item.qtde);
        });

        if (this.state.edit_tipoEntrega == 'entrega') {
            if (valor_compra < global.valMinDel){
                return true;
            }
        }else{
            if (valor_compra < global.valMinRet){
                return true;
            }
        }

        return false;
    }

    verificaDescontoMax = () => {

        const { classes } = this.props;

        var valor_compra = 0;

        this.state.carrinhoCompra.map((item, index) => {
            var itemSelecionado = (item.checkedFidelidade >= 1);

            var arrResult = {
                "pontosAdicBonus" : 0
            }

            var preco_prod_sem_adic = this.getPrecoProdSemAdic(item, arrResult);

            var valorProgFid = (global.programaFidelidadeAdicional == '1') ? 0 : (item.valorUnit * item.qtde) - preco_prod_sem_adic;

            valor_compra += (itemSelecionado) ? valorProgFid : parseFloat(formatarNumero(item.valorUnit * item.qtde, 2).replace(',', '.'));
        });

        var pagDesconto = 0;
        var descontoNoPagamento = 0;
        var descontoNoCupom     = 0;

        try {
            if (this.state.cupomSelected.idCupom){

                if (this.state.cupomSelected.tipoDesc == 0){
                    descontoNoCupom     = Math.round(((valor_compra - descontoNoPagamento) * ((this.state.cupomSelected.desconto) / 100)) * 100) / 100;
                }else if (this.state.cupomSelected.tipoDesc == 1){
                    descontoNoCupom     = this.state.cupomSelected.desconto;
                }else{
                    descontoNoCupom     = this.state.taxaEntrega;
                }

            }   

            try {
                descontoNoCupom = parseFloat(descontoNoCupom);
            } catch (error) {
                
            }

        } catch (error) {
            descontoNoCupom = 0;
        }

        try {
            pagDesconto = parseFloat(this.state.formaPagamentoAtual.desconto) + parseFloat(global.descontoApp);
        } catch (error) {
            try {
                pagDesconto = parseFloat(this.state.tmpFormaPagTroco.desconto) + parseFloat(global.descontoApp);
            } catch (error) {
                pagDesconto = 0;
            }
        }

        if ( isNaN(pagDesconto) && (pagDesconto != undefined) && (pagDesconto != null) ){
            pagDesconto = 0;
        }

        //var prod_val    = valor_compra.toFixed(2);
        var prod_val    = formatarNumero(valor_compra, 2).replace(',', '.');
        prod_val = ((prod_val.trim() == "0.00") || (prod_val.trim() == "0,00")) ? "0,00" : prod_val.replace('.', ',');
        prod_val = 'R$ ' + prod_val;
        
        var taxa_ent    = this.state.taxaEntrega;

        if (typeof(taxa_ent) === 'number'){
            //taxa_ent    = taxa_ent.toFixed(2);
            taxa_ent = formatarNumero(taxa_ent, 2).replace(',', '.');
        }

        taxa_ent = ((taxa_ent.trim() == "0.00") || (taxa_ent.trim() == "0,00")) ? "0,00" : taxa_ent.replace('.', ',');
        taxa_ent = 'R$ ' + taxa_ent;

        var tmpDescCupom = (this.state.cupomSelected.tipoDesc == '2') ? 0 : descontoNoCupom;
        descontoNoPagamento = Math.round(((valor_compra - tmpDescCupom) * ((pagDesconto) / 100)) * 100) / 100;

        var desconto    = (descontoNoPagamento + descontoNoCupom);
        var descontoProds       = 0;
        var descontoTaxaEntrega = 0;

        if (this.state.cupomSelected.tipoDesc == 2){
            descontoProds       = (descontoNoPagamento);
            descontoTaxaEntrega = descontoNoCupom;
        }else{
            descontoProds       = (descontoNoPagamento + descontoNoCupom);
        }

        //var total_ped    = (valor_compra + this.state.taxaEntrega - desconto).toFixed(2);
        var total_ped    = formatarNumero((valor_compra + this.state.taxaEntrega - desconto), 2).replace(',', '.');
        total_ped = ((total_ped.trim() == "0.00") || (total_ped.trim() == "0,00")) ? "0,00" : total_ped.replace('.', ',');
        total_ped = 'R$ ' + total_ped;
        
        //var   strDesc     = descontoProds.toFixed(2);
        var   strDesc     = formatarNumero(descontoProds, 2).replace(',', '.');
        strDesc           = ((strDesc.trim() == "0.00") || (strDesc.trim() == "0,00")) ? "0,00" : strDesc.replace('.', ',');
        strDesc = 'R$ ' + strDesc;

        //var   strDescTax  = descontoTaxaEntrega.toFixed(2);
        var   strDescTax     = formatarNumero(descontoTaxaEntrega, 2).replace(',', '.');
        strDescTax           = ((strDescTax.trim() == "0.00") || (strDescTax.trim() == "0,00")) ? "0,00" : strDescTax.replace('.', ',');
        strDescTax = 'R$ ' + strDescTax;

        //!@#!@#

        return (descontoProds > valor_compra);
    }

    verificaCamposCli = (ref) => {

        if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){
            var num_cel   = this.state.cli_tele_edit.replace(/\D/g, "");
            var nome_cli  = this.state.cli_nome_edit;
    
            if ((nome_cli.trim().length < 6) || (nome_cli.trim().length > 60)){
                ref['campo'] = tp_campo_nome;
                return ('O nome do cliente precisa ter entre 6 e 60 caracteres');
            }else if ((num_cel.trim().length < 10)){
                ref['campo'] = tp_campo_celular;
                return ('O telefone precisa de ao menos 8 números e o DDD');
            }
    
            return ('');
        }else{
            return ('');
        }
    }

    verificaCampos() {

        var ref = [];
        var msgCamposCli = this.verificaCamposCli(ref);
        let total_ped = this.returnTotPed();
        if (!this.state.formaPagamentoAtual && total_ped > 0){
            this.carregaFormasPag();
        }else if (this.verificaMinimoPedido()) {

            var msgMinimo = `O valor mínimo de pedido para ${(this.state.edit_tipoEntrega == 'entrega') ? "delivery" : "retirada" } é: R$` + ( (this.state.edit_tipoEntrega == 'entrega') ?  global.valMinDel : global.valMinRet).replace('.', ',');
            BS_ALE(this, 'Atenção', msgMinimo);

        }else if (msgCamposCli != ''){
            BS_ALE(this, 'Atenção', msgCamposCli, () => {
                if (ref['campo'] == tp_campo_nome){
                    this.cp_nome.current.focus();
                }else if (ref['campo'] == tp_campo_celular){
                    this.cp_celular.current.focus();
                    //TelefoneMaskInput.focus();
                }
            });
        }else if (this.verificaDescontoMax()){
            var msgMinimo = 'Os descontos do seu pedido não podem ser maiores que o valor do pedido.';
            BS_ALE(this, 'Atenção', msgMinimo);
        }else if ((this.state.tipoDocNota == "nenhum") && (!this.state.valPedidoZerado)){
            this.setState({
                alertaDesejaNota: true,
            });
        }else {
            this.geraPedido();
        }
    }

    renderBtnAvancar() {
        let total_ped = this.returnTotPed();
        if (!this.state.formaPagamentoAtual && total_ped > 0){
            return "Definir pagamento";
        }else {
            return "Enviar Pedido";
        }
    }

    geraPedido = () => {

        let localCart = global.ProductObj.getCarrinhoCompra();
        

        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            this.props.navigate('carrinho-compra');
            BS_ALE(this,  'Atenção', 'Seu carrinho expirou, selecione seus produtos novamente e faça seu pedido.');
            return false;
        }

        var jsonPedido = geraJson();

        var valor_compra = 0;

        jsonPedido.PEDIDO.PRODUTOS = this.separaProdsJSON(jsonPedido.PEDIDO.PRODUTOS);

        try {
            for (var i = 0; i < jsonPedido.PEDIDO.PRODUTOS.length; i++){
                if (this.state.carrinhoCompra[i].checkedFidelidade == '1'){
                    jsonPedido.PEDIDO.PRODUTOS[i].RESGATE_PONTOS = true;
                }
            }   
        } catch (error) {
            
        }

        this.state.carrinhoCompra.map((item, index) => {
            if (item.checkedFidelidade != '1'){
                valor_compra += parseFloat(formatarNumero((item.valorUnit * item.qtde), 2).replace(',', '.'));
            }else if (global.programaFidelidadeAdicional != '1'){

                var arrResult = {
                    "pontosAdicBonus" : 0
                }

                var preco_prod_sem_adic = this.getPrecoProdSemAdic(item, arrResult);

                //valor_compra += ((item.valorUnit * item.qtde) - preco_prod_sem_adic);
                valor_compra += parseFloat(formatarNumero(((item.valorUnit * item.qtde) - preco_prod_sem_adic), 2).replace(',', '.'));
            }
        });

        jsonPedido.PEDIDO.PRODUTOS = this.agrupaProds(jsonPedido.PEDIDO.PRODUTOS);

        var pagDesconto = 0;
        var descontoNoPagamento = 0;
        var descontoNoCupom     = 0;

        try {
            if (this.state.cupomSelected.idCupom){

                if (this.state.cupomSelected.tipoDesc == 0){
                    descontoNoCupom     = Math.round(((valor_compra - descontoNoPagamento) * ((this.state.cupomSelected.desconto) / 100)) * 100) / 100;
                }else if (this.state.cupomSelected.tipoDesc == 1){
                    descontoNoCupom     = this.state.cupomSelected.desconto;
                }else{
                    descontoNoCupom     = this.state.taxaEntrega;
                }

            }   

            try {
                descontoNoCupom = parseFloat(descontoNoCupom);
            } catch (error) {
                
            }

        } catch (error) {
            descontoNoCupom = 0;
        }


        try {
            pagDesconto = parseFloat(this.state.formaPagamentoAtual.desconto) + parseFloat(global.descontoApp);
        } catch (error) {
            try {
                pagDesconto = parseFloat(this.state.tmpFormaPagTroco.desconto) + parseFloat(global.descontoApp);
            } catch (error) {
                pagDesconto = 0;
            }
        }

        if ( isNaN(pagDesconto) && (pagDesconto != undefined) && (pagDesconto != null) ){
            pagDesconto = 0;
        }

        var tmpDescCupom = (this.state.cupomSelected.tipoDesc == '2') ? 0 : descontoNoCupom;
        descontoNoPagamento = Math.round(((valor_compra - tmpDescCupom) * ((pagDesconto) / 100)) * 100) / 100;

        if (this.state.cupomSelected.idCupom){
            jsonPedido.PEDIDO.CUPOM = {

                ID_CUPOM  : this.state.cupomSelected.idCupom,
                DESCRICAO : this.state.cupomSelected.descricao,
                TIPO_DESC : this.state.cupomSelected.tipoDesc,
                DESCONTO  : this.state.cupomSelected.desconto,
                DATAINI   : this.state.cupomSelected.dataIni,
                DATAFIM   : this.state.cupomSelected.dataFim,
                VALMIN    : this.state.cupomSelected.valMin

            };
        }

        const desconto    = (descontoNoPagamento + descontoNoCupom);

        //var total_ped   = (valor_compra + this.state.taxaEntrega - desconto).toFixed(2).replace(',', '.');
        var total_ped   = formatarNumero((valor_compra + this.state.taxaEntrega - desconto), 2).replace(',', '.');

        if (this.state.pagoOnline == 1){

        }else{
            var valorPag = (this.state.formaPagamentoAtual && this.state.formaPagamentoAtual.valTroco) ? this.state.formaPagamentoAtual.valTroco : total_ped;
            valorPag     =  valorPag.replace(',', '.');
            valorPag     =  (valorPag > 0) ? valorPag : total_ped;

            var pagoOnline = (this.state.formaPagamentoAtual && this.state.formaPagamentoAtual.online) ? this.state.formaPagamentoAtual.online : false;

            jsonPedido.PEDIDO.OBSERVACAO = this.state.ped_obsped ?? '';

            jsonPedido.PEDIDO.PEDIDO = {
                HORA               : '',
                ID_PAGAMENTO       : (this.state.formaPagamentoAtual && this.state.formaPagamentoAtual.idPagamento) ? this.state.formaPagamentoAtual.idPagamento : undefined,
                VALOR_PRE_RECEBIDO : (pagoOnline) ? valorPag : 0,
                PRE_PAGAMENTO      : (pagoOnline) ? this.state.formaPagamentoAtual.nome : '',  
                VALOR_POS_RECEBIDO : (pagoOnline) ?  0 : valorPag,
                POS_PAGAMENTO      : (pagoOnline) ? '' : (this.state.formaPagamentoAtual && this.state.formaPagamentoAtual.nome) ? this.state.formaPagamentoAtual.nome : "Pedido Zerado",
                VALOR_PEDIDO       : 0,
                VALOR_RECEBER      : 0,
                TAXA_ENTREGA       : 0,
                VALOR_DESCONTO     : formatarNumero(desconto, 2).replace(',', '.'),  
                //BUSCAR             : global.buscar,
                PAGO               : '',
                VALOR_TROCO        : 0,  
                DATA               : '',
                PLATAFORMA         : global.plataforma,
                VERSAO             : global.versaoApp,
            };

            jsonPedido.PEDIDO.CLIENTE.CNPJ_CPF_CUPOM = (this.state.tipoDocNota == "nenhum") ? "" : this.state.docDocNota;
        }

        //this.verificaLojaProds();
        this.salvaJsonPedido(JSON.stringify(jsonPedido.PEDIDO));
        
    }

    comparaTexto(val1, val2){

        var arrPalavras = val2.split(' ');
    
        var resultado = true;
        arrPalavras.map((palavra, index) => {
          if (!this.trataTexto(val1).includes(this.trataTexto(palavra))){
            resultado = false;
          }
        });
    
        return resultado;
    }

    leProdutosDisponiveis(tmpProd) {
        var menuItems = [];
        const cookies = new myCookies();

        try {
    
          var CancelToken = axios.CancelToken;
              
          let source = CancelToken.source();
          setTimeout(() => {
            source.cancel();
            
          }, 20000);
    
          var parametros = 'parresid=' + global.cod_restaurante + '&pargrures=' + global.cod_grurest + '&parmesa=' + (global.pedMesa ? "1" : "0");
          
          return axios.post(
            global.siteurl + 'produtos_lista.php', 
            parametros,
            {
                cancelToken: source.token,
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true
            }).then((response) => {
                
              var responseData = response.data;
              
              if (responseData.resposta == '1') {
                var arrLista = JSON.parse(JSON.stringify(responseData.registros));
                var arrFinal = [];

                console.log(`ERR - TRACK - JSON - RESTAURANTE - 1 - ${JSON.stringify(responseData.restaurante)}`);

                console.log(`ERR - TRACK - restaberto - 1 - ${global.restAberto}`);
                global.restAberto = (responseData.restaurante.aberto) ? responseData.restaurante.aberto : "0";
                console.log(`ERR - TRACK - restaberto - 1.1 - ${global.restAberto}`);

                if (responseData.promocao == '1'){
    
                  var arrProdPromo = [];
    
                  for (var index = 0; index < responseData.registros.length; index++){
                    if (index > 1){
                      for (var i = 0; i < responseData.registros[index].produtos.length; i++){
                        if ((responseData.registros[index].produtos[i].promo == "1")){
                          arrProdPromo.push( responseData.registros[index].produtos[i] );
                        }
                      };
                    }  
                  }
    
                  arrFinal.push({
                    nome : "Promoções",
                    imagem : "",
                    info : "",
                    produtos : arrProdPromo,
                  });
                }
    
                arrLista.map((prop, key) => {
    
                  arrFinal.push({
                    nome : prop.nome,
                    misto : prop.misto,
                    imagem : prop.cor,
                    info : prop.info,
                    codigo: prop.idGrupo,
                    produtos : prop.produtos,
                    grupoAdics : prop.grupoAdics,
                    fide_beneficios: prop.fide_beneficios,
                    fide_resgate: prop.fide_resgate,
                  });
                  
                });

                
                
                var resultFunc   = global.ProductObj.copiaPedidoCarrinho(tmpProd, arrFinal);
                var indisponivel = resultFunc.indisp;

                var now = new Date();
                var time = now.getTime();
                var maxAge     = 60*60*24*40000;
                var expireTime = time + maxAge;

                var expires = (new Date(expireTime));

                cookies.set(global.cod_restaurante + '_dicaShowed', '1', { path: '/', expires: expires, maxAge: maxAge });

                if (indisponivel.length > 0){
                    this.setState({
                        alertaIndisponiveis: true,
                        prodsIndisp: indisponivel,
                        produtosDispQtd: resultFunc.dispQtd
                    });
                }else{
                    this.setState({
                        alertaIndisponiveis: true,
                        prodsIndisp: [],
                        produtosDispQtd: resultFunc.dispQtd
                    });
                }
                //BS_ALE(this,  'Atenção', 'Teeesteee');
                this.props.navigate('carrinho-compra');
              }else{
                this.setState({
                  erroDB : true,
                });
                return false;
              }
          })
          .catch((error) =>{
            if(axios.isCancel(error)){
                return false;
            }
            else{
                return false;
            }
          });
        } catch (err) {
            return false;
        }  
    
    
        
      }

    salvaJsonPedido(strJson) {

        let encodedAuth = Buffer.from(strJson).toString("base64").replace(/=/g, "");
        
        try {
            // here place your signup logic     
            
            var CancelToken = axios.CancelToken;
    
            var valorTotal = 0;

            this.state.carrinhoCompra.map((item, index) => {

                var arrResult = {
                    "pontosAdicBonus" : 0
                }

                var preco_prod_sem_adic = this.getPrecoProdSemAdic(item, arrResult);

                var valorTmp = 0;

                if (global.programaFidelidadeAdicional == '1'){
                    valorTmp += (item.checkedFidelidade >= 1) ? 0 : (item.valorUnit * item.qtde);
                }else{
                    valorTmp += (item.checkedFidelidade >= 1) ? ((item.valorUnit * item.qtde) - preco_prod_sem_adic) : (item.valorUnit * item.qtde);
                }

                valorTotal += parseFloat(formatarNumero(valorTmp, 2).replace(',', '.'));
            });

            var nolog_buscar = "0";
            if (this.state.cli_enderecos) {
                this.state.cli_enderecos.map( (item, index) => {
                    if (item.enderecoAtivo == '1'){
                        if (item.identificacao == "RETIRADA"){
                            nolog_buscar   = "1";
                        }else{
                            nolog_buscar   = "0";
                        }                        
                    }
                })
            }

            const cookies = new myCookies();
            /*var nologin_hash = cookies.get(global.cod_restaurante + '_tmp_no_log');
            nologin_hash = (nologin_hash) ? nologin_hash : '';

            global.intercept_nologin_hash = nologin_hash;*/
            var nologin_hash = global.intercept_nologin_hash;
    
            var parametros = 
            "parvalpro="  + valorTotal +
            "&parjson="   + encodedAuth +
            "&partoken="  + global.myToken +
            "&pargrures=" + global.cod_grurest +
            "&parres="    + global.cod_restaurante +
            "&nolog_clifone=" + this.state.cli_tele_edit.replace(/_/g, '') +
            "&nolog_clinome=" + this.state.cli_nome_edit +
            "&nolog_buscar=" + nolog_buscar +
            "&nologin_hash=" + nologin_hash +
            "&parverapp=" + '99';

            if (((global.myToken == '') || (!global.myToken)) && (global.pedidoNoCad == '1')){
                parametros += "&parverapp=" + '99';

                this.state.cli_enderecos.map( (item, index) => {
                    if (item.enderecoAtivo == '1'){
                        
                        if (item.identificacao != "RETIRADA"){

                            parametros += "&nolog_end_cep=" + item.cep;
                            parametros += "&nolog_end_estado=" + item.estado;
                            parametros += "&nolog_end_cidade=" + item.cidade;
                            parametros += "&nolog_end_bairro=" + item.bairro;
                            parametros += "&nolog_end_rua=" + item.rua;
                            parametros += "&nolog_end_numero=" + item.numero;
                            parametros += "&nolog_end_complemento=" + item.complemento;
                            parametros += "&nolog_end_referencia=" + item.referencia;
                            parametros += "&nolog_end_codbairro=" + item.codigo_bairro;

                        }
                        
                    }
                });
            }
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);    
            
            axios.post(
                global.siteurl + 'pedido_salvar.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then(async (response) => {
                var responseData = response.data;
                this.closeProgress();

                if (responseData.resposta == "1"){

                    var nologin_tmphash = responseData.nolog_hash;
                    if (nologin_tmphash != ''){
                        const cookies = new myCookies();

                        var now = new Date();
                        var time = now.getTime();
                        var maxAge     = 60*60*24;
                        var expireTime = time + maxAge;

                        var expires = (new Date(expireTime));

                        cookies.set(global.cod_restaurante + '_tmp_no_log', nologin_tmphash, { path: '/', expires: expires, maxAge: maxAge });

                        await Preferences.set({
                            key: `${global.cod_restaurante}_tmp_no_log`,
                            value: nologin_tmphash,
                        });

                        global.pedidosPendentes = true;
                        global.intercept_nologin_hash = nologin_tmphash;
                        
                    }

                    global.ProductObj.limpaCarrinho();

                    global.pedidoAtual = responseData.codigo_web;

                    if (this.state.formaPagamentoAtual?.online ?? false){
                        var f = document.getElementById('Form');
                        f.parope.value = global.pedidoAtual;
                        f.parres.value = global.cod_restaurante;

                        window.open('', 'TheWindow');
                        f.submit();

                        this.props.navigate('pedido-status');
                    }else{
                        this.props.navigate('pedido-status');
                    }
                    
                }else if (responseData.resposta == 'ERRVALPROD'){
                    let tmpProd = responseData.prods;

                    var produtoInvalido = false;

                    for (var i = 0; i < tmpProd.length; i++){
                        if (tmpProd[i].VALOR_TOTAL == ''){
                            produtoInvalido = true;
                        }
                    }

                    if (produtoInvalido){
                        BS_ALE(this,  'Atenção', 'Seu pedido não foi enviado.\nAlguns produtos que você selecionou não estão mais disponíveis, verifique seu carrinho antes de continuar a compra.');
                    }else{
                        BS_ALE(this,  'Atenção', 'Alguns produtos do seu pedido estavam com preços desatualizados, confirme os novos valores antes de continuar com sua compra.');

                    }
                    
                    this.leProdutosDisponiveis(tmpProd);
                }else {

                    if (responseData.cupomerr == 1){
                        this.setState({
                            
                            cupomSelected: [],
                            
                        }, () => {
                            BS_ALE(this,  'Atençãaaaao', responseData.msg);
                            this.carregaDados();
                        })
                    }else{
                        BS_ALE(this,  'Atençãaao 2', responseData.msg);    
                    }

                    
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });
        } catch (err) {
            
        }
    }

    renderMensageKG(){
        if (!global.mensagemUnidadeShowed){
            var cart = this.state.carrinhoCompra;
            var temProdutoKg = false;
    
            temProdutoKg = cart.some((produto, index) => {
                return produto.sabores.some( (sabor, index) => {
                    var saborAtual = sabor.produto;
                    return (saborAtual.unidade == "KG");
                });
            });
    
            if (temProdutoKg) {
                return (
                    <div style={{borderRadius: '8px', padding: '6px 20px', color: '#cc6d00', fontWeight: '410', margin: '10px', marginTop: '0px', fontSize: '14px', textAlign: 'justify', textJustify: 'inter-word'}}>
                        (*) {global.txtMensagemUnidade}
                    </div>
                );
            }
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.myBody} style={{marginTop: '0px', marginBottom: '0px', paddingBottom: '0px'}}>
                <div className={classes.container} style={{paddingBottom: '25px'}}>
                    <Card style={{minHeight: '100vh'}}>
                        <form className={classes.form}>
                            <Header context={this} defaultIcon={false} leftLinks={
                                <div style={{ whiteSpace: 'nowrap'}}>
                                    <div style={{width: 'max-content', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                                        {this.renderHeader()}
                                    </div>                                    
                                </div>
                            } />
                            <CardBody>
                                {this.renderInfoCompra()}
                            </CardBody>
                        </form>                
                    </Card>
                </div>
                <Footer qtdeTiposProds={this.state.carrinhoCompra.length} whiteFont footerExtra={
                    <div onClick={() => this.verificaCampos()} className={classes.buttonFooter}>
                        <div style={{fontWeight: '400', width: '96%', margin: '0px 2%', position: 'relative', display: 'flex'}}>
                            <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>{this.renderBtnAvancar()}</div>
                        </div>                        
                    </div>
                } />
                <Confirmacao 
                    showAlert={this.state.alertaTroco}
                    onClose={this.handleCloseTroco}
                    callbackYes={() => {this.resolveAlertaTroco()}} 
                    title={'Atenção'}
                    msg={'Você deseja troco?'}
                    callbackNo={() => {
                        this.handleCloseTroco();
                    }}
                />

                <Confirmacao 
                    showAlert={this.state.alertaDesejaNota}
                    onClose={this.handleCloseDesejaNota}
                    callbackYes={() => {this.resolveAlertaDesejaNota()}} 
                    title={'Atenção'}
                    msg={'Você deseja inserir algum documento na Nota Fiscal?'}
                    callbackNo={() => {
                        this.setState({
                            alertaDesejaNota: false,
                        }, () => {
                            this.geraPedido();
                        })
                    }}
                />
                {this.renderDialogEndereco()}
                {this.renderDialogPagamento()}
                {this.renderDialogParticiparProgFid()}
                {this.renderDialogParticiparCupom()}
                {this.renderDialogDocNota()}
                {this.renderDialogTroco()}
                {this.renderDialogSelectCupom()}
                {this.renderDialogProgramaFidelidade()}

                {this.renderPoliticaPrivacidade()}

                <div style={{visibility: 'hidden'}}>
                    
                </div>

                <form id="Form" method="post" action={global.siteurl + 'mercado_pago.php?t=' + Date.now()} target="TheWindow">
                    <input type="hidden" name="parope" value="" />
                    <input type="hidden" name="parres" value="" />
                </form>

            </div>
        );
    }
}

export default withStyles(CarrinhoCompra, styles); ;
