import React from "react";
import { BrowserRouter, Routes, Route, HashRouter, useNavigate, useLocation } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

import TextField from '@mui/material/TextField';

// pages for this product
import LoginPage from "views/LoginPage/LoginPage.jsx";
import PrincipalPage from "views/PrincipalPage/PrincipalPage.jsx";
import CarrinhoCompra from "views/CarrinhoCompra/CarrinhoCompra.jsx";
import SelecionaProd_selecionaSabor from "views/PrincipalPage/SelecionaProd_selecionaSabor.jsx";
import UsuarioInfo_addend from "views/LoginPage/UsuarioInfo_addendereco.jsx";

import FinalizaCompra from "views/FinalizaCompra/FinalizaCompra.jsx";

import PedidoLista from "views/PedidoLista/PedidoLista.jsx";
import PedidoStatus from "views/PedidoLista/PedidoStatus.jsx";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "components/CustomButtons/Button.js";

import ProductObj from 'funcs/ProductObj.js';

import load from 'assets/img/loading.gif';
import 'assets/css/index.css';
import Axios from "axios";

import { renovaJWT, temPedidos } from 'funcs/MyFuncs.js';

import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import { BS_ALE } from "funcs/MyFuncs";

import myCookies from 'universal-cookie';

import 'prevent-pull-refresh';

import Confirmacao from "components/Dialogs/Confirmacao.js";

import InfoOutlined from "@mui/icons-material/InfoOutlined";

import AddToHomescreen from 'react-add-to-homescreen';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { container } from "assets/jss/material-kit-react.js";

import { Preferences } from '@capacitor/preferences';

import { ScreenOrientation } from '@capacitor/screen-orientation';

//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import styles from "assets/jss/material-kit-react/views/principalPage.js";

import myRestAtivo from './rest_ativo'; // Relative path to your File

import { Capacitor } from '@capacitor/core';

//import text from './rest_ativo.js'; // Relative path to your File

var routeParams = [];

const classes = {
  container: {
    ...container,
    position: "relative",
    paddingTop: "0px",
    color: "#FFFFFF",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  myBody: {
    overscrollBehaviorY: 'contain',
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%"
    }
  },
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "-40px",
    marginBottom: "0px",
    height: "250px",
    background: "none",
    backgroundColor: "#fff",
    padding: "0px",
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  },
  inputIconsColor: {
    color: "#495057"
  },
  separator: {
    width: "100%",
    height: "1px",
    marginRight: "30px",
    backgroundImage: "radial-gradient(#CCC, #FFF), radial-gradient(#CCC, #FFF)",
    backgroundPosition: "0, 100%, 0, 100%",
  },
  separatorMenu: {
    width: "100%",
    height: "2px",
    marginRight: "30px",
    backgroundImage: "radial-gradient(#F00, #FFF), radial-gradient(#F00, #FFF)",
    backgroundPosition: "0, 100%, 0, 100%",
  },
  paper: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '2px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: '6px 0px 4px',
    fontSize: '15px',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
};

global.siteurl = "https://sisecomm.com.br/vmxdelivery/";

global.platnewlogin = ["Web", "IOS", "ANDROID"];
global.timeOutTimer = 20000;

global.primeiraVez = true;

global.infoObrig = "";
global.infoObrigTitulo = ""
global.mostraTermos = true;
global.infoObrigVer = 0;

global.infoAdic = "";
global.infoAdicTitulo = "";
global.politPriv = "";

global.tipoCalcPrecoMisto = 1;
global.tipoCalcPrecoAdicional = 1;

global.siteAtual = "";
global.urlServer = "https://sisecomm.com.br/vmxdelivery/";
global.versaoApp = 132;
global.usr_id = 12;

global.restAberto = "1";
global.restEndereco = "";
global.apidist = "0";

global.proximaAbertura = "";
global.horariosFunc = [];

global.restPonBal = "0";
global.restPonDel = "0";

global.restPerBal = "0";
global.restPerDel = "0";

global.cliPerCupom = "0";
global.cliPerFidelidade = "0";

global.restCupAtivo = "0";

global.cupomAtivado = "0";
global.programaFidelidade = "0";

global.temPedidos = "0";
global.mostrouDicas = false;

global.tentativaRequestConfig = "";

global.pedMesa = false;

global.hashRest = "";
global.cod_grurest = "";
global.nome_rest = "Mr Bolat";
global.cod_restaurante = "";
global.exObs = "Ex: Ponto da carne, bebida sem gelo, com gelo, etc.";
global.msg_retirada = "Não esqueça de vir de máscara";
global.mostraUnidade = false;
global.facebookID = "895662054305928";
global.tel_restaurante = "(11) 9999-9999";

global.ProductObj = new ProductObj();

global.chaveLocalLogin = "@vmx-001/" + global.cod_restaurante + "rockstar";

global.corDegrade01 = "#ffc72c";
global.corDegrade02 = "#d2a84c";
global.rgBarraInfo = "#FFC72C";
global.rgBarraInfoEscuro = "#EBB318";
global.rgBarraInfoFont = "rgb(0, 0, 0)";
global.rgBarraSubInfo = "#DADADA";
global.rgBarraSubInfoEscuro = "#C6C6C6";
global.rgBarraSubInfoFont = "rgb(0, 0, 0)";
global.rgFundo = "#FFFFFF";
global.rgFundoFont = "rgb(0, 0, 0)";
global.rgRetirar = "#FF0000";

global.mensagemUnidadeShowed = false;
global.txtMensagemUnidade = 'Produtos em quilo podem ter variação em seu peso/preço devido ao tamanho do produto e corte. Variação média de 10% para mais ou para menos.';

// CORES

global.pedidosPendentes = false;
global.alertaPedPendExibido = false;

global.intercept_nologin_hash = '';

export function pass_function(message) {
  alert('42');
}

class IndexPage extends React.Component {

  alertaExibido = false;

  URLToArray(url) {
    var request = {};
    var pairs = url.substring(url.indexOf('?') + 1).split('&');
    for (var i = 0; i < pairs.length; i++) {
      if (!pairs[i])
        continue;
      var pair = pairs[i].split('=');
      request[(pair[0])] = (pair[1]);
    }
    return request;
  }

  handleAddToHomescreenClick = () => {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
  };

  vmxale = (title, message, callback) => {
    this.setState({
      mostraVmxAle: true,
      textVmxAle: message,
      titleVmxAle: title,
      callbackVmxAle: callback
    });
  }

  ArrayToURL(array) {
    var pairs = [];

    for (var key in array) {
      if (array.hasOwnProperty(key)) {
        var uri = array[key];
        const isEncoded = uri => uri !== decodeURIComponent(uri || '');

        if (isEncoded) {
          pairs.push((key) + '=' + (array[key]));
        } else {
          pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(array[key]));
        }


      }
    }

    return pairs.join('&');
  }

  ArrayToURLNoEncode(array) {
    var pairs = [];
    for (var key in array)
      if (array.hasOwnProperty(key))

        pairs.push((key) + '=' + (array[key]));
    return pairs.join('&');
  }

  constructor(props) {
    super(props);

    var scrollPos = 0;

    console.log("dddddd");

    this.state = {
      estadoApp: 0,
      appCarregado: false,
      mostraVmxAle: false,
      textVmxAle: '',
      titleVmxAle: '',
      dicaShowed: '0',
      cookieAcceptName: 'myAwesomeCookie',
      delayRender: true,
      carregando: true,
      alertaPedPend: false,
      pickingPlace: false,

      notFound: false,

      versaoOk: true,
      urlAppNewVer: '',
    };

    global.axiosTentativas = 0;

    let meuToken = localStorage.getItem(global.chaveLocalLogin);
    meuToken = meuToken;

    global.myToken = meuToken;

    global.descontoApp = 5;

    this.carregaDados = this.carregaDados.bind(this);
  }

  componentDidMount() {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    var rest = params.get('rest'); // parametro loja
    var mesa = (params.get('mesa')) ? params.get('mesa') : ''; // parametro loja

    const cookies = new myCookies();

    global.plataforma = '';

    if (Capacitor.getPlatform() == 'web') {
      global.plataforma = 'Web';
    } else {
      rest = '';
      ScreenOrientation.lock({ orientation: 'portrait' });
      if (Capacitor.getPlatform() == 'ios') {
        global.plataforma = 'IOS';
      } else {
        global.plataforma = 'ANDROID';
      }
    }

    if (mesa != '') {
      global.pedMesa = (mesa.toLowerCase() == '56hnzz7gg5');
    }

    if (rest) {

      if (rest == 'esfihabrasil') {
        this.setState({
          pickingPlace: true
        });
      }

      global.hashRest = rest;
      localStorage.setItem('@vmx-001/ultimorest', rest);
    } else {
      var parametroLocal = localStorage.getItem('@vmx-001/ultimorest');
      if (parametroLocal) {
        global.hashRest = parametroLocal;
      }
    }

    if ((global.hashRest == '') && (global.plataforma != 'Web')) {
      global.hashRest = myRestAtivo;
    }

    global.mensagemUnidadeShowed = false;

    this.setState({
      carregando: true
    }, () => {
      setTimeout(() => {
        this.setState({
          carregando: false,
        }, () => {
          document.activeElement.blur();
        })
      }, 500);
    });

    let meuToken = localStorage.getItem(global.chaveLocalLogin);
    meuToken = meuToken;

    global.myToken = meuToken;

    window.addEventListener('scroll', this.handleScroll);
    //window.addEventListener("resize", this.handleResize);

    Axios.interceptors.request.use((config) => {
      return new Promise(async (resolveAxios, rejectAxios) => {
        //window.scrollTo(0, 0);

        let meuToken = localStorage.getItem(global.chaveLocalLogin);
        meuToken = (meuToken) ?? "";

        const { value } = await Preferences.get({ key: `${global.chaveLocalLogin}_token` });

        var dataArr = this.URLToArray(config.data);
        dataArr.parres = global.cod_restaurante;
        dataArr.partoken = meuToken;
        dataArr.intercept_nologin_hash = global.intercept_nologin_hash;
        dataArr.parinterceptappver = global.versaoApp;
        dataArr.parinterceptapppla = global.plataforma;

        if (value) {
          dataArr.parpreferencetoken = value;
        }

        config.data = this.ArrayToURL(dataArr);

        console.log(`LOGIN - 2 - ${dataArr.parpreferencetoken}`)

        if (config.url == global.siteurl + "pedido_consulta_simplificado.php") {
          resolveAxios(config);
        } else {
          this.setState({ "estadoApp": 0 }, () => {
            document.body.style.overflowY = "hidden";
            resolveAxios(config);
          });
        }
      });

    });

    Axios.interceptors.response.use((response) => {
      // Do something with response data

      if (response.data.verifica_rest) {

        console.log(`ERR - TRACK - restaberto - 0 - ${global.restAberto}`);

        global.tel_restaurante = (response.data.verifica_rest.telefone) ? response.data.verifica_rest.telefone : global.tel_restaurante;
        global.restAberto = (response.data.verifica_rest.aberto) ? response.data.verifica_rest.aberto : global.restAberto;
        global.temPedidos = (response.data.verifica_rest.tempedido) ? response.data.verifica_rest.tempedido : global.temPedidos;
        global.restEndereco = (response.data.verifica_rest.endereco) ? response.data.verifica_rest.endereco : global.restEndereco;

        global.apidist = (response.data.verifica_rest.apidist) ? response.data.verifica_rest.apidist : global.apidist;

        global.proximaAbertura = (response.data.verifica_rest.proxmsg) ? response.data.verifica_rest.proxmsg : global.proximaAbertura;
        global.horariosFunc = (response.data.verifica_rest.horariosFunc) ? response.data.verifica_rest.horariosFunc : global.horariosFunc;

        global.restPonBal = (response.data.verifica_rest.rest_ponbal) ? response.data.verifica_rest.rest_ponbal : global.restPonBal;
        global.restPonDel = (response.data.verifica_rest.rest_pondel) ? response.data.verifica_rest.rest_pondel : global.restPonDel;

        global.restPerBal = (response.data.verifica_rest.rest_perret) ? response.data.verifica_rest.rest_perret : global.restPerBal;
        global.restPerDel = (response.data.verifica_rest.rest_perdel) ? response.data.verifica_rest.rest_perdel : global.restPerDel;

        global.pedidosPendentes = (response.data.verifica_rest.pedidopendente != undefined) ? response.data.verifica_rest.pedidopendente : global.pedidosPendentes;

        global.cliPerCupom = (response.data.verifica_rest.cli_percupom) ? response.data.verifica_rest.cli_percupom : global.cliPerCupom;
        global.cliPerFidelidade = (response.data.verifica_rest.cli_perfide) ? response.data.verifica_rest.cli_perfide : global.cliPerFidelidade;
        global.restCupAtivo = (response.data.verifica_rest.cupativo) ? response.data.verifica_rest.cupativo : global.restCupAtivo;

        global.pedidoNoCad = (response.data.verifica_rest.ped_no_cad) ? response.data.verifica_rest.ped_no_cad : global.pedidoNoCad;

        if (!global.mostrouDicas) {
          global.mostrouDicas = true;
          setTimeout(() => {
            const cookies = new myCookies();

            this.setState({
              dicaShowed: cookies.get(global.cod_restaurante + '_dicaShowed') || '0',
              cookieAcceptName: global.cod_restaurante + '_myAwesomeCookie',
              alertaPedPend: (!this.alertaExibido) ? (global.pedidosPendentes) : false,
            }, () => {
              this.mostraDicas();
            });
          }, 600);
        } else if (!this.alertaExibido) {
          setTimeout(() => {
            const cookies = new myCookies();

            this.setState({
              alertaPedPend: (global.pedidosPendentes),
            });
          }, 600);
        }

      }

      if (response.data.resposta == "99") {
        return new Promise((resolveAxios, rejectAxios) => {
          setTimeout(() => {
            resolveAxios(Axios(response.config));
          }, 3000);
        });
      } else {
        return new Promise((resolveAxios, rejectAxios) => {
          setTimeout(() => {
            this.setState({ "estadoApp": 1 }, () => {
              setTimeout(async () => {

                var dataArr = this.URLToArray(response.config.data);
                dataArr.partoken = global.myToken;
                response.config.data = this.ArrayToURL(dataArr);

                if (response.config.url == global.siteurl + "renova_jwt.php") {

                  var responseData = response.data;

                  if (responseData.token != null) {

                    console.log(`RENOVA LOGIN - 1.5 - ${responseData.token}`);

                    await Preferences.set({
                      key: `${global.chaveLocalLogin}_token`,
                      value: responseData.token,
                    });
                  }

                  if (responseData.resposta != '1') {
                    localStorage.setItem(global.chaveLocalLogin, '');

                    global.myToken = '';
                  }

                  resolveAxios(response);
                } else if (response.config.url == global.siteurl + "verifica_cep.php") {
                  global.axiosTentativas = 0;
                  document.body.style.overflowY = "scroll";
                  resolveAxios(response);
                } else if (response.data.resposta == "2") {
                  global.tentativaRequestConfig = response.config;
                  if (await renovaJWT()) {
                    var dataArr = this.URLToArray(global.tentativaRequestConfig.data);
                    dataArr.partoken = global.myToken;
                    global.tentativaRequestConfig.data = this.ArrayToURLNoEncode(dataArr);
                    resolveAxios(Axios(global.tentativaRequestConfig));
                  }
                } else {
                  global.axiosTentativas = 0;
                  document.body.style.overflowY = "scroll";
                  resolveAxios(response);
                }

              }, 0);

            });
          }, 300);
        });
      }

    }, (error) => {
      global.axiosTentativas = global.axiosTentativas + 1;

      if (Axios.isCancel(error)) {

        return new Promise((resolveAxios, rejectAxios) => {
          this.setState({ "estadoApp": 1 }, () => {
            setTimeout(() => {
              global.axiosTentativas = 0;
              rejectAxios(error);
            }, 0);
          });
        });

      } else if (global.axiosTentativas < 3) {
        return new Promise((resolveAxios, rejectAxios) => {
          setTimeout(() => {
            const requestConfig = error.config;
            resolveAxios(Axios(requestConfig));
          }, 3000);
        });
      } else {

        if (error.config.url == global.siteurl + "consulta_pedido.php") {
          global.axiosTentativas = 0;
          return Promise.reject(error);
        }

        return new Promise((resolveAxios, rejectAxios) => {
          this.setState({ "estadoApp": 1 }, () => {

            setTimeout(() => {
              global.axiosTentativas = 0;
              rejectAxios(error);
            }, 0);

          });
        });
      }
    });

    if (global.hashRest == '') {
      this.setState({
        notFound: true,
      })
    } else {

      this.carregaDados();
    }

  }

  geraCodPlataforma = () => {
    if (global.plataforma == 'ANDROID') {
      return 1;
    } else if (global.plataforma == 'IOS') {
      return 2;
    } else {
      return 3;
    }
  }

  verificaVersao = () => {

    const cookies = new myCookies();

    var CancelToken = Axios.CancelToken;

    var parametros =
      'parpla=' + this.geraCodPlataforma() +
      "&pargru=" + global.cod_grurest +
      "&parver=" + global.versaoApp;

    let source = CancelToken.source();
    setTimeout(() => {
      source.cancel();

    }, global.timeOutTimer);

    Axios.post(
      global.siteurl + 'versao_webapp.php',
      parametros,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        },
        withCredentials: true,
      }).then((response) => {

        var resposta = response.data;

        if (resposta.resposta == '1') {
          this.setState({
            versaoOk: true,
            appCarregado: true,
            urlAppNewVer: resposta.urlloja
          });
        } else {
          this.setState({
            versaoOk: false,
            appCarregado: true,
            urlAppNewVer: resposta.urlloja
          });
        }

      }).catch((err) => {
        alert(err)
      });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    //window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (window.scrollY < 100) {
      setTimeout(() => {
        window.scrollTo(0, 100);
      }, 300);
    }
  }

  handleScroll = () => {
    if (window.scrollY < 100) {
      window.scrollTo(0, 100);
      setTimeout(() => {
        window.scrollTo(0, 100);
      }, 300);
    }
  }

  htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  mostraDicas = () => {

    const cookies = new myCookies();

    if (global.temPedidos > 0) {

      var randomNb = Math.floor(Math.random() * 10) + 1;

      if (global.pedMesa) {
        return false;
      }

      if (randomNb <= 3) {
        if (this.state.dicaShowed != '1') {

          var now = new Date();
          var time = now.getTime();
          var maxAge = 60 * 60 * 24 * 15;
          var expireTime = time + maxAge;

          var expires = (new Date(expireTime));

          cookies.set(global.cod_restaurante + '_dicaShowed', '1', { path: '/', expires: expires, maxAge: maxAge });
          this.setState({
            dicaShowed: cookies.get(global.cod_restaurante + '_dicaShowed') || '0'
          })


          BS_ALE(this, 'Você sabia?', 'Você pode facilitar seu dia adicionando os produtos de um de seus pedidos já realizados ao seu carrinho, na lista de pedidos escolha o pedido que deseja copiar os produtos e clique em ADICIONAR AO CARRINHO.')
        }
      }
    }
  }

  lePreferenceHash = async () => {

    if (global.plataforma == 'IOS') {
      const { value } = await Preferences.get({ key: `${global.cod_restaurante}_tmp_no_log` });

      if (value) {
        global.intercept_nologin_hash = value;
      }

    }

  }

  carregaDados() {

    const cookies = new myCookies();

    var CancelToken = Axios.CancelToken;

    var parametros = 'parhash=' + global.hashRest;

    let source = CancelToken.source();
    setTimeout(() => {
      source.cancel();

    }, global.timeOutTimer);

    Axios.post(
      'https://sisecomm.com.br/fazseupedido/politica-privacidade/politics.php',
      parametros,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        },
      }).then((response) => {

        try {
          var tmpResposta = response.data;
          var resultado = /<body.*?>([\s\S]*)<\/body>/.exec(tmpResposta)[1];

          global.infoObrig =
            global.politPriv = '<div style="width: 95%; word-wrap: break-word; overflow-wrap: break-word;">' + resultado + '</div>';// ;
        } catch (error) {
          global.politPriv = "";
        }


      });

    /*Axios.post('https://sisecomm.com.br/fazseupedido/politica-privacidade/').then( (response) => {
      global.politPriv = response;
    });*/

    Axios.post(
      global.siteurl + 'loja_pesquisa.php',
      parametros,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        },
        withCredentials: true,
      }).then((response) => {

        var responseData = response.data;
        global.pedidoAtencao = false;

        if (responseData.resposta == '1') {
          var item = responseData.rests[0];

          global.cod_grurest = item.mordor;
          global.nome_rest = item.nome;
          global.cod_restaurante = item.gondor;
          global.exObs = item.exObs;
          global.msg_retirada = item.msgRetirada;
          global.mostraUnidade = item.mostraUnd;
          global.facebookID = item.facebookID;
          global.tel_restaurante = item.telefone;

          global.siteAtual = item.siteURL;

          global.descontoApp = item.descapp;

          global.valMinDel = item.valmin;
          global.valMinRet = item.valminret;

          global.tipoCalcPrecoAdicional = item.tipoAdic;

          global.cupomAtivado = item.cupomAtivado;
          global.programaFidelidade = item.programaFidelidade;

          global.programaFidelidadeFator = item.programaFidelidadeFator;
          global.programaFidelidadeFatorValMin = item.programaFidelidadeFatorValMin;
          global.programaFidelidadeAdicional = item.programaFidelidadeAdicional;

          global.ProductObj = new ProductObj();

          global.chaveLocalLogin = "@vmx-001/" + global.cod_restaurante + "rockstar";

          if (item.infoObrig) {
            var decodedString = this.htmlDecode(item.infoObrig);
            //global.infoObrig = '<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"><style>body { width: 95%; word-wrap: break-word; overflow-wrap: break-word; }</style></head><body>' + decodedString + '</body></html>';// ;
            global.infoObrig = '<div style="width: 95%; word-wrap: break-word; overflow-wrap: break-word;">' + decodedString + '</div>';// ;
            global.infoObrig = global.infoObrig.replace(/<a[^>]*>/g, '<u>').replace(/<\/a>/g, "</u>");
            global.infoObrig = global.infoObrig.replace(/<A[^>]*>/g, '<u>').replace(/<\/A>/g, "</u>");

            global.infoObrigTitulo = item.infoObrigTit;
            global.infoObrigVer = item.infoObrigVer;
          }

          if (item.infoAdic) {
            var decodedString = this.htmlDecode(item.infoAdic);
            //global.infoAdic = '<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"><style>body { width: 95%; word-wrap: break-word; overflow-wrap: break-word; }</style></head><body>' + decodedString + '</body></html>';// ;
            global.infoAdic = '<div style="width: 95%; word-wrap: break-word; overflow-wrap: break-word;">' + decodedString + '</div>';// ;
            global.infoAdic = global.infoAdic.replace(/<a[^>]*>/g, '<u>').replace(/<\/a>/g, "</u>");
            global.infoAdic = global.infoAdic.replace(/<A[^>]*>/g, '<u>').replace(/<\/A>/g, "</u>");

            global.infoAdicTitulo = item.infoAdicTit;
          }

          const favicon = document.getElementById("my-shortcut");
          favicon.href = window.PUBLIC_URL + '/' + item.iconeNome;

          global.pedidosPendentes = responseData.pedidosPendentes;

          var nologin_hash = cookies.get(global.cod_restaurante + '_tmp_no_log');
          nologin_hash = (nologin_hash) ? nologin_hash : '';

          global.intercept_nologin_hash = nologin_hash;

          //BS_ALE(this, "aaaa", nologin_hash);
          if (nologin_hash == '') {
            this.lePreferenceHash();
          }

          this.setState({
            refresh: 1,
            dicaShowed: cookies.get(global.cod_restaurante + '_dicaShowed') || '0',
            cookieAcceptName: global.cod_restaurante + '_myAwesomeCookie',
            alertaPedPend: (responseData.pedidosPendentes),
            appCarregado: false,
          }, () => {
            this.verificaVersao();
          });

        } else {
          this.setState({
            notFound: true,
          })
        }
      }).catch((err) => {

      })
  }

  renderGambiarra() {
    return (
      <input
        variant="outlined"
        margin="normal"
        autoFocus
        required
        fullWidth
        name="cad_email"
        label=""
        type="email"
        id="cad_email"
        autoComplete="email"
        style={{
          height: '1px',
          width: '1px',
          display: (this.state.carregando) ? 'flex' : 'none',
          //display: 'flex',
          fontSize: "16px"
        }}
      />
    )
  }

  renderCookieConsent = () => {
    if (Capacitor.getPlatform() == 'web') {
      return (
        <CookieConsent
          location="bottom"
          buttonText="  OK  "
          cookieName={this.state.cookieAcceptName}
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Para melhorar sua experiência nós usamos cookies essenciais nesse aplicativo, ao continuar navegando, você concorda com essas condições.{" "}
        </CookieConsent>
      );
    }
  }

  renderRotas = () => {

    this.handleScroll();

    var props = {};

    return (
      <div style={{ marginTop: '100px' }}>
        <div id="fb-root"></div>
        {this.renderCarregando()}
        {/*this.renderGambiarra()*/}
        <Routes>
          <Route path="/login-page" element={<LoginPage vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
          <Route path="/carrinho-compra" element={<CarrinhoCompra vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
          <Route path="/selecionaprod-seleciona-sabor" element={<SelecionaProd_selecionaSabor vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
          <Route path="/usuarioinfo-addend" element={<UsuarioInfo_addend vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
          <Route path="/finaliza-compra" element={<FinalizaCompra vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
          <Route path="/pedido-lista" element={<PedidoLista vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
          <Route path="/pedido-status" element={<PedidoStatus vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
          <Route path="/" element={<PrincipalPage vmxale={this.vmxale} navigate={this.props.navigate} location={this.props.location} />} />
        </Routes>

        {this.renderCookieConsent()}

        <AddToHomescreen onAddToHomescreenClick={this.handleAddToHomescreenClick} />

        {this.renderVmxAle()}
        {/*this.renderConfirmacao(hist)*/}
      </div>
    )
  }

  handleClosePedPend = () => {
    this.setState({
      alertaPedPend: false,
    });
  }

  renderConfirmacao = (hist) => {

    return (
      <Confirmacao
        showAlert={this.state.alertaPedPend}
        onClose={() => {
          if (this.state.alertaPedPend) {
            this.alertaExibido = true;
          }
          this.handleClosePedPend();
        }}
        callbackYes={() => {
          if (this.state.alertaPedPend) {
            this.alertaExibido = true;
          }
          this.setState({
            alertaPedPend: false,
          }, () => {
            this.context.history.push('/#/pedido-lista');
          });
        }}
        title={'Atenção'}
        msg={'Você possui pedidos que precisam de sua atenção\n\nDeseja verificar agora?'}
        callbackNo={() => {
          if (this.state.alertaPedPend) {
            this.alertaExibido = true;
          }
          this.handleClosePedPend();
        }}
      />
    );
  }

  renderVmxAle = () => {
    if (this.state.mostraVmxAle) {
      return (
        <Dialog
          open={this.state.mostraVmxAle}
          onClose={() => {
            this.setState({
              mostraVmxAle: false,
              textVmxAle: '',
              titleVmxAle: '',
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ display: 'flex', flexDirection: 'column' }}>
              <InfoOutlined style={{ margin: 'auto', marginBottom: '10px', fontSize: '40px', color: "#03a1fc", }} />
              <text style={{ margin: 'auto', marginBottom: '10px', fontSize: '20px', fontWeight: '500' }}>Informações</text>
              <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>{this.state.textVmxAle}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex' }}>
            <Button color="primary" style={{ backgroundColor: '#03a1fc', color: '#fff', margin: 'auto', height: '45px', marginBottom: '10px' }} onClick={() => {
              this.setState({
                mostraVmxAle: false,
                textVmxAle: '',
                titleVmxAle: '',
              }, () => {
                if (this.state.callbackVmxAle) {
                  if (typeof this.state.callbackVmxAle === 'function') {
                    this.state.callbackVmxAle();
                  }
                }
              });
            }}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  renderCarregando() {
    if (this.state.estadoApp == 0) {
      return (
        <div className={'myLoadingGIF'} style={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#fff' }}>
          <img style={{ margin: 'auto', width: '95%', maxWidth: '400px' }} src={load} alt="loading..." />
        </div>
      );
    }
  }

  renderCarregandoNoMatterWhat() {
    return (
      <div className={'myLoadingGIF'} style={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#fff' }}>
        {/*this.renderGambiarra()*/}
        <img style={{ margin: 'auto', width: '95%', maxWidth: '400px' }} src={load} alt="loading..." />
        {this.renderVmxAle()}
      </div>
    );
  }

  renderMenuPickEsfiha = () => {

    this.handleScroll();

    var lojas = [{
      imgLoja: '3',
      lojaHash: 'jundiaiesfihas'
    }, {
      imgLoja: '4',
      lojaHash: 'itupevaesfihas'
    },];

    return (
      <div style={{ offset: '100px', overscrollBehaviorY: 'contain', marginTop: '100px' }} >
        <div style={classes.container}>
          <Card>
            <div style={{ margin: "0", height: '100vh' }}>
              <CardHeader style={{ padding: '0px', margin: '0 0 2vh 0', }}>
                <div style={{ height: '8vh', padding: '12px', position: 'relative', background: 'rgb(255,205,0)', background: 'linear-gradient(0deg, rgba(255,205,0,1) 0%, rgba(255,162,0,1) 100%)' }}>
                  <img src={global.siteurl + "rests/" + global.cod_restaurante + "/imgs/rest/path.php?t=restimg"} style={{ height: '10vh', margin: '0 8px', position: 'absolute', left: '0px', right: '0px', marginRight: 'auto', marginLeft: 'auto' }}></img>
                </div>
              </CardHeader>
              <CardBody style={{ padding: '5vh 5vh 5vh 5vh', display: 'flex', flexDirection: 'column' }}>

                <div style={{ height: '12vh', paddingBottom: '1vh', position: 'relative' }}>
                  <div style={{ margin: 'auto', textAlign: 'center', color: '#fc9003', fontSize: '18px', fontWeight: '500' }}>
                    Escolha a loja para fazer seu pedido
                  </div>
                </div>

                {lojas.map((value, index) => {
                  return (
                    <div onClick={() => {
                      //hist.push('./?rest=' + value.lojaHash);
                      setTimeout(() => {
                        window.location.reload();// 
                      });
                    }} style={{ height: '22vh', paddingBottom: '1vh', position: 'relative' }}>
                      <img ref={(ref) => { this.cabecalho = ref }} src={global.siteurl + "rests/" + value.imgLoja + "/imgs/rest/path.php?t=logo"} style={{ opacity: '1', width: '100%', height: "100%", objectFit: 'contain' }}></img>
                    </div>
                  );
                })}

              </CardBody>

            </div>
          </Card>
        </div>
      </div>
    );
  }

  renderNotFound = () => {
    return (
      <div class="container" style={{ margin: '85px auto', width: '300px' }}>
        <div class="row">
          <div class="col-md-12">
            <div class="error-template">
              <h1>
                Oops!</h1>
              <h2>
                404 Página não encontrada</h2>
              <div class="error-details">
                Desculpe, a página que você solicitou não está aqui, avise-nos e iremos encontrá-la pra você.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  recarregaUrlAtual = () => {

    var now = new Date();
    var strDate = `${now.getFullYear()} ${now.getMonth()} ${now.getDate()} ${now.getHours()}`;
    var strUnica = strDate + Math.floor(Math.random() * 10000000);

    var f = document.getElementById('Form');
    f.setAttribute('action', window.location.href);
    f.key.value = strUnica;
    f.submit();

  }

  renderLinkNewVersion = () => {
    if (global.plataforma == 'Web') {
      return (
        <div style={{ marginTop: '20px', padding: '15px 50px' }}>
          <div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: '23px' }}>
            {"Olá!\nUma nova versão do webapp está disponível."}
          </div>
          <div onClick={() => {

            //window.open(window.location.href);
            this.recarregaUrlAtual();

          }} class="error-details" style={{ textAlign: 'center', width: '100%', fontSize: '20px', marginTop: '50px' }}>
            <a>Clique Aqui <text style={{ color: '#000' }}>para atualizar a versão do webapp.</text></a>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: '20px', padding: '15px 50px' }}>
          <div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: '23px' }}>
            {"Olá!\nUma nova versão do app está disponível."}
          </div>
          <div onClick={() => { window.open(this.state.urlAppNewVer); }} class="error-details" style={{ textAlign: 'center', width: '100%', fontSize: '20px', marginTop: '50px' }}>
            <a>Clique Aqui <text style={{ color: '#000' }}>para baixar a nova versão e continuar fazendo seus pedidos.</text></a>
          </div>
        </div>
      );
    }
  }

  renderAppUpdate = () => {

    return (
      <div class="myBody" style={{ offset: '100px', position: 'absolute', top: '0', bottom: '0', height: '100%', width: '100%', backgroundColor: "#fff" }} >
        <div class="container">
          <div class="row" style={{ margin: '0px auto', width: '100%', maxWidth: '600px', }}>
            <div class="col-md-12">
              <div class="form">
                <img ref={(ref) => { this.cabecalho = ref }} src={global.siteurl + "rests/" + global.cod_restaurante + "/imgs/rest/path.php?t=logo"} style={{ width: '100%', height: "100%", objectFit: 'contain' }}></img>
                {this.renderLinkNewVersion()}
              </div>
            </div>
          </div>
        </div>

        <form id="Form" method="post">
          <input type="hidden" name="key" value="" />
        </form>
      </div>

    );
  }

  render() {

    if (this.state.notFound) {
      return this.renderNotFound();
    }

    if (this.state.pickingPlace) {
      return this.renderMenuPickEsfiha();
    }

    if (this.state.appCarregado) {
      if (this.state.versaoOk === true) {
        return (this.renderRotas());
      } else {
        return (this.renderAppUpdate())
      }
    } else {
      return (this.renderCarregandoNoMatterWhat());
    }



    if (this.state.estadoApp == 0) {
      return (this.renderCarregando());
    } else {
      return (this.renderRotas());
    }

  }
}
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <IndexPage navigate={navigate} location={location} />
  )
}

//serviceWorkerRegistration.register();
