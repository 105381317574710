import React, { useState, useEffect } from "react";

import classNames from "classnames";
// @mui/material components
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// @mui/icons-material
import Email from "@mui/icons-material/Email";
import People from "@mui/icons-material/People";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { Capacitor } from '@capacitor/core';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Skeleton from '@mui/material/Skeleton';

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import "assets/css/CarrinhoCompra.css";

import image from "assets/img/bg7.jpg";
import { Message } from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";

import 'prevent-pull-refresh';

import axios from 'axios';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import WorkIcon from '@mui/icons-material/Work';
import CheckBoxIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DeleteIcon from '@mui/icons-material/Delete';
import MapIcon from '@mui/icons-material/Map';
import CheckIcon from '@mui/icons-material/Check';
import PersonAdd from '@mui/icons-material/PersonAdd';
import VpnKey from '@mui/icons-material/VpnKey';
import AppleIcon from '@mui/icons-material/Apple';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import MaskedInput from 'react-text-mask'
import { IconButton, FormControlLabel } from "@mui/material";

import { renovaJWT, BS_ALE, removeEComercial, validaCpfCnpj } from 'funcs/MyFuncs.js';

import { Preferences } from '@capacitor/preferences';

//import FacebookLogin from '@greatsumini/react-facebook-login';

import Confirmacao from "components/Dialogs/Confirmacao.js";
import { myEncodeURI } from "funcs/MyFuncs";

import stylesAppBar from "assets/jss/politica-privacidade.js";
import { Dialog, DialogContent, } from "@mui/material";
import AppBar from "@mui/material/AppBar";

import { useNavigate } from "react-router-dom";

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';

//import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook';
import { FacebookLogin } from '@capacitor-community/facebook-login';

import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

const delayProcessando = 0;
const tempoMinProcess = 0;

const tp_estado_criaconta = 0;
const tp_estado_recuperasenha = 1;

const tp_cadcli_email = 0;
const tp_cadcli_codigo = 1;
const tp_cadcli_senha = 2;
const tp_cadcli_final = 3;

var newLogin = false;

const corCheckBoxRemove = '#980000';
const corCheckBoxAdiciona = '#777';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const tp_programa_naoautorizado = 0;
const tp_programa_confautoriza = 1;
const tp_programa_autorizado = 2;


const AdicionaCheckbox = withStyles(
  ((props) => <Checkbox color="default" {...props} />),
  (theme, props) => ({
    root: {
      color: corCheckBoxAdiciona,
      '&$checked': {
        color: corCheckBoxAdiciona,
      },
      [`& label`]: {
        color: corCheckBoxAdiciona,
      },
    },
    checked: {},
  })
);

function TelefoneMaskInput(props) {
  const { ref, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref ? ref.inputElement : null}
      mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

function CPFMaskInput(props) {
  const { ref, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref ? ref.inputElement : null}
      mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
    />
  );
}

class LoginPage extends React.Component {

  btnAddEnd = null;

  arrPosItems = [];
  arrMenuItems = [];
  cardBody = null;
  myMenu = null;
  cabecalho = null;

  ultElementMenu = -1;

  cursorPassCad = 0;
  cursorPassCadConf = 0;
  cursorPassLog = 0;

  facebookIniciado = false;

  async getStatus() {
    const response = await AppTrackingTransparency.getStatus();

    console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

  async requestPermission() {

    console.log('aaaaaaaaaaaa');
    const response = await AppTrackingTransparency.requestPermission();

    console.log(response);
    // { status: 'authorized' } for example

    return response;

  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  verificaPermissaoIOS = async () => {

    const FACEBOOK_PERMISSIONS = [
      'email',
      'public_profile',
    ];

    var status = await this.getStatus();

    if (status.status == 'authorized') {
      this.fazLoginFacebook();
    } else {
      status = await this.requestPermission();

      console.log('AAAAAAZZZZZ');

      if (status.status == "authorized") {

        setTimeout(() => {
          console.log('LOGIN DO FACEBOOK');
          this.fazLoginFacebook();
        }, 1000)

        //BS_ALE(this,  'Atenção', 'Seu login do Facebook continuará agora.', () => {
        //    console.log('LOGIN DO FACEBOOK');
        //    this.fazLoginFacebook();
        //},{cancelable: false},);  

      } else if (status.status == "denied") {
        BS_ALE(this, 'Atenção', 'Para usar o Login do Facebook, você precisa aceitar o rastreamento dos seus dados. Caso você queira habilitar o rastreamento você pode fazer pela configuração do seu Celular.', () => {
          console.log('open settings ios');
          NativeSettings.openIOS({
            option: IOSSettings.App
          });
        }, { cancelable: false },);
      }
    }



  }

  fazLoginFacebook = async () => {

    const FACEBOOK_PERMISSIONS = [
      'email',
      'public_profile',
    ];

    if (this.facebookIniciado != false) {
      await FacebookLogin.initialize({ appId: global.facebookID });
    }

    if (global.plataforma != 'Web') {
      console.log("AAAAZZZZ - LOG FACE - NOT WEB");

      try {
        var fbStatus = await FacebookLogin.getCurrentAccessToken();

        if (fbStatus.accessToken) {
          await FacebookLogin.logout();
        }
      } catch (error) {

      }
    }

    console.log("AAAAZZZZ - LOG FACE - ENTRANDO");
    FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
      .then((result) => {

        console.log("AAAAZZZZ - LOG FACE - ENTROU");
        if (result.accessToken) {
          // Login successful.
          console.log(`Facebook access token is ${result.accessToken.token}`);
          this.responseFacebook(result);
        }

      }).catch(e => {
        console.log(e);
      })

  }

  responseFacebook = (response) => {


    try {
      // here place your login logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "&parfbtoken=" + response.accessToken.token +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      console.log(`Facebook parameter is ${parametros}`);

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'cliente_facebook_login.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then(async (response) => {
          var responseData = response.data;

          if (responseData.token != null) {

            console.log(`LOGIN FACEBOOK - 1.5 - ${responseData.token}`);

            await Preferences.set({
              key: `${global.chaveLocalLogin}_token`,
              value: responseData.token,
            });
          }

          if (responseData.resposta == "0") {
            //BS_ALE(this,  'Atenção', responseData.msg, [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
          } else {

            if (responseData.novouser == "1") {
              localStorage.setItem(global.chaveLocalLogin, responseData.jwt);

              global.myToken = responseData.jwt;
              this.setState({
                refresh: true,
                myToken: responseData.jwt,
                log_senha: '',
                log_usu: '',
                log_cpf: '',
              }, () => {
                this.carregaDados();
              });
            } else {

              this.setState({
                confLogFacebook_alerta: true,
                confLogFacebook_jwt: responseData.jwt,
                confLogFacebook_nome: responseData.nome,
              }, () => {

              });
            }

          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });

    } catch (err) {

    }
  }

  constructor(props) {
    super(props);

    let localCart = global.ProductObj.getCarrinhoCompra();
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (!localCart) {
      localCart = [];
    }

    this.state = {
      carrinhoCompra: localCart,
      estadoLogin: [0],
      cad_nome: '',
      cad_cpf: '',
      cad_senha: '',
      cad_senha_conf: '',
      cad_celular: '',
      cad_email: '',
      cad_codconf: '',
      log_senha: '',
      log_usu: '',
      log_cpf: '',

      cli_nome: '',
      cli_tipo: '',
      cli_tele: '',
      cli_email: '',
      cli_cpf: '',
      cli_enderecos: [],
      cli_cupons: [],
      cli_pontos: 0,
      cli_vericod: '',
      myToken: '',
      loading: true,

      cli_nome_edit: '',
      cli_cpf_edit: '',
      cli_tele_edit: '',

      atualizaCad: false,

      alertaConfExc: false,

      alertaConfAddEnd: false,

      showPasswordLog: false,
      showPasswordCad: false,
      showPasswordCadConf: false,

      confLogFacebook_alerta: false,
      confLogFacebook_jwt: '',
      confLogFacebook_nome: '',

      alertaDeslogaUser: false,

      politPrivTitulo: 'Política de Privacidade',
      politPriv: '',
      politPrivOpen: '',

      // INI - CUPOM DE DESCONTO

      cupomDescState: tp_programa_naoautorizado,

      cupomDescAceito: 0,
      fidelidadeAceito: 0,

      alertaConfSairProgDesc: false,

      // FIM - CUPOM DE DESCONTO

      // INI - PROGRAMA DE FIDELIDADE

      fidelidadeDescState: tp_programa_naoautorizado,

      fidelidadeDescAceito: 0,

      alertaConfSairProgFide: false,

      alertaExtratoPontos: false,

      // FIM - CUPOM DE DESCONTO

      alertaConfExcConta: false,
      extratoPontos: [],
    };

    this.carregaDados = this.carregaDados.bind(this);

  }

  componentDidMount() {

    const { navigation } = this.props;

    let localCart = global.ProductObj.getCarrinhoCompra();
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (!localCart) {
      localCart = [];
    }

    let meuToken = localStorage.getItem(global.chaveLocalLogin);
    meuToken = meuToken;

    global.myToken = meuToken;
    this.setState({
      myToken: meuToken,
      carrinhoCompra: localCart,
      estadoLogin: [0]
    }, () => {

      this.iniciaFacebook();
      this.carregaDados();

    });
  }

  componentWillUnmount = () => {

  }

  iniciaFacebook = async () => {
    await FacebookLogin.initialize({ appId: global.facebookID });
    this.facebookIniciado = true;
  }

  carregaDados = async () => {

    if (global.myToken) {

      try {
        // here place your login logic     

        var CancelToken = axios.CancelToken;

        const { log_senha, log_usu, log_cpf } = this.state;

        var parametros =
          "&partoken=" + global.myToken +
          "&pargrures=" + global.cod_grurest +
          "&parres=" + global.cod_restaurante +
          "&parverapp=" + '99';

        let source = CancelToken.source();
        setTimeout(() => {
          source.cancel();
        }, global.timeOutTimer);


        axios.post(
          global.siteurl + 'cliente_info.php',
          parametros,
          {
            cancelToken: source.token,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            },
            withCredentials: true,
          }).then((response) => {
            var responseData = response.data;

            if (responseData.cliPermCupom) {
              global.cliPerCupom = responseData.cliPermCupom;
            }

            if (responseData.resposta == "2") {
            } else if (responseData.resposta == "0") {
              global.myToken = "";
              this.setState({
                myToken: "",
                loading: false,
              });
            } else if (responseData.resposta == "77") {
              global.myToken = "";
              this.setState({
                myToken: "",
                loading: false,
              });
            } else {
              //alert( JSON.stringify(responseData));

              this.setState({
                cli_nome: responseData.nome,
                cli_tele: responseData.telefone,
                cli_tipo: responseData.tipo,
                cli_vericod: '',
                cli_email: responseData.email,
                cli_cpf: responseData.cpf,
                cli_enderecos: responseData.enderecos,
                cli_pontos: responseData.pontos,

                cli_cupons: responseData.cupons,

                cli_tele_edit: responseData.telefone,
                cli_nome_edit: responseData.nome,
                cli_cpf_edit: ((responseData.cpf ?? '') != '') ? responseData.cpf : (this.state.log_cpf_digit ?? ''),

                cupomDescState: (global.cliPerCupom == '1') ? tp_programa_autorizado : tp_programa_naoautorizado,
                fidelidadeDescState: (global.cliPerFidelidade == '1') ? tp_programa_autorizado : tp_programa_naoautorizado,

                loading: false,


              }, () => {



                var temEndereco = false;

                for (var i = 0; i < responseData.enderecos.length; i++) {
                  var tmpEnd = responseData.enderecos[i];

                  if (tmpEnd.identificacao != "RETIRADA") {
                    temEndereco = true;
                  }
                }

                if (this.newLogin) {
                  if (
                    (this.state.cli_nome != '') &&
                    (this.state.cli_tele != '') &&
                    (this.state.cli_email != '') &&
                    (this.state.cli_cpf != '')
                  ) {
                    newLogin = false;
                    BS_ALE(this, "Bem-vindo", "Login realizado com sucesso. \n\nBoas compras !!! ");
                    this.props.navigate('/');
                  }
                }
                //window.scrollBy(0, -30); 
              });
            }
          })
          .catch((error) => {

            if (axios.isCancel(error)) {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
            else {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
          });

      } catch (err) {

      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  updateDimensions() {
    var alturaScroll = document.body.scrollHeight - window.innerHeight;
    var scrolledY = window.scrollY;

    var alturaItems = 0;

    this.arrPosItems.map((item, index) => {
      alturaItems += (item) ? item.clientHeight : 0;
    });

    var proporcao = alturaItems / (alturaScroll);

    var tmpPos = 0;
    var itemAtual = 0;

    for (var i = 0; i < this.arrPosItems.length; i++) {
      var tmpItem = this.arrPosItems[i];
      tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

      if ((scrolledY * proporcao) < (((this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) - this.state.scrollMarginTop)) {
        break;
      } else {
        itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
      }
    }

    if (this.state.posItemClicado == -1) {
      if (itemAtual !== this.state.itemAtivo) {
        this.setState({ itemAtivo: itemAtual });

        if (itemAtual > 0) {
          this.myMenu.scrollTo({ left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth' })
        } else {
          this.myMenu.scrollTo({ left: 0, behavior: 'smooth' })
        }
      }
    } else if (scrolledY == this.state.posItemClicado) {

      if (this.state.eventClicado == -1) {
        if (itemAtual !== this.state.itemAtivo) {
          this.setState({ itemAtivo: itemAtual });
        }

        if (itemAtual > 0) {
          this.myMenu.scrollTo({ left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth' })
        } else {
          this.myMenu.scrollTo({ left: 0, behavior: 'smooth' })
        }
        this.setState({
          posItemClicado: -1,
        });
      } else {
        this.myMenu.scrollTo({ left: this.arrMenuItems[this.state.eventClicado].offsetLeft - 30, behavior: 'smooth' })
        this.setState({
          itemAtivo: this.state.eventClicado,
          posItemClicado: -1,
          eventClicado: -1,
        });
      }
    } else {
      if (itemAtual !== this.state.itemAtivo) {
        this.setState({ itemAtivo: itemAtual });
      }
    }


  }

  atualizaPadraoDB = async (parametros, metodo, tabelaNome, arrLista) => {

    try {

      var CancelToken = axios.CancelToken;

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, 20000);


      await axios.post(
        global.urlServer + metodo,
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta == '1') {
            arrLista = JSON.parse(JSON.stringify(responseData.registros));

            var arrFinal = [];

            if (arrLista) {
              arrLista.map((prop, key) => {
                arrFinal.push({
                  key: key,
                  codInterno: prop.idGrupo,
                  nome: prop.nome,
                  precoDelivery: prop.posicao,
                  descricao: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                  image: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                });
              });
            }

            this.setState({
              listaGruPro: arrFinal,
            });
          } else {
            this.setState({
              erroDB: true,
            });
          }
        })
        .catch((error) => {
          if (error == '785936') {

          } else if (axios.isCancel(error)) {

          } else {


          }
        });
    } catch (err) {

    }
  }

  clickProd(item) {
    alert('Hello: ' + item.nome);
  }

  scrollToItem = (index) => {
    //alert('Hello: ' + index);
    //this.arrPosItems[index].scrollIntoView({behavior: 'smooth'});

    var offsetNextScroll = 80;

    var alturaScroll = document.body.scrollHeight - window.innerHeight - this.state.scrollMarginTop;
    var scrolledY = window.scrollY;

    var alturaItems = 0;

    this.arrPosItems.map((item, index) => {
      alturaItems += (item) ? item.clientHeight : 0;
    });

    var proporcao = (alturaItems) / alturaScroll;

    var posicaoItem = 0;

    this.arrPosItems.map((item, itemIndex) => {
      if (itemIndex == index) {
        posicaoItem = (item) ? ((item.offsetTop + this.cardBody.offsetTop) - this.state.scrollMarginTop) : 0;
      }
    });

    /*for (var i = 0; i < (index); i++){
        var tmpItem = this.arrPosItems[i];
        posicaoItem += (tmpItem) ? tmpItem.clientHeight : 0;
    }

    posicaoItem -= this.state.scrollMarginTop;
    posicaoItem += this.cabecalho.clientHeight;
    posicaoItem = posicaoItem / proporcao;
    posicaoItem += offsetNextScroll;
    posicaoItem = Math.round(posicaoItem);*/

    window.scrollTo({ top: posicaoItem, behavior: 'smooth' });

    this.setState({
      posItemClicado: posicaoItem,
      eventClicado: index,
    });

  }

  renderProds = (prodArrFinal) => {
    const { classes } = this.props;

    return prodArrFinal.map((prod, index) => {
      return (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
            <div>
              <div>
                <text style={{ fontSize: '16px', fontWeight: 'bold' }}>{prod.nomeProduto}</text>
              </div>
              <div>
                <text style={{ display: 'inline-block', fontSize: '13px', lineHeight: '18px' }}>{prod.descricao}</text>
              </div>
              <div>
                <text style={{ fontSize: '16px', fontWeight: 'bold' }}>R$ {prod.preco.replace('.', ',')}</text>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '10px' }} className={classes.separator}></div>
        </div>
      );
    });
  }

  renderLista = (items) => {
    const { classes } = this.props;
    var resultado = null;

    return items.map((item, index) => {

      return (
        <div ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px' }}>
          <div style={{ width: '100%', }}>
            <text style={{ marginRight: '30px', fontSize: '22px', color: '#a30000', fontWeight: 'bold' }} >{item.qtde}x {item.nome} - {item.valorTotal}</text>
          </div>
        </div>
      );

    });
  }

  pesquisaLista = (value) => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    this.setState({ pesquisaTxt: value }, () => {
      this.updateDimensions();
    });
  }

  abrePesquisa = (pesquisaAberta) => {
    if (pesquisaAberta) {
      this.setState({
        scrollMarginTop: 125
      });
    } else {
      this.setState({
        scrollMarginTop: 75
      });
    }
  }

  comparaTexto(val1, val2) {

    var arrPalavras = val2.split(' ');

    var resultado = true;
    arrPalavras.map((palavra, index) => {
      if (!this.trataTexto(val1).includes(this.trataTexto(palavra))) {
        resultado = false;
      }
    });

    return resultado;
  }

  trataTexto(val) {
    return (
      val.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    );
  }

  handleLoad() {
    let localCart = global.ProductObj.getCarrinhoCompra();
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (!localCart) {
      localCart = [];
    }

    this.setState({
      carrinhoCompra: localCart,
    });
  }

  logaUsu = () => {
    try {
      // here place your login logic     

      var CancelToken = axios.CancelToken;

      const { log_senha, log_usu, log_cpf } = this.state;

      var parametros =
        "&parusu=" + (log_usu) +
        "&parsenha=" + (log_senha) +
        "&parcpf=" + (log_cpf) +
        "&parres=" + global.cod_restaurante +
        "&pargrures=" + global.cod_grurest +
        "&parverapp=" + '99';

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'cliente_login.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then(async (response) => {
          var responseData = response.data;
          if (responseData.resposta == "1") {
            localStorage.setItem(global.chaveLocalLogin, responseData.jwt);

            global.myToken = responseData.jwt;

            this.newLogin = true;

            console.log(`LOGIN - 1 - ${responseData.token}`);

            if (responseData.token != null) {

              console.log(`LOGIN - 1.5 - ${responseData.token}`);

              await Preferences.set({
                key: `${global.chaveLocalLogin}_token`,
                value: responseData.token,
              });
            }

            this.setState({
              refresh: true,
              myToken: responseData.jwt,

              log_cpf_digit: this.state.log_cpf,

              log_senha: '',
              log_usu: '',
              log_cpf: '',
            }, () => {
              this.carregaDados();
            });
          } else {
            BS_ALE(this, 'Atenção', responseData.msg);
          }

        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            alert(JSON.stringify(error));
            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });

    } catch (err) {

    }
  }

  renderLinksBottom_reenviarCodigo() {
    var resultado = (
      <Grid container style={{ marginTop: '15px' }}>
        <Grid item xs>
          <Link onClick={() => {
            this.reenviaCod();
          }} variant="body2">
            {"Reenviar código"}
          </Link>
        </Grid>
      </Grid>
    );

    if (this.state.cli_cadestado == tp_cadcli_codigo) return resultado;
  }

  renderLinksBottom_esqueceuSenha() {
    var resultado = (
      <Grid container style={{ marginTop: '15px' }}>
        <Grid item xs>
          <Link onClick={() => {
            let arrTmp = this.state.estadoLogin;
            arrTmp.push(1);
            this.setState({
              estadoLogin: arrTmp,
              operacao: tp_estado_recuperasenha,
              cli_cadestado: tp_cadcli_email,

              cad_nome: '',
              cad_cpf: '',
              cad_senha: '',
              cad_senha_conf: '',
              cad_celular: '',
              cad_email: '',
              cad_codconf: '',
              log_senha: '',
              log_usu: '',
              log_cpf: '',

              cli_nome: '',
              cli_tipo: '',
              cli_tele: '',
              cli_email: '',
              cli_cpf: '',
              cli_enderecos: [],
              cli_cupons: [],
              cli_pontos: 0,
              cli_vericod: '',
            });
          }} variant="body2">
            Esqueceu sua senha?
          </Link>
        </Grid>
      </Grid>
    );

    var estadoLogin = this.state.estadoLogin[this.state.estadoLogin.length - 1];

    if (estadoLogin == 0) return resultado;
    //if ( this.state.operacao != tp_estado_recuperasenha ) return resultado;

    if ((this.state.operacao != tp_estado_recuperasenha) &&
      (this.state.cli_cadestado <= tp_cadcli_email))
      return resultado;
  }

  renderLinksBottom_cadastrar() {
    var resultado = (
      <Grid container style={{ marginTop: '15px' }}>
        <Grid item>
          <Link onClick={() => {
            let arrTmp = this.state.estadoLogin;
            arrTmp.push(1);
            this.setState({
              estadoLogin: arrTmp,
              operacao: tp_estado_criaconta,
              cli_cadestado: tp_cadcli_email,
              cad_nome: '',
              cad_cpf: '',
              cad_senha: '',
              cad_senha_conf: '',
              cad_celular: '',
              cad_email: '',
              cad_codconf: '',
              log_senha: '',
              log_usu: '',
              log_cpf: '',

              cli_nome: '',
              cli_tipo: '',
              cli_tele: '',
              cli_email: '',
              cli_cpf: '',
              cli_enderecos: [],
              cli_cupons: [],
              cli_pontos: 0,
              cli_vericod: '',
            });
          }} variant="body2">
            {"Não tem uma conta? Cadastre-se"}
          </Link>
        </Grid>
      </Grid>
    );

    var estadoLogin = this.state.estadoLogin[this.state.estadoLogin.length - 1];

    if (estadoLogin == 0) return resultado;
    if (this.state.operacao == tp_estado_recuperasenha) return resultado;
  }

  renderLinksBottom_logar() {
    var resultado = (
      <Grid container style={{ marginTop: '15px' }}>
        <Grid item>
          <Link onClick={() => {
            let arrTmp = this.state.estadoLogin;
            arrTmp.push(0);
            this.setState({
              estadoLogin: arrTmp,

              cad_nome: '',
              cad_cpf: '',
              cad_senha: '',
              cad_senha_conf: '',
              cad_celular: '',
              cad_email: '',
              cad_codconf: '',
              log_senha: '',
              log_usu: '',
              log_cpf: '',

              cli_nome: '',
              cli_tipo: '',
              cli_tele: '',
              cli_email: '',
              cli_cpf: '',
              cli_enderecos: [],
              cli_cupons: [],
              cli_pontos: 0,
              cli_vericod: '',
            });
          }} variant="body2">
            {"Já tem uma conta? Faça login"}
          </Link>
        </Grid>
      </Grid>
    );

    var estadoLogin = this.state.estadoLogin[this.state.estadoLogin.length - 1];

    if ((estadoLogin != 0) &&
      (this.state.cli_cadestado <= tp_cadcli_email))
      return resultado;
  }

  renderLinksBottom() {
    return (
      <Grid>

        {this.renderLinksBottom_reenviarCodigo()}
        {this.renderLinksBottom_esqueceuSenha()}
        {this.renderLinksBottom_cadastrar()}
        {this.renderLinksBottom_logar()}

      </Grid>
    );
  }

  mostraSenhaCad = (e) => {
    e.preventDefault();
    this.setState({
      showPasswordCad: !this.state.showPasswordCad
    });
  }

  mostraSenhaCadConf = (e) => {
    e.preventDefault();
    this.setState({
      showPasswordCadConf: !this.state.showPasswordCadConf
    });
  }

  mostraSenhaLog = () => {

  }

  mostraSenhaLogDown = (e) => {

    this.setState({
      showPasswordLog: !this.state.showPasswordLog
    });

    e.preventDefault();
  }

  responseApple = (id, nome, email, tipo = '2') => {

    try {
      // here place your login logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "partipo=" + tipo +
        "&parverapp=" + global.versaoApp +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parusu=" + id +
        "&parsenha=" + id +
        "&parnumcel=" + '' +
        "&parcpf=" + '' +
        "&paremail=" + email +
        "&parnome=" + nome;

      console.log(`APPLE LOGIN - ${parametros}`);

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'cliente_apple_login.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then(async (response) => {
          var responseData = response.data;

          if (responseData.token != null) {

            console.log(`LOGIN - 1.5 - ${responseData.token}`);

            await Preferences.set({
              key: `${global.chaveLocalLogin}_token`,
              value: responseData.token,
            });
          }

          if (responseData.resposta == "0") {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {

            if (responseData.novouser == "1") {
              localStorage.setItem(global.chaveLocalLogin, responseData.jwt);

              global.myToken = responseData.jwt;
              this.setState({
                refresh: true,
                myToken: responseData.jwt,
                log_senha: '',
                log_usu: '',
                log_cpf: '',
              }, () => this.carregaDados());
            } else {


              this.setState({
                confLogFacebook_alerta: true,
                confLogFacebook_jwt: responseData.jwt,
                confLogFacebook_nome: responseData.nome,
              });
            }

          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });

    } catch (err) {

    }
  }

  fazLoginApple = () => {
    /*window.cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        (appleAuthRequestResponse) => {
            this.responseApple(appleAuthRequestResponse.user, (appleAuthRequestResponse.fullName.givenName != null) ? appleAuthRequestResponse.fullName.givenName + ' ' + appleAuthRequestResponse.fullName.familyName : '!0000!', appleAuthRequestResponse.email, '3')
        },
        function(err){
          console.error(err);
        }
    )*/

    let options = {
      scopes: 'email name',
    };

    SignInWithApple.authorize(options)
      .then((appleAuthRequestResponse) => {
        console.error(`SUCESSO LOGIN APPLE - ${appleAuthRequestResponse.response.user}`);
        this.responseApple(appleAuthRequestResponse.response.user, (appleAuthRequestResponse.response.givenName != null) ? appleAuthRequestResponse.response.givenName + ' ' + appleAuthRequestResponse.response.familyName : '!0000!', appleAuthRequestResponse.response.email, '3')
      })
      .catch(error => {
        console.error(`ERR LOGIN APPLE - ${error}`);
      });

  }

  renderAppleLogin = () => {
    const { classes } = this.props;

    if (global.plataforma == 'IOS') {
      return (
        <Button
          fullWidth
          variant="contained"
          onClick={() => this.fazLoginApple()}
          className={classes.submit}
          style={{ backgroundColor: '#000', }}
        >
          <AppleIcon style={{ marginBottom: 'auto' }} />Sign in with Apple
        </Button>
      );
    }
  }

  renderFacebookLogin = () => {

    const { classes } = this.props;

    var teste = global.facebookID;
    var teste2 = teste;

    if (global.plataforma == 'Web') {
      /*return (
          <FacebookLogin
              appId={global.facebookID}
              autoLoad={false}
              fields="name,email"
              onClick={() => {

              }}
              isMobile={false}
              callback={this.responseFacebook} 
              cssClass={'MuiButtonBase-root MuiButton-root MuiButton-contained ' + classes.facebookBtn}
              styles={{backgroundColor: '#f0f'}}
              disableMobileRedirect={false}
              textButton={"Continuar com Facebook"}/>
      );*/
      return <Button
        fullWidth
        variant="contained"
        onClick={() => this.fazLoginFacebook()}
        className={'MuiButtonBase-root MuiButton-root MuiButton-contained ' + classes.facebookBtn + ' ' + classes.submit}
        styles={{ backgroundColor: '#f0f' }}
      >
        Continuar com Facebook
      </Button>
    } else {
      return (
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            if (global.plataforma == 'IOS') {
              this.verificaPermissaoIOS();
            } else {
              this.fazLoginFacebook();
            }
          }}
          className={'MuiButtonBase-root MuiButton-root MuiButton-contained ' + classes.facebookBtn + ' ' + classes.submit}
          styles={{ backgroundColor: '#f0f' }}
        >
          Continuar com Facebook
        </Button>
      );
    }
  }

  renderLogin() {

    const { classes } = this.props;

    const { showPasswordLog } = this.state;

    //!@#!@#

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                type="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="log_usu"
                label="Email"
                name="log_usu"
                autoComplete="email"
                autoFocus
                value={this.state.log_usu}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="log_cpf"
                label="CPF"
                type="text"
                id="log_cpf"
                value={this.state.log_cpf}
                autoComplete="text"
                InputProps={{
                  inputComponent: CPFMaskInput,
                  value: this.state.log_cpf,
                  onChange: this.handleChange,
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="log_senha"
                label="Senha"
                id="log_senha"
                autoComplete="current-password"
                value={this.state.log_senha}
                onChange={this.handleChange}
                type={showPasswordLog ? "text" : "password"}
                autoCorrect="off"
                autoCapitalize="none"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.logaUsu();
                  }
                }}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={(e) => this.mostraSenhaLogDown(e)}
                      >
                        {showPasswordLog ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  'inputProps': {
                    autoCapitalize: 'none',
                    autoCorrect: 'off',
                  }
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.logaUsu()}
                className={classes.submit}
              >
                Entrar
              </Button>

              {this.renderFacebookLogin()}
              {this.renderAppleLogin()}
              {this.renderLinksBottom()}

              <Grid container style={{ marginTop: '15px' }}>
                <Grid item xs>
                  <Link
                    variant="body2"
                    onClick={() => {
                      this.setState({
                        politPrivTitulo: 'Política de Privacidade',
                        politPriv: global.politPriv,
                        politPrivOpen: true
                      });
                    }}>
                    {"Política de privacidade"}
                  </Link>
                </Grid>
              </Grid>

            </form>
          </div>
        </div>
      </div>
    );
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }

  hasLetters(myString) {
    return /[a-zA-Z]/g.test(myString);
  }

  hasSpecialChars(myString) {
    var format = /[!@#$%*]/;
    return format.test(myString);
  }

  verificaSenhas() {

    const { cad_senha, cad_senha_conf } = this.state;

    if (cad_senha.length < 6) {
      return "A senha precisa ter ao menos 6 caracteres.";
    } else if (cad_senha.length > 20) {
      return "A senha precisa ter no máximo 20 caracteres.";
    } else if (!this.hasNumber(cad_senha)) {
      return "A senha precisa ter ao menos um número.";
    } else if (!this.hasLetters(cad_senha)) {
      return "A senha precisa ter ao menos uma letra.";
    } else if (!this.hasSpecialChars(cad_senha)) {
      return "A senha precisa ter ao menos um dos seguintes símbolos: * ! @ # $ %";
    } else if (cad_senha != cad_senha_conf) {
      return "A confirmação da senha é diferente da senha informada.";
    }

    return '';

  }

  verificaCampos(nome, cpf, num_cel,) {
    var r = true;
    var msg = '';

    num_cel = num_cel.replace(/\D/g, "");

    if ((nome.trim().length < 6) || (nome.trim().length > 60)) {
      r = false;
      msg = 'O nome do cliente precisa ter entre 6 e 60 caracteres';
    } else if ((cpf.trim().length < 14)) {
      r = false;
      msg = 'Você precisa inserir um CPF válido';
    } else if ((!validaCpfCnpj(cpf))) {
      r = false;
      msg = 'Você precisa inserir um CPF válido';
    } else if ((num_cel.trim().length < 10)) {
      r = false;
      msg = 'O telefone precisa de ao menos 8 números e o DDD';
    }

    if (!r) {
      BS_ALE(this, 'Atenção', msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
    }

    return r;
  }

  closeProgress = () => {
    this.setState({
      loading: false,
    });
  }


  salvaInfoUsu = () => {

    const { cli_nome_edit, cli_cpf_edit, cli_tele_edit, } = this.state;


    try {
      // here place your signup logic     

      if (this.verificaCampos(cli_nome_edit, cli_cpf_edit, cli_tele_edit,)) {

        var CancelToken = axios.CancelToken;

        //Usuario Apple
        //cpf = '123.123.123-87w';
        //senha = 'rYA33Q9q';

        var telefoneTratado = (cli_tele_edit).replace(/_/g, '');
        //telefoneTratado = telefoneTratado.replace(' ', '');

        var parametros =
          "partipo=" + '0' +
          "&partoken=" + global.myToken +
          "&pargrures=" + global.cod_grurest +
          "&parverapp=" + '99' +
          "&parnumcel=" + telefoneTratado +
          "&parcpf=" + (cli_cpf_edit) +
          "&parnome=" + (cli_nome_edit);

        let source = CancelToken.source();
        setTimeout(() => {
          source.cancel();
        }, global.timeOutTimer);


        axios.post(
          global.siteurl + 'cliente_salvainfo.php',
          parametros,
          {
            cancelToken: source.token,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            },
            withCredentials: true,
          }).then((response) => {
            var responseData = response.data;
            this.closeProgress();
            if (responseData.resposta == '1') {

              this.setState({
                atualizaCad: false,
              }, () => this.carregaDados());

            } else {
              BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
            }
          })
          .catch((error) => {

            if (axios.isCancel(error)) {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
            else {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
          });

      } else {

      }
    } catch (err) {

    }
  }

  renderIcon(identificacao, ativo = false) {

    var cor = (ativo) ? '#c00' : '#777';

    if (ativo) {
      return (<CheckBoxIcon style={{ margin: 'auto', color: cor }} />);
    }

    switch (identificacao) {
      case "Casa":
        return (<HomeIcon style={{ margin: 'auto', color: cor }} />);
      case "Trabalho":
        return (<WorkIcon style={{ margin: 'auto', color: cor }} />);
      case "Apartamento":
        return (<ApartmentIcon style={{ margin: 'auto', color: cor }} />);
      case "RETIRADA":
        return (<StorefrontIcon style={{ margin: 'auto', color: cor }} />);
      default:
        return (<MapIcon style={{ margin: 'auto', color: cor }} />);

    }
  }

  deleteEndereco(item) {
    this.setState({
      alertaConfExc: true,
      myItemExcluindo: item,
    });
  }

  resolveAlertaExcConta = () => {

    try {
      // here place your signup logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "&partoken=" + global.myToken +
        "&parres=" + global.cod_restaurante +
        "&pargrures=" + global.cod_grurest +
        "&parverapp=" + '99';

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'conta_dream_protocol.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          this.closeProgress();
          if (responseData.resposta == '1') {
            this.deslogaUser();
          } else {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

    }

  }

  getExtratoPontosCliente = () => {

    try {
      // here place your signup logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "&partoken=" + global.myToken +
        "&parres=" + global.cod_restaurante +
        "&pargrures=" + global.cod_grurest +
        "&parverapp=" + '99';

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'cliente_info_pontos.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          this.closeProgress();
          if (responseData.resposta == '1') {
            this.setState({
              extratoPontos: responseData.extrato,
              alertaExtratoPontos: true,
              cli_pontos: responseData.saldoAtual,
            })
          } else {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

    }

  }

  resolveAlertaExc() {

    var item = this.state.myItemExcluindo;

    try {
      // here place your signup logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "parendati=" + item.idEndereco +
        "&partoken=" + global.myToken +
        "&parres=" + global.cod_restaurante +
        "&pargrures=" + global.cod_grurest +
        "&parverapp=" + '99';

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'cliente_deleteend.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          this.closeProgress();
          if (responseData.resposta == '1') {
            this.setState({
              alertaConfExc: false,
              myItemExcluindo: -1,
            }, () => this.carregaDados());
          } else {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

    }
  }

  setEnderecoAtivo(id) {


    try {
      // here place your signup logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "parendati=" + id +
        "&partoken=" + global.myToken +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'cliente_mudaendati.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          this.closeProgress();
          if (responseData.resposta == '1') {
            this.carregaDados();
          } else {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

    }
  }

  renderStyleShadow(ativo = false) {


    var arrStyle = {};

    if (ativo) {
      arrStyle = {
        boxShadow: '0px 0px 3px 2px rgba(181,0,0,0.5)'
      };
    }

    return arrStyle;
  }

  handleChangeRegulamentoDescAceito = (e) => {
    this.setState({
      cupomDescAceito: !(this.state.cupomDescAceito)
    });
  }

  handleChangeRegulamentoFidAceito = (e) => {
    this.setState({
      fidelidadeAceito: !(this.state.fidelidadeAceito)
    });
  }

  abreTermoCupom = () => {

    var CancelToken = axios.CancelToken;

    var parametros = 'parhash=' + global.hashRest +
      "&partoken=" + global.myToken +
      "&parresid=" + global.cod_restaurante;

    let source = CancelToken.source();
    setTimeout(() => {
      source.cancel();

    }, global.timeOutTimer);

    axios.post(
      global.siteurl + 'exibe_termo_cupom.php',
      parametros,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        },
        withCredentials: true,
      }).then((response) => {

        this.setState({
          politPrivTitulo: 'Termo de Aceite - Cupom de Desconto',
          politPriv: response.data,
          politPrivOpen: true
        });

      })
      .catch((error) => {
        if (error == '785936') {

        } else if (axios.isCancel(error)) {

        } else {


        }
      });
  }

  abreTermoFidelidade = () => {

    var CancelToken = axios.CancelToken;

    var parametros = 'parhash=' + global.hashRest +
      "&partoken=" + global.myToken +
      "&parresid=" + global.cod_restaurante;

    let source = CancelToken.source();
    setTimeout(() => {
      source.cancel();

    }, global.timeOutTimer);

    axios.post(
      global.siteurl + 'exibe_termo_fidelidade.php',
      parametros,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        },
        withCredentials: true,
      }).then((response) => {

        this.setState({
          politPrivTitulo: 'Termo de Aceite - Programa de Fidelidade',
          politPriv: response.data,
          politPrivOpen: true
        });

      });
  }

  mudaPermFidelidade = (permFidelidade) => {

    try {
      // here place your signup logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "partoken=" + global.myToken +
        "&pargrures=" + global.cod_grurest +
        "&parverapp=" + '99' +
        "&parpermfide=" + permFidelidade;

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'cliente_muda_fideperm.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          this.closeProgress();
          if (responseData.resposta == '1') {

            this.setState({
              atualizaCad: false,
              fidelidadeAceito: '0'
            }, () => this.carregaDados());

          } else {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });

    } catch (err) {
      BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
    }

  }

  mudaPermCupom = (permCupom) => {

    try {
      // here place your signup logic     

      var CancelToken = axios.CancelToken;

      var parametros =
        "partoken=" + global.myToken +
        "&pargrures=" + global.cod_grurest +
        "&parverapp=" + '99' +
        "&parpermcupom=" + permCupom;

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'cliente_muda_cupperm.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          this.closeProgress();
          if (responseData.resposta == '1') {

            this.setState({
              atualizaCad: false,
            }, () => this.carregaDados());

          } else {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
          }
        })
        .catch((error) => {

          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });

    } catch (err) {
      BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
    }
  }

  abreExtratoPontos = () => {
    this.setState({
      alertaExtratoPontos: true
    });
  }

  confSairProgramaFide = () => {
    this.setState({
      alertaConfSairProgFide: true
    });
  }

  confSairProgramaCupom = () => {
    this.setState({
      alertaConfSairProgDesc: true
    });
  }

  renderSaldoPontos = () => {
    var saldoPontos = this.state.cli_pontos;

    if (saldoPontos > 0) {
      return (
        <div style={{ fontSize: '15px', fontWeight: '400', color: '#009800', textAlign: 'center', marginTop: '10px' }}>
          {`Você tem ${saldoPontos} ${(saldoPontos > 1) ? "pontos disponíveis" : "ponto disponível"}.`}
          <div onClick={() => { this.getExtratoPontosCliente() }} style={{ marginTop: '6px', marginBottom: '6px', textDecoration: 'underline', fontSize: '15px', fontWeight: '500', color: '#145087', }}>Extrato</div>
        </div>
      )
    }
  }

  renderCuponsDisp = () => {

    var cuponsSize = this.state.cli_cupons.length;

    if (cuponsSize > 0) {
      return (
        <div style={{ fontSize: '15px', fontWeight: '400', color: '#009800', textAlign: 'center', marginTop: '10px' }}>
          {`Você tem ${cuponsSize} ${(cuponsSize > 1) ? "cupons disponíveis" : "cupom disponível"}.`}
        </div>
      )
    }
  }

  renderProgramaFidelidade = () => {

    var styleTeste = this.renderStyleShadow(false);
    const { classes } = this.props;

    if (global.programaFidelidade == 1) {
      if (this.state.fidelidadeDescState == tp_programa_naoautorizado) {
        return (
          <div style={{ marginBottom: '40px', borderRadius: '8px', backgroundColor: '#950000', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}>
            <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
              <text style={{ margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff' }}>Programa de Fidelidade</text>
            </div>
            <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
              <div style={{ width: '100%' }}>
                <div style={{ fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center' }}>{"Você ainda não optou por participar do nosso programa de fidelidade."}</div>
                <div style={{ width: '100%', display: 'flex', marginTop: '12px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => this.setState({ fidelidadeDescState: 1 })} style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#009800', textDecoration: 'underline' }}>Desejo participar do Programa de Fidelidade</a>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.fidelidadeDescState == tp_programa_confautoriza) {
        return (
          <div style={{ marginBottom: '40px', borderRadius: '8px', backgroundColor: '#4287f5', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}>
            <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
              <IconButton
                color="inherit"
                style={{ padding: '7px', paddingLeft: '0px' }}
                onClick={() => {
                  this.setState({
                    fidelidadeDescState: tp_programa_naoautorizado
                  });
                }}
              >
                <ArrowBack style={{ color: '#fff' }} />
              </IconButton>
              <text style={{ margin: 'auto auto auto 0px', fontSize: '17px', fontWeight: '500', color: '#fff' }}>Programa de Fidelidade</text>
            </div>
            <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
              <div style={{ marginLeft: '6px', marginRight: '10px' }}>
                <div style={{ fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'justify' }}>{"Ao aceitar participar do nosso Programa de Fidelidade você concorda com o termo abaixo."}</div>
                <div style={{ width: '100%', display: 'flex', marginTop: '12px', marginBottom: '14px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => { this.abreTermoFidelidade() }} style={{ margin: 'auto auto auto 0px', fontSize: '15px', fontWeight: '500', color: '#777', textDecoration: 'underline' }}>Termo de Aceite e Política de Funcionamento</a>
                </div>
                <FormControlLabel
                  control={<AdicionaCheckbox checked={(this.state.fidelidadeAceito >= 1) ? true : false} value={"1"} onChange={this.handleChangeRegulamentoFidAceito} name="checkedG" />}
                  label={"Aceito os termos acima"} style={{ color: corCheckBoxAdiciona, width: '100%', }}
                />
                <div style={{ width: '90%', margin: '14px auto 0px auto' }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={(this.state.fidelidadeAceito == 1) ? "primary" : "secondary"}
                    className={classes.submit}
                    onClick={() => {
                      if (this.state.fidelidadeAceito == 1) {
                        this.mudaPermFidelidade(1);
                      } else {
                        BS_ALE(this, 'Atenção', 'Para continuar e ingressar no Programa de Descontos leia e aceite os termos.', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
                      }
                    }}

                    style={{ backgroundColor: (this.state.fidelidadeAceito == 1) ? '#3496eb' : "#ccc", width: '100%' }}
                  >
                    Confirmar
                  </Button>
                </div>

              </div>
            </div>
          </div>
        );
      } else if (this.state.fidelidadeDescState == tp_programa_autorizado) {
        return (
          <div style={{ marginBottom: '40px', borderRadius: '8px', backgroundColor: '#43944a', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}>
            <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
              <text style={{ margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff' }}>Programa de Fidelidade</text>
            </div>
            <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
              <div style={{ width: '100%' }}>
                <div style={{ fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center' }}>{"Você está participando do nosso Programa de Fidelidade."}</div>
                {this.renderSaldoPontos()}
                <div style={{ width: '100%', display: 'flex', marginTop: '6px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => this.confSairProgramaFide()} style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#980000', textDecoration: 'underline' }}>Desejo sair do Programa de Fidelidade</a>
                </div>
                <div style={{ width: '100%', display: 'flex', marginTop: '6px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => { this.abreTermoFidelidade() }} style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#777', textDecoration: 'underline' }}>Termo de Aceite e Política de Funcionamento</a>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  renderCupomDesconto = () => {

    var styleTeste = this.renderStyleShadow(false);
    const { classes } = this.props;

    if (global.cupomAtivado == 1) {
      if (this.state.cupomDescState == tp_programa_naoautorizado) {
        return (
          <div style={{ marginBottom: '40px', borderRadius: '8px', backgroundColor: '#950000', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}>
            <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
              <text style={{ margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff' }}>Cupons de Desconto</text>
            </div>
            <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
              <div style={{ width: '100%' }}>
                <div style={{ fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center' }}>{"Você ainda não optou por participar do nosso programa de descontos."}</div>
                <div style={{ width: '100%', display: 'flex', marginTop: '12px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => this.setState({ cupomDescState: 1, cupomDescAceito: 0 })} style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#009800', textDecoration: 'underline' }}>Desejo participar do Programa de Descontos</a>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.cupomDescState == tp_programa_confautoriza) {
        return (
          <div style={{ marginBottom: '40px', borderRadius: '8px', backgroundColor: '#4287f5', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}>
            <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
              <IconButton
                color="inherit"
                style={{ padding: '7px', paddingLeft: '0px' }}
                onClick={() => {
                  this.setState({
                    cupomDescState: tp_programa_naoautorizado
                  });
                }}
              >
                <ArrowBack style={{ color: '#fff' }} />
              </IconButton>
              <text style={{ margin: 'auto auto auto 0px', fontSize: '17px', fontWeight: '500', color: '#fff' }}>Cupons de Desconto</text>
            </div>
            <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
              <div style={{ marginLeft: '6px', marginRight: '10px' }}>
                <div style={{ fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'justify' }}>{"Ao aceitar participar do nosso Programa de Descontos você concorda com o termo abaixo."}</div>
                <div style={{ width: '100%', display: 'flex', marginTop: '12px', marginBottom: '14px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => { this.abreTermoCupom() }} style={{ margin: 'auto auto auto 0px', fontSize: '15px', fontWeight: '500', color: '#777', textDecoration: 'underline' }}>Termo de Aceite e Política de Funcionamento</a>
                </div>
                <FormControlLabel
                  control={<AdicionaCheckbox checked={(this.state.cupomDescAceito >= 1) ? true : false} value={"1"} onChange={this.handleChangeRegulamentoDescAceito} name="checkedG" />}
                  label={"Aceito os termos acima"} style={{ color: corCheckBoxAdiciona, width: '100%', }}
                />
                <div style={{ width: '90%', margin: '14px auto 0px auto' }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={(this.state.cupomDescAceito == 1) ? "primary" : "secondary"}
                    className={classes.submit}
                    onClick={() => {
                      if (this.state.cupomDescAceito == 1) {
                        this.mudaPermCupom(1);
                      } else {
                        BS_ALE(this, 'Atenção', 'Para continuar e ingressar no Programa de Descontos leia e aceite os termos.', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
                      }
                    }}

                    style={{ backgroundColor: (this.state.cupomDescAceito == 1) ? '#3496eb' : "#ccc", width: '100%' }}
                  >
                    Confirmar
                  </Button>
                </div>

              </div>
            </div>
          </div>
        );
      } else if (this.state.cupomDescState == tp_programa_autorizado) {
        return (
          <div style={{ marginBottom: '40px', borderRadius: '8px', backgroundColor: '#43944a', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}>
            <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
              <text style={{ margin: 'auto', fontSize: '17px', fontWeight: '500', color: '#fff' }}>Cupons de Desconto</text>
            </div>
            <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
              <div style={{ width: '100%' }}>
                <div style={{ fontSize: '15px', fontWeight: '400', color: '#777', textAlign: 'center' }}>{"Você está participando do nosso Programa de Descontos."}</div>
                {this.renderCuponsDisp()}
                <div style={{ width: '100%', display: 'flex', marginTop: '12px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => this.confSairProgramaCupom()} style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#980000', textDecoration: 'underline' }}>Desejo sair do Programa de Descontos</a>
                </div>
                <div style={{ width: '100%', display: 'flex', marginTop: '12px' }}>
                  <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => { this.abreTermoCupom() }} style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', color: '#777', textDecoration: 'underline' }}>Termo de Aceite e Política de Funcionamento</a>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }


  }

  renderInfoCli() {

    var styleTeste = this.renderStyleShadow(false);

    return (
      <div style={{ borderRadius: '8px', backgroundColor: '#efefef', padding: '10px', paddingBottom: '0px', display: 'flex', flexDirection: 'column' }}>
        <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
          <text style={{ margin: 'auto', fontSize: '17px', fontWeight: '500' }}>Informações Pessoais</text>
        </div>
        <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
          <div style={{ width: '100%' }}>
            <div style={{ fontSize: '15px', fontWeight: '500', color: '#000' }}>{"Nome"}</div>
            <div style={{ fontSize: '14px', fontWeight: '400', color: '#777' }}>{this.state.cli_nome}</div>
          </div>
        </div>
        <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
          <div style={{ width: '100%' }}>
            <div style={{ fontSize: '15px', fontWeight: '500', color: '#000' }}>{"Telefone"}</div>
            <div style={{ fontSize: '14px', fontWeight: '400', color: '#777' }}>{this.state.cli_tele}</div>
          </div>
        </div>
        <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
          <div style={{ width: '100%' }}>
            <div style={{ fontSize: '15px', fontWeight: '500', color: '#000' }}>{"E-mail"}</div>
            <div style={{ fontSize: '14px', fontWeight: '400', color: '#777' }}>{this.state.cli_email}</div>
          </div>
        </div>
        <div style={Object.assign({}, { width: '100%', marginBottom: '10px', backgroundColor: '#fff', padding: '9px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
          <div style={{ width: '100%' }}>
            <div style={{ fontSize: '15px', fontWeight: '500', color: '#000' }}>{"CPF"}</div>
            <div style={{ fontSize: '14px', fontWeight: '400', color: '#777' }}>{this.state.cli_cpf}</div>
          </div>
        </div>
        <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
          <div style={{ width: '50%', display: 'flex', }}>
            <a onClick={() => this.setState({ atualizaCad: true })} style={{ margin: 'auto auto auto 0px', fontSize: '15px', fontWeight: '500', color: '#009800', textDecoration: 'underline' }}>Editar Perfil</a>
          </div>
          <div style={{ width: '50%', display: 'flex', }}>
            <a onClick={() => this.setState({ alertaDeslogaUser: true })} style={{ margin: 'auto 0px auto auto', fontSize: '15px', fontWeight: '500', color: '#980000', textDecoration: 'underline' }}>Sair da Conta</a>
          </div>
        </div>
      </div>
    );
  }

  renderDeleteCli = () => {

    var tipo = this.state.operacao;

    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: '35px' }}>
        <Link
          variant="body2"
          style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#999', padding: '0px 10px', textAlign: 'center', }}
          onClick={() => {
            this.setState({
              alertaConfExcConta: true
            });
          }}>
          {"Excluir minha conta"}
        </Link>
      </div>
    );

  }

  renderLinkPolicy() {
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: '25px' }}>
        <Link
          variant="body2"
          style={{ fontSize: '16px', margin: 'auto', textDecoration: 'underline', color: '#DAA520', padding: '0px 10px', textAlign: 'center', }}
          onClick={() => {
            this.setState({
              politPrivTitulo: 'Política de Privacidade',
              politPriv: global.politPriv,
              politPrivOpen: true
            });
          }}>
          {"Política de Privacidade"}
        </Link>
      </div>
    );
  }

  renderEnderecos() {

    const enderecoEncontrado = this.state.cli_enderecos.find(endereco => endereco.idEndereco === "DELBLQ");

    if (enderecoEncontrado) {
      return (
        <div style={{ borderRadius: '8px', backgroundColor: '#efefef', padding: '10px', paddingBottom: '0px' }}>
          <div style={{ width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }}>
            <text style={{ margin: 'auto', fontSize: '17px', fontWeight: '500' }}>Meus endereços</text>
          </div>
          <div style={{ marginBottom: '10px', backgroundColor: '#fff', padding: '18px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }}>
            <div style={{ width: '84%', paddingLeft: '12px' }}>
              <div style={{ fontSize: '15px', fontWeight: '500', color: '#000' }}>{"Serviço de Delivery desativado pela loja."}</div>
              <div style={{ fontSize: '14px', fontWeight: '400', color: '#777' }}>{enderecoEncontrado.rua}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={{ borderRadius: '8px', backgroundColor: '#efefef', padding: '10px', paddingBottom: '0px' }}>
        <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
          <text style={{ margin: 'auto', fontSize: '17px', fontWeight: '500' }}>Meus endereços</text>
        </div>
        {(this.state.cli_enderecos) ? this.state.cli_enderecos.map((item, index) => {

          var enderecoAtivo = false;

          if ((item.identificacao) && (item.identificacao != "")) {

            var enderecoFinal = '';

            if (item.identificacao == "RETIRADA") {
              enderecoFinal = "Vou retirar meus produtos na loja";
            } else {
              if (item.rua.length > 0) {
                enderecoFinal = enderecoFinal + item.rua;
              }

              if (item.numero.length > 0) {
                if (enderecoFinal.length > 0) {
                  enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + item.numero;
              }

              if (item.complemento.length > 0) {

                if (enderecoFinal.length > 0) {
                  enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + item.complemento;
              }

              if (item.referencia.length > 0) {

                if (enderecoFinal.length > 0) {
                  enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + item.referencia;
              }

              if (item.bairro.length > 0) {

                if (enderecoFinal.length > 0) {
                  enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + item.bairro;
              }

              if (item.cidade.length > 0) {

                if (enderecoFinal.length > 0) {
                  enderecoFinal = enderecoFinal + ' - ';
                }

                enderecoFinal = enderecoFinal + item.cidade;
              }

              if (item.estado.length > 0) {

                if (enderecoFinal.length > 0) {
                  enderecoFinal = enderecoFinal + ' - ';
                }

                enderecoFinal = enderecoFinal + item.estado;
              }

              if (item.cep.length > 0) {

                if (enderecoFinal.length > 0) {
                  enderecoFinal = enderecoFinal + ' - ';
                }

                enderecoFinal = enderecoFinal + item.cep;

              }
            }
          } else {

            var enderecoFinal = '';

            if (item.complemento.length > 0) {

              if (enderecoFinal.length > 0) {
                enderecoFinal = enderecoFinal + ', ';
              }

              enderecoFinal = enderecoFinal + item.complemento;
            }

            if (item.referencia.length > 0) {

              if (enderecoFinal.length > 0) {
                enderecoFinal = enderecoFinal + ', ';
              }

              enderecoFinal = enderecoFinal + item.referencia;
            }

            if (item.bairro.length > 0) {

              if (enderecoFinal.length > 0) {
                enderecoFinal = enderecoFinal + ', ';
              }

              enderecoFinal = enderecoFinal + item.bairro;
            }

            if (item.cidade.length > 0) {

              if (enderecoFinal.length > 0) {
                enderecoFinal = enderecoFinal + ' - ';
              }

              enderecoFinal = enderecoFinal + item.cidade;
            }

            if (item.estado.length > 0) {

              if (enderecoFinal.length > 0) {
                enderecoFinal = enderecoFinal + ' - ';
              }

              enderecoFinal = enderecoFinal + item.estado;
            }

            if (item.cep.length > 0) {

              if (enderecoFinal.length > 0) {
                enderecoFinal = enderecoFinal + ' - ';
              }

              enderecoFinal = enderecoFinal + item.cep;

            }


          }

          var styleTeste = this.renderStyleShadow(enderecoAtivo);

          if (item.identificacao != 'RETIRADA') {
            return (
              <div style={Object.assign({}, { marginBottom: '10px', backgroundColor: '#fff', padding: '18px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', }, styleTeste)}>
                <div style={{ width: '6%', minWidth: '40px', display: 'flex' }}>
                  {this.renderIcon(item.identificacao, enderecoAtivo)}
                </div>
                <div style={{ width: '84%' }}>
                  <div style={{ fontSize: '15px', fontWeight: '500', color: (enderecoAtivo) ? '#c00' : '#000' }}>{((item.identificacao) && (item.identificacao != "")) ? ((item.identificacao == 'RETIRADA') ? 'Retirar na Loja' : item.identificacao) : (item.rua + ', ' + item.numero)}</div>
                  <div style={{ fontSize: '14px', fontWeight: '400', color: '#777' }}>{enderecoFinal}</div>
                </div>
                <div onClick={() => {
                  if (item.identificacao != 'RETIRADA') {
                    this.deleteEndereco(item)
                  }
                }} style={{ width: '10%', minWidth: '40px', display: 'flex' }}>
                  {(item.identificacao == 'RETIRADA') ? null : <DeleteIcon style={{ margin: 'auto', fontSize: '29px', color: '#a33' }} />}
                </div>
              </div>
            );
          }
        }) : null}
        <div style={Object.assign({}, { width: '100%', marginBottom: '7px', padding: '7px', paddingLeft: '6px', borderRadius: '8px', display: 'flex', flexDirection: 'row', })}>
          <div style={{ width: '100%', display: 'flex', }}>
            <a ref={(ref) => { this.btnAddEnd = ref; }} onClick={() => {

              this.props.navigate('usuarioinfo-addend');
            }} style={{ margin: 'auto auto auto 0px', fontSize: '15px', fontWeight: '500', color: '#009800', textDecoration: 'underline' }}>Adicionar novo Endereço</a>
          </div>
        </div>

      </div>
    );
  }

  renderLoading() {

    const { classes } = this.props;

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Skeleton variant="circle" width={60} height={60} />
            <Skeleton variant="text" height={60} width={210} />
            <div className={classes.form} noValidate>
              <Skeleton variant="text" height={60} />
              <Skeleton variant="text" height={60} />
              <Skeleton variant="text" height={60} />
              <Skeleton variant="rect" height={60} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  deslogaUser = () => {

    //renovaJWT();

    if (this.state.myToken) {

      try {
        // here place your login logic     

        var CancelToken = axios.CancelToken;

        const { log_senha, log_usu, log_cpf } = this.state;

        var parametros =
          "&partoken=" + global.myToken +
          "&pargrures=" + global.cod_grurest +
          "&parres=" + global.cod_restaurante +
          "&parverapp=" + '99';

        let source = CancelToken.source();
        setTimeout(() => {
          source.cancel();
        }, global.timeOutTimer);


        axios.post(
          global.siteurl + 'cliente_logout.php',
          parametros,
          {
            cancelToken: source.token,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            },
            withCredentials: true,
          }).then((response) => {
            var responseData = response.data;
            if (responseData.resposta == "1") {
              localStorage.setItem(global.chaveLocalLogin, '');

              global.myToken = '';
              this.setState({
                refresh: true,
                myToken: '',
                alertaConfExcConta: false,
              }, () => this.carregaDados());
            }
          })
          .catch((error) => {

            if (axios.isCancel(error)) {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
            else {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
          });

      } catch (err) {

      }
    }
  }

  renderPagCli() {

    const { classes } = this.props;

    return (
      <div>
        <div className={classes.usuarioForm}>
          {this.renderCupomDesconto()}
          {this.renderProgramaFidelidade()}
          {this.renderInfoCli()}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px', marginBottom: '30px' }}>
            {this.renderEnderecos()}
            {this.renderLinkPolicy()}
            {this.renderDeleteCli()}
          </div>
        </div>
      </div>
    );
  }

  confirmaCodUsu = async () => {
    var { cli_vericod } = this.state;

    try {
      // here place your signup logic     

      var i = 0;

      var parametros =
        "partipo=" + this.state.cli_tipo +
        "&partoken=" + global.myToken +
        "&parcodigo=" + cli_vericod;

      if (cli_vericod.length == 6) {

        var CancelToken = axios.CancelToken;


        let source = CancelToken.source();
        setTimeout(() => {
          source.cancel();

        }, global.timeOutTimer);


        axios.post(
          global.siteurl + 'cliente_veriemail.php',
          parametros,
          {
            cancelToken: source.token,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            },
            withCredentials: true,
          }).then((response) => {
            var responseData = response.data;
            if (responseData.resposta == '1') {
              this.setState({
                cli_tipo: '1',
                cli_vericod: '',
                refresh: true,
              });
            } else {
              if (responseData.resposta == '99') {
                BS_ALE(this, 'Atenção', responseData.msg);
              } else {
                BS_ALE(this, 'Atenção', responseData.msg);
              }

            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 073', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
            else {

              BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 074', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

            }
          });
      } else {
        this.closeProgress();
      }
    } catch (err) {

      this.closeProgress();
    }

  }

  handleMaxLength = (event) => {
    var maxLength = event.target.maxLength;
    if (this.state.cli_vericod.length >= maxLength) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  reenviaCod = async () => {
    try {
      // Reenviar código de confirmação

      var i = 0;

      var parametros =
        "&paremail=" + (this.state.cad_email) +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      var CancelToken = axios.CancelToken;

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();

      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'reenvia_codigo_web.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta != '1') {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {
            BS_ALE(this, 'Sucesso', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
            this.closeProgress();
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 071', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 072', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

      this.closeProgress();
    }

  }

  renderConfirmaCod() {

    const { classes } = this.props;

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Avatar className={classes.avatar}>
              <CheckIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Confirmar código
            </Typography>
            <text>Enviamos um código de confirmação no seu e-mail, insira aqui o código recebido.</text>
            <text><b>Email:</b> {this.state.cli_email}</text>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cli_vericod"
                label="Código recebido"
                name="cli_vericod"
                type="number"
                value={this.state.cli_vericod}
                onChange={this.handleChange}
                inputProps={{
                  maxLength: 6
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.confirmaCodUsu();
                  }
                }}
                onKeyPress={this.handleMaxLength}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.confirmaCodUsu()}
                style={{ backgroundColor: '#3496eb' }}
              >
                Confirmar
              </Button>
              {this.renderLinksBottom()}
            </form>
          </div>
        </div>
      </div>
    );
  }

  iniMudaSenha = async () => {
    try {
      // Reenviar código de confirmação

      var i = 0;

      var parametros =
        "&paremail=" + (this.state.cad_email) +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      var CancelToken = axios.CancelToken;


      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();

      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'cliente_mudasenha_enviacod.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta != '1') {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {
            this.setState({
              cli_cadestado: tp_cadcli_codigo
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 071', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 072', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

      this.closeProgress();
    }

  }

  iniCadUsu = async () => {
    try {
      // Reenviar código de confirmação

      var i = 0;

      var parametros =
        "&paremail=" + (this.state.cad_email) +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      var CancelToken = axios.CancelToken;


      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();

      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'cliente_cadcli_enviacod.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          },
          withCredentials: true,
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta != '1') {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {
            this.setState({
              cli_cadestado: tp_cadcli_codigo
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 071', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 072', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

      this.closeProgress();
    }

  }

  verificaCodMudaSenha = async () => {
    try {
      // Reenviar código de confirmação

      var i = 0;

      var parametros =
        "&paremail=" + (this.state.cad_email) +
        "&parcodigo=" + (this.state.cad_codconf) +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      var CancelToken = axios.CancelToken;


      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();

      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'cliente_mudasenha_vericod.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta != '1') {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {
            this.setState({
              cli_cadestado: tp_cadcli_senha
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 071', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 072', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

      this.closeProgress();
    }

  }

  verificaCodCadUsu = async () => {
    try {
      // Reenviar código de confirmação

      var i = 0;

      var parametros =
        "&paremail=" + (this.state.cad_email) +
        "&parcodigo=" + (this.state.cad_codconf) +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      var CancelToken = axios.CancelToken;


      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();

      }, global.timeOutTimer);


      axios.post(
        global.siteurl + 'cliente_cadcli_vericod.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta != '1') {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {
            this.setState({
              cli_cadestado: tp_cadcli_senha
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 071', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 072', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

      this.closeProgress();
    }

  }

  atualizaSenhaMudaSenha = async () => {
    try {
      // Reenviar código de confirmação

      var i = 0;

      var parametros =
        "&paremail=" + (this.state.cad_email) +
        "&parsenha=" + (this.state.cad_senha) +
        "&parcodigo=" + (this.state.cad_codconf) +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      var CancelToken = axios.CancelToken;

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'cliente_mudasenha_mudasenha.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta != '1') {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {
            this.setState({
              cli_cadestado: tp_cadcli_final
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 071', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 072', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

      this.closeProgress();
    }

  }

  atualizaSenhaCadUsu = async () => {
    try {
      // Reenviar código de confirmação

      var i = 0;

      var parametros =
        "&paremail=" + (this.state.cad_email) +
        "&parsenha=" + (this.state.cad_senha) +
        "&parcodigo=" + (this.state.cad_codconf) +
        "&pargrures=" + global.cod_grurest +
        "&parres=" + global.cod_restaurante +
        "&parverapp=" + '99';

      var CancelToken = axios.CancelToken;

      let source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, global.timeOutTimer);

      axios.post(
        global.siteurl + 'cliente_cadcli_mudasenha.php',
        parametros,
        {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }
        }).then((response) => {
          var responseData = response.data;
          if (responseData.resposta != '1') {
            BS_ALE(this, 'Atenção', responseData.msg, [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
          } else {
            this.setState({
              cli_cadestado: tp_cadcli_final
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 071', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
          else {

            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 072', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

          }
        });
    } catch (err) {

      this.closeProgress();
    }

  }

  renderCadastro_finalizado() {

    const { classes } = this.props;

    var tipo = this.state.operacao;

    var titulo = (tipo == tp_estado_criaconta) ? "Cadastro" : "Recuperar senha";
    var info = (tipo == tp_estado_criaconta) ? "Sua nova senha foi registrada com sucesso!" :
      "Sua nova senha foi registrada com sucesso!";

    const Icon = (tipo == tp_estado_criaconta) ? <PersonAdd /> : <VpnKey />;

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Avatar className={classes.avatar} style={{ margin: 'auto' }}>
              {Icon}
            </Avatar>
            <Typography component="h1" variant="h5" style={{ margin: 'auto' }}>
              {titulo}
            </Typography>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {info}
            </div>
            <form className={classes.form} noValidate>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  let arrTmp = this.state.estadoLogin;
                  arrTmp.push(0);
                  this.setState({
                    estadoLogin: arrTmp,
                  });
                }}
                style={{ backgroundColor: '#3496eb' }}
              >
                Logar
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderCadastro_senha() {

    const { classes } = this.props;

    const { showPasswordCad, showPasswordCadConf } = this.state;

    var tipo = this.state.operacao;

    var titulo = (tipo == tp_estado_criaconta) ? "Cadastro" : "Recuperar senha";
    var info = (tipo == tp_estado_criaconta) ? 'Registre sua nova senha abaixo nos dois campos.\n' +
      'A senha deve conter de 6 a 20 caracteres e preencher os seguintes requisitos:\n' +
      '- Conter ao menos uma letra;\n' +
      '- Conter ao menos um número;\n' +
      '- Conter ao menos um símbolo * ! @ # $ %' :

      'Registre sua nova senha abaixo nos dois campos.\n' +
      'A senha deve conter de 6 a 20 caracteres e preencher os seguintes requisitos:\n' +
      '- Conter ao menos uma letra;\n' +
      '- Conter ao menos um número;\n' +
      '- Conter ao menos um símbolo * ! @ # $ %';

    const Icon = (tipo == tp_estado_criaconta) ? <PersonAdd /> : <VpnKey />;

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Avatar className={classes.avatar}>
              {Icon}
            </Avatar>
            <Typography component="h1" variant="h5">
              {titulo}
            </Typography>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {info}
            </div>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="cad_senha"
                label="Senha"
                id="cad_senha"
                autoComplete="current-senha"
                value={this.state.cad_senha}
                onChange={this.handleChange}
                type={showPasswordCad ? "text" : "password"}
                autoCorrect="off"
                autoCapitalize="none"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={(e) => this.mostraSenhaCad(e)}
                      >
                        {showPasswordCad ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="cad_senha_conf"
                label="Confirmar Senha"
                id="cad_senha_conf"
                autoComplete="current-senha"
                value={this.state.cad_senha_conf}
                onChange={this.handleChange}
                type={showPasswordCadConf ? "text" : "password"}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    var errMsg = this.verificaSenhas();
                    if (errMsg == '') {
                      (tipo == tp_estado_criaconta) ? this.atualizaSenhaCadUsu() : this.atualizaSenhaMudaSenha();
                    } else {
                      BS_ALE(this, 'Atenção', errMsg);
                    }
                  }
                }}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment tabIndex="-1" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        tabIndex="-1"
                        onMouseDown={(e) => this.mostraSenhaCadConf(e)}
                      >
                        {showPasswordCadConf ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  var errMsg = this.verificaSenhas();
                  if (errMsg == '') {
                    (tipo == tp_estado_criaconta) ? this.atualizaSenhaCadUsu() : this.atualizaSenhaMudaSenha();
                  } else {
                    BS_ALE(this, 'Atenção', errMsg);
                  }

                }}
                style={{ backgroundColor: '#3496eb' }}
              >
                Continuar
              </Button>

              {this.renderLinksBottom()}
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderCadastro_codigo() {

    const { classes } = this.props;

    var tipo = this.state.operacao;

    var titulo = (tipo == tp_estado_criaconta) ? "Cadastro" : "Recuperar Senha";
    var info = (tipo == tp_estado_criaconta) ? "Verifique em seu e-mail se você recebeu o código de registro de conta. Use esse código para preencher o campo abaixo e clique no botão [Continuar]" :
      "Verifique em seu e-mail se você recebeu o código de registro de senha. Use esse código para preencher o campo abaixo e clique no botão [Continuar]";

    const Icon = (tipo == tp_estado_criaconta) ? <PersonAdd /> : <VpnKey />;

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Avatar className={classes.avatar}>
              {Icon}
            </Avatar>
            <Typography component="h1" variant="h5">
              {titulo}
            </Typography>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {info}
            </div>
            <form className={classes.form} noValidate>
              <TextField

                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cad_codconf"
                label="Código de confirmação"
                name="cad_codconf"
                type="number"
                value={this.state.cad_codconf}
                onChange={this.handleChange}
                inputProps={{
                  maxLength: 6
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (tipo == tp_estado_criaconta) {
                      this.verificaCodCadUsu();
                    } else {
                      this.verificaCodMudaSenha();
                    }
                  }
                }}
                onKeyPress={this.handleMaxLength}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  (tipo == tp_estado_criaconta) ? this.verificaCodCadUsu() : this.verificaCodMudaSenha();
                }}
                style={{ backgroundColor: '#3496eb' }}
              >
                Continuar
              </Button>
              {this.renderLinksBottom()}
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderLinkPolicyCad = () => {

    var tipo = this.state.operacao;

    if (tipo == tp_estado_criaconta) {
      return (
        <Grid container style={{ marginTop: '15px' }}>
          <Grid item xs>
            <div>
              <text>Ao cadastrar-se você concorda com nossa </text>
              <Link
                variant="body2"
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  this.setState({
                    politPrivTitulo: 'Política de Privacidade',
                    politPriv: global.politPriv,
                    politPrivOpen: true
                  });
                }}>
                {"Política de Privacidade"}
              </Link>
            </div>

          </Grid>
        </Grid>
      );
    }
  }

  renderCadastro() {

    const { classes } = this.props;

    var tipo = this.state.operacao;

    var titulo = (tipo == tp_estado_criaconta) ? "Cadastro" : "Recuperar senha";
    var info = (tipo == tp_estado_criaconta) ? 'Informe abaixo o endereço de e-mail que será o seu login de acesso ao app e depois clique no botão [Continuar]' :
      "Preencha o campo login com o e-mail que você usou para criar a sua conta e clique no botão [Continuar]. Você receberá o código de recuperação de senha por e-mail.";

    const Icon = (tipo == tp_estado_criaconta) ? <PersonAdd /> : <VpnKey />;

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Avatar className={classes.avatar}>
              {Icon}
            </Avatar>
            <Typography component="h1" variant="h5">
              {titulo}
            </Typography>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {info}
            </div>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="cad_email"
                label="Email"
                type="email"
                id="cad_email"
                autoComplete="email"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (this.isValidEmail(this.state.cad_email)) {
                      if (tipo == tp_estado_criaconta) {
                        this.iniCadUsu();
                      } else {
                        this.iniMudaSenha();
                      }
                    } else {
                      BS_ALE(this, 'Atenção', 'Insira um e-mail válido para continuar.', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
                    }
                  }
                }}
                value={this.state.cad_email}
                onChange={this.handleChange}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  if (this.isValidEmail(this.state.cad_email)) {
                    (tipo == tp_estado_criaconta) ? this.iniCadUsu() : this.iniMudaSenha();
                  } else {
                    BS_ALE(this, 'Atenção', 'Insira um e-mail válido para continuar.', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);
                  }
                }}
                style={{ backgroundColor: '#3496eb' }}
              >
                Continuar
              </Button>
              {this.renderLinksBottom()}
              {this.renderLinkPolicyCad()}
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderSalvaInfos() {

    const { classes } = this.props;

    return (
      <div>
        <div className={classes.loginForm}>
          <div className={classes.paperHeader}>
            <Avatar className={classes.avatar}>
              <PersonAdd />
            </Avatar>
            <Typography component="h1" variant="h5">
              Editar Perfil
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="cli_email"
                label="Email"
                type="email"
                id="cli_email"
                autoComplete="email"
                value={this.state.cli_email}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    this.salvaInfoUsu()
                  }
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cli_nome_edit"
                label="Nome"
                name="cli_nome_edit"
                autoComplete="name"
                autoFocus
                value={this.state.cli_nome_edit}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    this.salvaInfoUsu()
                  }
                }}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="cli_cpf_edit"
                label="CPF"
                type="text"
                id="cli_cpf_edit"
                autoComplete="text"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    this.salvaInfoUsu()
                  }
                }}
                InputProps={{
                  inputComponent: CPFMaskInput,
                  value: this.state.cli_cpf_edit,
                  onChange: this.handleChange,
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Celular"
                onChange={this.handleChange}
                name="cli_tele_edit"
                id="formatted-text-mask-input"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    this.salvaInfoUsu()
                  }
                }}
                InputProps={{
                  inputComponent: TelefoneMaskInput,
                  value: this.state.cli_tele_edit,
                  onChange: this.handleChange,
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.salvaInfoUsu()}
                style={{ backgroundColor: '#3496eb' }}
              >
                Salvar Informações
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderTiposCampos() {
    var estadoLogin = this.state.estadoLogin[this.state.estadoLogin.length - 1];
    //return this.renderLogin();

    if (this.state.loading) {
      return this.renderLoading();
    }

    if (!global.myToken) { // TOKEN NÃO ESTÁ PREENCHIDO - USUÁRIO DESLOGADO
      if (estadoLogin == 0) { // A PÁGINA ESTÁ NO ESTADO DE LOGIN
        return this.renderLogin();
      } else if (estadoLogin == 1) { // A PÁGINA ESTÁ NO ESTADO DE CADASTRO
        if (this.state.cli_cadestado == tp_cadcli_email) {
          return this.renderCadastro();
        } else if (this.state.cli_cadestado == tp_cadcli_codigo) {
          return this.renderCadastro_codigo();
        } else if (this.state.cli_cadestado == tp_cadcli_senha) {
          return this.renderCadastro_senha();
        } else if (this.state.cli_cadestado == tp_cadcli_final) {
          return this.renderCadastro_finalizado();
        }
      }
    } else if (global.myToken.length > 0) { // TOKEN PREENCHIDO - USUÁRIO LOGADO
      if (this.state.cli_tipo == "0") { // CONFIRMAÇÃO DE EMAIL PENDENTE
        return this.renderConfirmaCod();
      } else { // NENHUMA PENDENCIA
        var pendenciaCad = false;

        if (!pendenciaCad) pendenciaCad = (this.state.cli_nome.length <= 0);
        if (!pendenciaCad) pendenciaCad = (this.state.cli_tele.length <= 0);
        if (!pendenciaCad) pendenciaCad = (this.state.cli_cpf.length <= 0);

        if (!pendenciaCad) pendenciaCad = this.state.atualizaCad;

        if (pendenciaCad) {
          return this.renderSalvaInfos();
        } else {
          return this.renderPagCli();
        }

      }

    } else if (estadoLogin == 0) {
      return this.renderLogin();
    } else if (estadoLogin == 1) {
      if (this.state.cli_cadestado == tp_cadcli_email) {
        return this.renderCadastro();
      } else if (this.state.cli_cadestado == tp_cadcli_codigo) {
        return this.renderCadastro_codigo();
      } else if (this.state.cli_cadestado == tp_cadcli_senha) {
        return this.renderCadastro_senha();
      } else if (this.state.cli_cadestado == tp_cadcli_final) {
        return this.renderCadastro_finalizado();
      }
    }
  }

  renderArrowBack = () => {
    if (this.state.estadoLogin) {
      if (this.state.estadoLogin.length > 0) {
        var estadoLogin = this.state.estadoLogin[this.state.estadoLogin.length - 1];

        if (this.state.atualizaCad) {
          return (
            <IconButton
              color="inherit"
              style={{ padding: '7px' }}
              onClick={() => {

                this.setState({
                  atualizaCad: false
                });

              }}
            >
              <ArrowBack />
            </IconButton>
          );
        } else if (estadoLogin > 0) {
          return (
            <IconButton
              color="inherit"
              style={{ padding: '7px' }}
              onClick={() => {


                if (this.state.cli_cadestado > 0) {
                  this.setState({
                    cli_cadestado: this.state.cli_cadestado - 1
                  });
                } else if (this.state.estadoLogin.length > 1) {
                  var arrTmp = this.state.estadoLogin;
                  arrTmp.splice(arrTmp.length - 1, 1);

                  this.setState({
                    estadoLogin: arrTmp
                  });
                }

              }}
            >
              <ArrowBack />
            </IconButton>
          );
        }

      }
    }

  }

  renderHeader = () => {

    return (
      <div style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', fontSize: '20px', }}>
        {this.renderArrowBack()}
        Sua conta
      </div>
    );
  }

  handleCloseConfExc = () => {
    this.setState({
      alertaConfExc: false
    });
  }

  handleCloseConfExcConta = () => {

    this.setState({
      alertaConfExcConta: false
    });

  }

  handleCloseConfSairProgDesc = () => {
    this.setState({
      alertaConfSairProgDesc: false
    });
  }

  resolveConfSairProgDesc = () => {
    this.setState({
      alertaConfSairProgDesc: false
    }, () => {
      this.mudaPermCupom(0);
    });
  }

  handleCloseConfSairProgFide = () => {
    this.setState({
      alertaConfSairProgFide: false
    });
  }

  resolveConfSairProgFide = () => {
    this.setState({
      alertaConfSairProgFide: false
    }, () => {
      this.mudaPermFidelidade(0);
    });
  }

  isNegativeNumber(str) {

    try {
      if (str === '') {
        return false; // Se a string estiver vazia, não é um número negativo
      }
      const number = Number(str);
      return !isNaN(number) && number < 0;
    } catch (error) {
      return false;
    }

  }

  renderListaExtrato = () => {

    var lista = this.state.extratoPontos;

    return lista.map((item, index) => {

      var data = item.data.substr(8, 2) + '/' +
        item.data.substr(5, 2) + '/' +
        item.data.substr(0, 4);

      var tipo = item.tipo;

      var fonteCor = (tipo == '1') ? '#349449' : '#980000';
      var sinal = (tipo == '1') ? '+ ' : '- ';
      var sinal2 = '';
      var pedido = item.pedido;

      var valor = item.movimento;

      if (tipo == '3') {
        fonteCor = '#cfb430';
        sinal = '(';
        sinal2 = ')';
      }

      if (tipo == '5') {
        fonteCor = '#3266a8';

        if (this.isNegativeNumber(item.movimento)) {
          sinal = '- ';
          valor = item.movimento.replace(/\D/g, '');

          pedido = "Desconto de pontos ";
          fonteCor = "#980000";
        } else {
          sinal = '+ ';
          pedido = "Bônus da Loja :)"
        }

        sinal2 = '';
      }

      return (
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '6px', }}>
          <div style={{ fontWeight: '500' }}>{data}</div>
          <div style={{ fontWeight: '400', marginLeft: '5px' }}>{'- ' + pedido}</div>
          <div style={{ flex: 1 }}></div>
          <div style={{ color: fonteCor }}>{sinal + valor} ponto(s){sinal2}</div>
        </div>
      );
    });

  }

  renderExtratoPontos = () => {

    const { classes } = this.props;

    const appBarClasses = classNames(classes);

    var lista = [
      "aaaa",
      "aaaa",
      "aaaa",
    ]

    var saldoPontos = this.state.cli_pontos;

    return (
      <Dialog
        open={this.state.alertaExtratoPontos}
        onClose={() => { this.setState({ alertaExtratoPontos: false }) }}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ container: classes.root, paper: classes.paper }}
        style={{ maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch' }}
      >
        <AppBar className={appBarClasses} style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px', position: 'absolute' }}>
          <div style={{ height: '50px', width: '100%', display: 'flex', position: 'relative' }}>
            <text style={{ fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto' }}>{"Programa de Fidelidade"}</text>
            <div onClick={() => { this.setState({ alertaExtratoPontos: false }) }} style={{ fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px' }}>
              <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                <ArrowBack style={{ margin: 'auto', }} />
              </div>
            </div>
          </div>
        </AppBar>
        <DialogContent style={{ width: '100%', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px' }}>
            <div>
              <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '14px', fontWeight: '500', fontSize: '16px' }}>Extrato de Pontos</div>
              {this.renderListaExtrato()}
              <div style={{ marginTop: '15px', display: 'flex', flexDirection: 'row', }}>
                <div style={{ fontWeight: 'bold' }}>Saldo Final</div>
                <div style={{ flex: '1' }}></div>
                <div style={{ fontWeight: '500', color: '#349449' }}>{saldoPontos} ponto(s)</div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  renderPoliticaPrivacidade = () => {

    const { classes } = this.props;

    const appBarClasses = classNames(classes);

    return (
      <Dialog
        open={this.state.politPrivOpen}
        onClose={() => { this.setState({ politPrivOpen: false }) }}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ container: classes.root, paper: classes.paper }}
        style={{ maxWidth: '720px', margin: '0 auto 0 auto', WebkitOverflowScrolling: 'touch' }}
      >
        <AppBar className={appBarClasses} style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px', position: 'absolute' }}>
          <div style={{ height: '50px', width: '100%', display: 'flex', position: 'relative' }}>
            <text style={{ fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto' }}>{"Informações"}</text>
            <div onClick={() => { this.setState({ politPrivOpen: false }) }} style={{ fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px' }}>
              <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                <ArrowBack style={{ margin: 'auto', }} />
              </div>
            </div>
          </div>
        </AppBar>
        <DialogContent style={{ width: '100%', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30px', marginBottom: '0px' }}>
            <div>
              <div style={{ width: '100%', display: 'flex' }}>
                <text style={{ paddingBottom: '9px', paddingTop: '18px', margin: 'auto', marginTop: '12px', marginBottom: '15px', fontSize: '20px', fontWeight: '410', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: '#0380fc' }}>{this.state.politPrivTitulo}</text>
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.state.politPriv }} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.myBody} style={{ marginTop: '0px' }}>
        <div className={classes.container}>
          <Card style={{ minHeight: '100vh' }}>
            <form className={classes.form}>
              <Header context={this} defaultIcon={false} leftLinks={
                <div style={{ whiteSpace: 'nowrap' }}>
                  <div style={{ width: 'max-content', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                    {this.renderHeader()}
                  </div>
                </div>
              } />
              <CardBody>
                {this.renderTiposCampos()}
              </CardBody>
            </form>
          </Card>
        </div>
        <Footer qtdeTiposProds={this.state.carrinhoCompra.length} whiteFont />
        <Confirmacao
          showAlert={this.state.alertaConfExc}
          onClose={this.handleCloseConfExc}
          callbackYes={() => { this.resolveAlertaExc() }}
          title={'Atenção'}
          msg={'Você deseja mesmo excluir este endereço?'}
          callbackNo={() => {
            this.handleCloseConfExc();
          }}
        />

        <Confirmacao
          showAlert={this.state.alertaConfExcConta}
          onClose={this.handleCloseConfExcConta}
          callbackYes={() => { this.resolveAlertaExcConta() }}
          title={'Atenção'}
          msg={'Você deseja mesmo excluir sua conta? Você terá que refazer seu cadastro se quiser fazer pedidos novamente.'}
          callbackNo={() => {
            this.handleCloseConfExcConta();
          }}
        />

        <Confirmacao
          showAlert={this.state.alertaConfSairProgDesc}
          onClose={this.handleCloseConfSairProgDesc}
          callbackYes={() => { this.resolveConfSairProgDesc() }}
          title={'Atenção'}
          msg={'Você realmente deseja sair do nosso programa de descontos?\n\nCaso opte por sair, não receberá mais cupons nem poderá utilizá-los.'}
          callbackNo={() => {
            this.handleCloseConfSairProgDesc();
          }}
        />

        <Confirmacao
          showAlert={this.state.alertaConfSairProgFide}
          onClose={this.handleCloseConfSairProgFide}
          callbackYes={() => { this.resolveConfSairProgFide() }}
          title={'Atenção'}
          msg={'Você deseja mesmo sair do nosso programa de fidelidade?\n\nVocê não acumulará mais pontos nem poderá trocar estes pontos por produtos.'}
          callbackNo={() => {
            this.handleCloseConfSairProgFide();
          }}
        />

        <Confirmacao
          showAlert={this.state.alertaConfAddEnd}
          onClose={() => {
            this.setState({
              alertaConfAddEnd: false,
            });
          }}
          callbackYes={() => {

            this.props.navigate('usuarioinfo-addend');
          }}
          title={'Atenção'}
          msg={'Você ainda não tem um endereço de entrega, deseja cadastrar um?'}
          callbackNo={() => {
            this.setState({
              alertaConfAddEnd: false,
            }, () => {

              this.props.navigate('/');
            });
          }}
        />

        <Confirmacao
          showAlert={this.state.alertaDeslogaUser}
          onClose={() => {
            this.setState({
              alertaDeslogaUser: false,
            });
          }}
          callbackYes={() => {
            this.setState({
              alertaDeslogaUser: false,
            }, () => {
              this.deslogaUser();
            });
          }}
          title={'Atenção'}
          msg={'Você deseja mesmo sair da sua conta?'}
          callbackNo={() => {
            this.setState({
              alertaDeslogaUser: false,
            });
          }}
        />

        <Confirmacao
          showAlert={this.state.confLogFacebook_alerta}
          onClose={() => {
            this.setState({
              confLogFacebook_alerta: false,
              confLogFacebook_jwt: '',
              confLogFacebook_nome: '',
            });
          }}
          callbackYes={() => {

            localStorage.setItem(global.chaveLocalLogin, this.state.confLogFacebook_jwt);

            global.myToken = this.state.confLogFacebook_jwt;
            this.setState({
              refresh: true,
              myToken: this.state.confLogFacebook_jwt,
              log_senha: '',
              log_usu: '',
              log_cpf: '',
              confLogFacebook_alerta: false,
              confLogFacebook_jwt: '',
              confLogFacebook_nome: '',
            }, () => this.carregaDados());

          }}
          title={'Atenção'}
          msg={'Atualmente você está logado como ' + this.state.confLogFacebook_nome + '.'}
          callbackNo={() => {
            this.setState({
              confLogFacebook_alerta: false,
              confLogFacebook_jwt: '',
              confLogFacebook_nome: '',
            });
          }}
          btnSimText={"Continuar"}
          btnNaoText={"Voltar"}
        />

        {this.renderExtratoPontos()}
        {this.renderPoliticaPrivacidade()}
      </div>
    );
  }
}

export default withStyles(LoginPage, styles);
